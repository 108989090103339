import React from 'react';
import styled from 'styled-components';

const AddStandardInstance = () => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <AddStandardInstanceSC>
                <p>add standard instance</p>
            </AddStandardInstanceSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddStandardInstanceSC = styled.div`
    
`

export default AddStandardInstance