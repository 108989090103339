import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import Preview from '../../emailSchedule/Content/Preview';
import { Button } from '@mui/material';

const PreviewModal = ({ mailTemplate, handleBarsObject, state, setState, closeFunction, nonInstanceHandleBars}) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <Modal>
                <PreviewModalSC>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => closeFunction()}
                        className='close-button'
                    >close</Button>
                    <Preview    
                        mailTemplate={mailTemplate}
                        handleBarsObject={handleBarsObject}
                        state={state}
                        setState={setState}
                        hideCloseButton={'yes'}
                        nonInstanceHandleBars={nonInstanceHandleBars}
                    />
                </PreviewModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PreviewModalSC = styled.div`
    max-height : 95vh;
    overflow-y : scroll;
    max-width : 50vw;
    background-color : white;
    min-height : 95vh;
    min-width : 50vw; 
    position : relative;

    .close-button {
        position : absolute; 
        z-index : 100;
        top : 15px;
        right : 15px;
    }


`

export default PreviewModal