import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const DiscountsList = ({ discounts, setIndividualDiscountCode }) => {


    const columnDefs=[
        { field: "discountCode", headerName: "Code", flex : 2 },
        { field: "description", headerName: "Description", flex : 5 },
        { field: 'startDate', headerName : 'Start date', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY'), flex : 2},
        { field: 'endDate', headerName : 'End date', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY'), flex : 2},
        { field: "discountCodeStatus", headerName: "Status", flex : 2 },
    ]

    const openIndividualDiscountCode = (params) => {
        setIndividualDiscountCode(params);
    }

    const rendering = () => {
        return (
            <DiscountsListSC>
                    <div className='ag-theme-material' style={{height : 600, width : "100%"}} >
                    <AgGridReact
                        rowData={discounts}
                        columnDefs={columnDefs}
                        onRowClicked={p => openIndividualDiscountCode(p.data)}
                    />
                </div>
            </DiscountsListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DiscountsListSC = styled.div`
    background-color : white; 
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    margin : 10px;
    border-radius : 10px;
    padding : 10px;

    .ag-theme-material {
        --ag-border-radius : 10px;
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
    }

    
`

export default DiscountsList