import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import { TextField } from '@mui/material';
import { CustomInstanceContext } from './AddCustomInstance';

const Customer = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { register, CustomerRef } = React.useContext(CustomInstanceContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <CustomerSC className='content-container-item content-section' id='section2' ref={CustomerRef}>
                <TitleComponent title={'Customer'}/>
                <div className='customer-row'>
                    <TextField  
                        label='Customer name'
                        type='text' 
                        defaultValue={0}
                        fullWidth
                        {...register('customerName', {
                            required : {
                                value : true,
                                message : 'customer name is requried'
                            },
                            minLength : {
                                value : 1,
                                message : 'This field must be at least 5 characters'
                            }
                        })}
                    />                        

                </div>
                <div className='customer-row second-row'>
                    <TextField  
                        label='Customer contact person e-mail'
                        type='text' 
                        defaultValue={0}
                        {...register('contactEmail')}
                    />
                    <TextField  
                        label='Customer phone'
                        type='text' 
                        defaultValue={0}
                        {...register('contactPhoneNumber')}
                    />                        
                </div>

            </CustomerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CustomerSC = styled.div`
    gap : 25px;

    .customer-row {
        display : flex;
        flex-direction : row;
        gap : 25px;
        margin-bottom : 25px;


    }

    &>.second-row {
        display : flex; 
        flex : 1;
        
        &>div {
            width : 50%;
        }
    }
`

export default Customer