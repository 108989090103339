import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { SurveyContext } from './FillSurveyLink';

const SurveyButtons = () => {

    const { submitFunction } = React.useContext(SurveyContext);

    const rendering = () => {
        return (
            <SurveyButtonsSC>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => submitFunction()}
                >
                    Submit
                </Button>
            </SurveyButtonsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyButtonsSC = styled.div`
    padding : 20px;
    display : flex; 
    justify-content : flex-end;
`

export default SurveyButtons