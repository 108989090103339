import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import Switch from '@mui/material/Switch';
import { CustomInstanceContext } from './AddCustomInstance';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import { TextField } from '@mui/material';

const Pricing = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { priceMode, setPriceMode, register, errors, PricingRef } = React.useContext(CustomInstanceContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <PricingSC className='content-container-item content-section' id='section4' ref={PricingRef}>
                <TitleComponent title={'Pricing'}/>
                <div className='pricing-row'>
                    <div className='pricing-mode price-input-field'>
                        <FormControl
                            sx={{
                                width : '100%', 
                            }}
                            fullWidth>
                                <InputLabel 
                                    id="demo-simple-select-label"
                                    sx={{
                                        backgroundColor : 'white',
                                        paddingRight : '5px',

                                    }}    
                                >
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={priceMode}
                                    label="Age"
                                    onChange={(e) => setPriceMode(e.target.value)}
                                    sx={{ fontFamily : 'inherit', fontSize : 'inherit', color : 'black'}}
                                >
                                    <MenuItem value={'fixed'} sx={{ padding : '10px'}}>Fixed price</MenuItem>
                                    <MenuItem value={'per-attendee'} sx={{ padding : '10px'}}>Cost per attendee</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
                    <div className='price price-input-field'>
                        <TextField  
                            label={priceMode === 'fixed' ? 'Price' : 'Price per attendee'}
                            type='number' 
                            defaultValue={0}
                            {...register('price', {
                                required : {
                                    value : true,
                                    message : 'Price is required'
                                }
                            })}
                            // error={!!errors.price}
                            // helperText={errors.price?.message}
                            sx={{
                                maxWidth : '49%',
                                marginRight : '10px'
                            }}
                        />
                    </div>
                </div>
                <div className='capacity-row'>
                    <div className='capacity price-input-field'>
                        <TextField  
                            label='Max capacity'
                            type='number' 
                            defaultValue={0}
                        />
                    </div>
                </div>

            </PricingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PricingSC = styled.div`
    display : flex; 
    flex-direction : column;
    gap : 20px;

    .pricing-row {
        display : flex;
        gap : 20px;
        width : 50%;
    }

    .capacity-row {
        display : flex;
        gap : 10px;
        width : 50%;
    }

    .price-input-field {
        min-width : 50%;

        .MuiFormControl-root {
            min-width : 100%;
            flex : 1;
        }
    }
`

export default Pricing