import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';

const CombiDiscountModal = ({ individualTraining, individualSession, closeFunction, attendee }) => {

    const rendering = () => {
        return (
            <Modal>
                <CombiDiscountModalSC >
                    <div>
                        <h1>Combi Discount</h1>
                        {individualSession.combiDiscount.map((discountArray) => {

                            return (
                                <div className='discount-item'>
                                    <h2>{discountArray[0].discountCode}</h2>
                                    {discountArray.map((discount) => {
                                        return (
                                            <div>
                                                <div className='user-and-discount'>
                                                    <p style={{ backgroundColor : attendee.email === discount.email ? 'yellow' : 'inherit'}}>{discount.email}</p>
                                                    <p>-€{discount.discountValue.toFixed(2)}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>                          
                            )
                        })}
                    </div>
                    <Button 
                        onClick={closeFunction}
                        variant='contained'    
                    >Close</Button>
                </CombiDiscountModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CombiDiscountModalSC = styled.div`
    background-color : white;
    padding : 25px;
    min-width: 500px;
    display : flex; 
    flex-direction : column;
    justify-content : flex-end;

    h1 {
        margin-bottom : 20px;
        font-size : 1.5em;
    }

    .user-and-discount {
        display : flex;
        justify-content : space-between;
    }

    .discount-item {
        margin-bottom : 20px;
        margin-top : 20px;
    }
`

export default CombiDiscountModal