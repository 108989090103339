import React from 'react';
import styled from 'styled-components';
import Modal from '../../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { backEndAdress } from '../../../../variables/back-endAdress';
import Axios from 'axios'; 
import { IndividualSessionContext } from '../../IndividualSession';

const CertificatesListPopUp = ({ closeFunction, instance }) => {

/* ===================================================
    IMPORTS 
=================================================== */
    const { surveyResponses, individualSession } = React.useContext(IndividualSessionContext);

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EFFECTS 
=================================================== */

/* ===================================================
    FUNCTIONS 
=================================================== */


    const flaggedAttendees = individualSession.attendees.map((attendee) => {
        const hasSurveyResponse = surveyResponses.some(response => response.user.email === attendee.email);
        return {
            ...attendee,
            hasSurveyResponse
        };
    });

    const StatusComponent = ({ data }) => {
        return (
            <div style={{ margin : 0, padding : 0, lineHeight : 'normal', display : 'inline-block', verticalAlign : 'middle'}}>
                {data.hasSurveyResponse ? 
                    <p className='status-green' style={{ padding : '5px 15px 5px 15px', fontSize : '12px', borderRadius : '5px'}}>{'completed'}</p>
                    : 
                    <p className='status-amber' style={{ padding : '5px 15px 5px 15px', fontSize : '12px', borderRadius : '5px'}}>{'waiting'}</p>
                }
            </div>
        );
    }
/* ===================================================
    RENDERING
=================================================== */
    const rendering = () => {
        return (
            <Modal>
                <CertificatesListPopUpSC>
                    <div className='header'>
                        <p>Awarded certificates</p>
                        <Button variant='outlined' onClick={closeFunction}>Close</Button>
                    </div>

                    <div className='parent-container'>
                        {individualSession.attendees.length === 0 ?
                        
                        <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', flex : 1}}>
                            <p>No attendees found</p>
                        </div>

                        :

                        <div className='ag-theme-material' style={{height : "75vh", width : "100%"}} >
                            <AgGridReact
                                rowData={flaggedAttendees} 
                                columnDefs={[
                                    {field : 'email', headerName : 'Adress', flex : 1},
                                    {field : 'hasSurveyResponse', headerName : 'Status', flex : 1, cellRenderer : StatusComponent}
                                ]}
                            />
                        </div>
                    }
                    </div>
                    
                </CertificatesListPopUpSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificatesListPopUpSC = styled.div`
    min-width : 80vw;
    min-height : 80vh;
    background-color : white;
    display : flex;
    flex-direction : column;

    .header {
        display : flex;
        padding : 1rem;
        justify-content : space-between;
        align-items : center;
        border-bottom : 1px solid lightgrey;
    }

    .parent-container {
        display : flex;
        flex-grow : 1;
        overflow : hidden;
        background-color : #f5f5f5;
        flex-direction : column;
    }

    .ag-theme-material {
        --ag-border-radius : 10px;
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
    }

`

export default CertificatesListPopUp