import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import Header from './Header';
import SelectTrainingType from './SelectTrainingType';
import AddStandardInstance from './AddStandardInstance/AddStandardInstance';
import AddCustomInstance from './AddCustomInstance/AddCustomInstance';
import SelectContentType from './SelectContentType';
import ActionInProgress from '../GeneralComponents/ActionInProgress';
import AddPublicTraining from './AddCustomInstance/AddPublicTraining/AddPublicTraining';
import AddInstance from '../addInstance2/AddInstance';

export const NewSessionModalContext = React.createContext();

const ScheduleNewSessionModal = ({ setNewSessionModal }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */
    const [trainingType, setTrainingType] = React.useState('')
    const [contentType, setContentType] = React.useState('')
    const [popUpTrainingType , setPopUpTrainingType] = React.useState(false)
    const [selectedTraining, setSelectedTraining] = React.useState(null)


    const [createSessionLoading, setCreateSessionLoading] = React.useState(false)
    const [createSessionError, setCreateSessionError] = React.useState(false)
    const [createSessionSuccess, setCreateSessionSuccess] = React.useState(false)

    const closeFunction = () => {
        setNewSessionModal(false)
    }
/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */
    const renderCorrectComponent = () => {
        switch(contentType) {
            case '':
                return null;
            case 'standard':
                return <AddStandardInstance />;
            case 'custom':
                return <AddCustomInstance />;
            default:
                return null;
        }
    }
/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {

        console.log('training type :' , trainingType)

        return (
            <NewSessionModalContext.Provider value={{
                setNewSessionModal,
                setTrainingType,
                setContentType,
                trainingType,
                setPopUpTrainingType,
                setCreateSessionLoading,
                setCreateSessionError,
                setCreateSessionSuccess,
                closeFunction,
                setSelectedTraining
            }}>
                <Modal>
                    <ScheduleNewSessionModalSC>
                        {/* <Header /> */}
                        {/* {renderCorrectComponent()} */}

                        {createSessionLoading && <ActionInProgress
                            text='Creating training'
                            successText='Training created'
                            errorText='Error creating training'
                            completed={createSessionSuccess}
                            error={createSessionError}
                            successOption1={{text : 'Close', onClick : closeFunction}}
                        />}


                        {trainingType === '' && <SelectTrainingType closeFunction={() =>setNewSessionModal(false)} />}
                        {trainingType === 'public' && <AddPublicTraining />}
                        {popUpTrainingType && trainingType !== 'public' && <SelectContentType closeFunction={() =>setNewSessionModal(false)} />}
                        {selectedTraining && <AddInstance 
                            training={selectedTraining} 
                            closeFunction={closeFunction}    
                        />}
                        {renderCorrectComponent()}

                    </ScheduleNewSessionModalSC>
                </Modal>
            </NewSessionModalContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ScheduleNewSessionModalSC = styled.div`
    min-width : 90vw;
    min-height : 90vh;
    max-height : 90vh;
    max-width : 90vw;
    background-color : white;
    display : flex; 

`

export default ScheduleNewSessionModal