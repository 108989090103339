import React from 'react';
import styled from 'styled-components';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import ActionInProgress from '../../GeneralComponents/ActionInProgress';
import { set } from 'react-hook-form';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import { IndividualTrainingContext } from '../IndividiualTraining';

const WebsiteStatus = ({ individualTraining }) => {

    const { setIndividualTraining } = React.useContext(IndividualTrainingContext);

    const [changeVisbilityWebsite, toggleChangeVisbilityWebsite] = React.useState(false); 
    const [changeVisibilityResponse, setChangeVisibilityResponse] = React.useState(null);
    const [changeVisiblityError, setChangeVisiblityError] = React.useState(null);


    const changeWebsiteStatus = async () => {

        toggleChangeVisbilityWebsite(true);
        
        try {
            const response = await Axios.post(`${backEndAdress}/api/trainings/toggle-website-visiblity/${individualTraining._id}`);
            console.log('response : ', response.data.publishedOnWebsite);
            setIndividualTraining(response.data);
            setChangeVisibilityResponse(response);
            return response.data;
        } catch (error) {
            console.log('error', error);    
            setChangeVisiblityError(error);
        }

    }

    React.useEffect(() => {
        console.log('published : ', individualTraining.publishedOnWebsite);
    }, [individualTraining])


    const rendering = () => {

        return (

            <UICard styleTags={{ margin : '20px', marginLeft : 0, padding : 0}}>

                {changeVisbilityWebsite && <ActionInProgress 
                    text='Updating website status'
                    successText='Website status updated'
                    errorText='Error updating website status'
                    completed={changeVisibilityResponse}
                    error={changeVisiblityError}
                    successOption1={{ text : 'Ok', onClick : () => toggleChangeVisbilityWebsite(false)}}
                    errorOption1={{ text : 'Try again', onClick : () => console.log('try again')}}
                />}

                <WebsiteStatusSC>
                    <div className='title-component' style={{ padding : '20px', paddingRight : 0}}>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Website status</h1>
                    </div>     

                    <div className='website-status' style={{ }}>
                        <Switch 
                            checked={individualTraining.publishedOnWebsite}
                            onChange={() => changeWebsiteStatus()}    
                        />
                        {individualTraining.publishedOnWebsite ?
                            <div style={{  display : 'flex',  flexDirection : 'column', paddingLeft : '10px'}}>
                                <h1>published on website</h1>
                                <h2>Users can sign up via the website</h2>
                            </div>
                            :
                            <div style={{  display : 'flex',  flexDirection : 'column', paddingLeft : '10px'}}>
                                <h1>Hidden on website</h1>
                                <h2>Users cannot sign up via the website</h2>
                            </div>
                        }


                    </div>

                   </WebsiteStatusSC>
                </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const WebsiteStatusSC = styled.div`
    margin-bottom : 20px;
    display : flex; 
    flex-direction : column;
    
    .website-status {
        padding : 15px;
        display : flex;
        justify-content : space-between;
        align-items : center;
        border-radius : 10px;
        min-width : 0;
        margin-top : 0;
        align-self : flex-start;


        &>svg {
            padding-right : 10px;
            margin-left : 10px;
        }
    }
    p {
        padding : 8px;
        padding-right : 0;
        border-radius : 10px;
        min-width : 0;
    }


`

export default WebsiteStatus