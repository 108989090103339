import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SurveyContext } from './FillSurveyLink';

const CertCreatedMenu = ({ certificate }) => {


    const { state, setState } = React.useContext(SurveyContext);
    
    const navigate = useNavigate();

    const openCertificate = () => {
        window.location.href = `https://holli-certificate-hoster.onrender.com/certificate/${certificate._id}`;
    }

    const rendering = () => {
        return (
            <CertCreatedMenuSC>
                <p>{`The certificate is sent to  : ${certificate.user.userEmail}`} </p>
                <div className='buttons'>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={openCertificate}
                    >
                        Open certificate
                    </Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setState({...state, preSendToggled : false})}
                    >
                        Close
                    </Button>
                </div>
            </CertCreatedMenuSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertCreatedMenuSC = styled.div`
    background-color : white;
    padding : 40px;
    min-width : 500px;
    display : flex;
    flex-direction : column;
    border-radius : 5px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);

    p {
        font-size : 1.2rem;
    }

    .buttons {
        display : flex;
        gap : 10px;
        margin-top : 20px;
    }

    @media (max-width: 600px) {
        max-width : 90vw;
        min-width : 90vw;
        padding : 15px;
        box-sizing : border-box;
    }
`

export default CertCreatedMenu