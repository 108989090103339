import React from 'react';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import AreYouSure from '../../GeneralComponents/AreYouSure';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import { IndividualSessionContext } from '../IndividualSession';
import ChangeLocationPopUp from '../../IndividualTraining/TrainingProperties/InstanceLocation/ChangeLocationPopUp';
import ChangeTrainerPopUp from '../../IndividualTraining/TrainingProperties/InstanceTrainer/ChangeTrainerPopUp';
import { set } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { IndividualTrainingContext } from '../../IndividualTrainingFinal/IndividiualTraining';
import CustomerLocation from './CustomerLocation';

const TrainingInfo = ({ individualSession }) => {

    console.log('ind session', individualSession);

    const [functionOjbect, setFunctionObject] = React.useState(null);
    const [pickNewTrainer, setPickNewTrainer] = React.useState(false);
    const [pickNewLocation, setPickNewLocation] = React.useState(false);

    const { setIndividualSession } = React.useContext(IndividualSessionContext);
    const { setReRender } = React.useContext(IndividualTrainingContext);

    const updateTrainer = (selectedTrainer) => {
            
        const updateInstance = async () => {

            const newInstance =  {
                ...individualSession,
                trainer : selectedTrainer
            }


            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${individualSession._id}`, newInstance);
                setPickNewTrainer(false);
                setFunctionObject(null);
                setIndividualSession(response.data);
                setReRender(Math.random());
            } catch (error) {
                console.log(error);
            }
        }

        updateInstance();

    }

    const updateLocation = (selectedLocation) => {
            
        const updateInstance = async () => {

            const newInstance =  {
                ...individualSession,
                location : selectedLocation
            }

            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${individualSession._id}`, newInstance);
                setPickNewLocation(false);
                setFunctionObject(null);
                setIndividualSession(response.data);
                setReRender(Math.random());

            } catch (error) {
                console.log(error);
            }
        }

        updateInstance();

    }        

    const rendering = () => {
        return (
            <UICard styleTags={{ marginLeft : "20px"}}>
                <TrainingInfoSC className='dasboard-container'>

                    { functionOjbect && <AreYouSure 
                        title={functionOjbect.title}
                        textOption1={functionOjbect.textOption1}
                        textOption2={functionOjbect.textOption2}
                        function1={functionOjbect.function1}
                        function2={functionOjbect.function2}
                    />}

                    {pickNewTrainer && <ChangeTrainerPopUp  
                        closeFunction={() => setPickNewTrainer(false)}    
                        clickFunction={updateTrainer}
                    />}

                    {pickNewLocation && <ChangeLocationPopUp
                        closeFunction={() => setPickNewLocation(false)}
                        clickFunction={updateLocation}
                    />}
                            

                        <div className='title-component'>
                            <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                            <h1 className='card-title'>Workshop info</h1>
                        </div>

                    <div className='avatar-container'>
                        <div className='left'>
                            <img src={individualSession.trainer.imageUrl} alt='avatar' />
                            <div>
                                <h1 className='name'>Trainer</h1>
                                <h3 className='role'>{`${individualSession.trainer.firstName} ${individualSession.trainer.lastName}`}</h3>
                            </div>
                        </div>
                        <EditIcon 
                            onClick={() => setFunctionObject({
                                title : 'Do you want to change the trainer for this session?',
                                textOption1 : 'Yes', 
                                textOption2 : 'No', 
                                function1 : () => setPickNewTrainer(true),
                                function2 : () => setFunctionObject(null)
                            })}
                        />
                    </div>
                    <div className='avatar-container' style={{borderBottom : 0}}>
                        <div className='left'>
                            {individualSession.locationType === 'our-location' &&
                                <>
                                    <img src={individualSession.location.thumbnailImageUrl} alt='avatar' />
                                    <div >
                                        <h1 className='name'>Location</h1>
                                        <h3 className='role'>{individualSession.location.title} </h3>
                                    </div>
                                </>
                            }
                            {individualSession.locationType === 'customer-location' &&
                                <CustomerLocation 
                                    individualSession={individualSession}
                                />
                            }
                            {individualSession.locationType === 'online' &&
                                <>
                                    <img src='https://blog.huddles.app/wp-content/uploads/Virtual-Meeting.png' alt='avatar' />
                                    <div >
                                        <h1 className='name'>Location</h1>
                                        <h3 className='role'>Online </h3>
                                    </div>
                                </>
                            }
                        </div>
                        <EditIcon
                            onClick={() => setFunctionObject({
                                title : 'Do you want to change the location for this session?',
                                textOption1 : 'Yes', 
                                textOption2 : 'No', 
                                function1 : () => setPickNewLocation(true),
                                function2 : () => setFunctionObject(null)
                            })}
                        />
                    </div>
                </TrainingInfoSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingInfoSC = styled.div`

    .avatar-container {
        display : flex; 
        justify-content : space-between;
        align-items : center;
        border-bottom : 1px solid lightgrey;
        padding : 10px;

        .left {
            display : flex;
            align-items : center;
            padding : 10px;

            &>img {
                width : 50px;
                height : 50px;
                border-radius : 50%;
                margin-right : 20px;
            }


        }

        &>svg {
            color : lightgrey;
            border : 2px solid lightgrey;
            border-radius : 5px;
            padding : 5px;
            font-size : 15px;
            margin-right : 10px;
            margin-left : 10px;

            &:hover {
                cursor : pointer;
                color : grey;
            }
        }

    }



`

export default TrainingInfo