import React from 'react';
import styled from 'styled-components';
import KPICard from './KPICard';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import { SvgIcon } from '@mui/material';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import moment from 'moment';
import { DashboardContext } from '../Dashboard';

const  KPIRow = () => {

    const [registrations, setRegistrations] = React.useState(0);    
    const [revenue, setRevenue] = React.useState(0);
    const [revenueByTraining, setRevenueByTraining] = React.useState([]);

    const currentYear = new Date().getFullYear();
    const firstDateOfYear = new Date(currentYear, 0, 1);
    const lastDateOfYear = new Date(currentYear, 11, 31);

    const { setLatestRegistrationsModal, reRender } = React.useContext(DashboardContext);

    React.useEffect(() => {
        const getRevenue = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/get-total-revenue`);
                const revenue = result.data[0].totalRevenue;
                const formattedRevenue = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(revenue);
                setRevenue(formattedRevenue);
            }
            catch(err) {
                console.log(err);
            }
        }

        const getAttendees = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/total-attendees`);
                setRegistrations(result.data.totalAttendees);
            }
            catch(err) {
                console.log(err);
            }
        }

        const getRevenueByTraining = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/revenue-by-training`);
                setRevenueByTraining(result.data);
            } catch(err) {
                console.log(err);
            }
            }

        getRevenue();
        getAttendees();
        getRevenueByTraining();

    }, [reRender]);

    const rendering = () => {
        return (
            <UICard>
                <KPIRowSC>
                    <div className='title-component'>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Key metrics</h1>
                    </div>
                    <div className='kpi-cards'>
                        <KPICard 
                            title={'Total revenue'}
                            subTitle={'Attendees signed up times training price'}
                            value={revenue}
                            dateRange={`${moment(firstDateOfYear).format('DD/MM/YYYY')} - ${moment(lastDateOfYear).format('DD/MM/YYYY')}`}
                        />
                        <KPICard 
                            onClickFunction = {() => setLatestRegistrationsModal(true)}
                            title={'# Registrations'}
                            subTitle={'Attendees signed up for training'}
                            value={registrations}
                            dateRange={`${moment(firstDateOfYear).format('DD/MM/YYYY')} - ${moment(lastDateOfYear).format('DD/MM/YYYY')}`}
                        />

                    </div>
                </KPIRowSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const KPIRowSC = styled.div`
    
    .kpi-cards  {
        margin-left : 20px;
        display : flex;
        flex-wrap : wrap;
        margin-bottom : 20px;
    }


`

export default KPIRow