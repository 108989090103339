import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import TrainingInfo from './TrainingInfo';
import DateAndTime from './DateAndTime';
import Trainer from './Trainer';
import AddCustomInstanceSidebar from './AddCustomInstanceNavigation';
import Pricing from './Pricing';
import { backEndAdress } from '../../../variables/back-endAdress'; 
import Axios from 'axios'; 
import Location from './Location';
import MessageForSignUp from './MessageForSignUp';
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Communication from './Communication';
import { Button, ButtonBase } from '@mui/material';
import Customer from './Customer';
import dayjs from 'dayjs';
import { NewSessionModalContext } from '../ScheduleNewSessionModal';
import { DashboardContext } from '../../dashboard/Dashboard';

export const CustomInstanceContext = React.createContext();

const AddCustomInstance = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const TrainingInfoRef = React.useRef(null);
    const CustomerRef = React.useRef(null);
    const DateAndTimeRef = React.useRef(null);
    const PricingRef = React.useRef(null);
    const TrainerRef = React.useRef(null);
    const LocationRef = React.useRef(null);
    const MessageForEmailRef = React.useRef(null);
    const CommunicationRef = React.useRef(null);

    const navigationItems = [{
        title : 'Training Info',
        icon : 'info',
        active : true,
        id : 1,
        refToUse : TrainingInfoRef
    },{
        title : 'Customer',
        icon : 'user',
        active : false, 
        id : 2,
        refToUse : CustomerRef
    },
    {
        title : 'Date & Time',
        icon : 'calendar',
        active : false,
        id : 2,
        refToUse : DateAndTimeRef
    },{
        title : 'Pricing', 
        icon : 'euro',
        active : false,
        id : 2,
        refToUse : PricingRef
    },{
        title : 'Trainer',
        icon : 'user',
        active : false,
        id : 3,
        refToUse : TrainerRef
    },{
        title : 'Location',
        icon : 'map-marker',
        active : false,
        id : 4,
        refToUse : LocationRef

    },{
        title : 'Practical Info',
        icon : 'user',
        active : false,
        id : 3,
        refToUse : MessageForEmailRef
    },{
        title : 'Communication',
        icon : 'map-marker',
        active : false,
        id : 4,
        refToUse : CommunicationRef

    }]

    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors, isValidating } = formState;

    const { setRerender } = React.useContext(DashboardContext);
    const  { setCreateSessionLoading, setCreateSessionError, setCreateSessionSuccess } = React.useContext(NewSessionModalContext);
    const formRef = React.useRef(null);
    const titleRef = React.useRef(null);
    const descriptionRef = React.useRef(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle
        ],
        content: <p>wssup</p>
    })


/* ===================================================
    STATE
=================================================== */
    const [customInstance, setCustomInstance] = React.useState({});
    const [activeNavItem, setActiveNavItem] = React.useState(1);
    const [priceMode, setPriceMode] = React.useState('fixed');
    const [trainers, setTrainers] = React.useState([]);
    const [trainer, setTrainer] = React.useState(null);
    const [locations, setLocations] = React.useState([]);
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [locationType, setLocationType] = React.useState('customer-location');
    const [trainingName, setTrainingName] = React.useState('');
    const [trainingDescription, setTrainingDescription] = React.useState('');
    const [selectedDates, setSelectedDates] = React.useState([]);
    const [trainingDays, setTrainingDays] = React.useState([]);
    const [touchPoints, setTouchPoints] = React.useState([]);

/* ===================================================
    EVENTS
=================================================== */
    React.useEffect(() => {
        
        const getTrainers = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers`);
                setTrainers(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const getLocations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/locations`);
                setLocations(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getTrainers();
        getLocations();
        
        setCustomInstance({
            title : 'Test', 
            description : 'also test', 
        });

        
    }, []);

/* ===================================================
    FUNCTIONS
=================================================== */
    const submitIt = (data) => {

        setCreateSessionLoading(true);

        const trainingName = titleRef.current.value;
        const trainingDescription = descriptionRef.current.value;
        const practicalInfo = editor.getText();

        const customer = {
            name : data.customerName,
            contactEmail : data.contactEmail,
            contactPhoneNumber : data.contactPhoneNumber
        }

        const getLocationObject = () => {
            if(locationType === 'customer-location') {
                return {
                    adress : data.customerAdress,
                    descriptionForTrainer : data.descriptionForTrainer,
                    descriptionForAttendees : data.descriptionForAttendees,
                    title : 'customer location'
                }
            } else if(locationType === 'our-location') {
                return selectedLocation;
            } else if (locationType === 'online') {
                return {
                    title : 'Online',
                    meetingLink : data.meetingLink
                }
            }
        }

        const location = getLocationObject();

        const transformedDates = trainingDays.map(day => {
            return {
                day : dayjs(day.day,'DD/MM/YYYY').toDate(),
                startTime : dayjs(day.startTime,'HH:mm').toDate(),
                endTime : dayjs(day.endTime,'HH:mm').toDate()
            }
        });

        const instance = {
            trainingType : 'custom', 
            trainingId : 'custom', 
            trainingName : titleRef.current.value,
            trainer : trainer,
            location : location, 
            locationType : locationType,
            capacity : 8, 
            startDate : dayjs(trainingDays[0].day,'DD/MM/YYYY').toDate(), 
            dates : transformedDates,
            attendees : [],
            touchPoints : touchPoints,
            handleBars :  [], 
            combiDiscount : [],
            cancelled : false, 
            customer : customer,
            priceMode : priceMode,
            fixedPrice : priceMode === 'fixed' ? data.price : null,
            pricePerAttendee : priceMode === 'per-attendee' ? data.price : null,
            nonInstanceHandleBars :{
                training_name : trainingName,
                short_description : trainingDescription,
                practical_info : practicalInfo
            }
        }

        const createInstance = async () => {    
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances`, instance);
                setCreateSessionSuccess(response.data);
                setRerender(prev => prev + 1);
            } catch (error) {
                console.error(error);
                setCreateSessionError(error)

            }
        }

        createInstance();

    }

/* ===================================================
    RENDERING
=================================================== */
    const rendering = () => {
        return (
            <CustomInstanceContext.Provider value={{
                activeNavItem,
                setActiveNavItem,
                priceMode,
                setPriceMode,
                trainers,
                locations,
                trainer,
                setTrainer,
                setSelectedLocation,
                locationType, 
                setLocationType,
                editor,
                titleRef, 
                descriptionRef,
                register,
                setTouchPoints,

                TrainingInfoRef,
                CustomerRef,
                DateAndTimeRef,
                PricingRef,
                TrainerRef,
                LocationRef,
                MessageForEmailRef,
                CommunicationRef,
                navigationItems

            }}>
                <AddCustomInstanceSC>
                    <form noValidate onSubmit={handleSubmit(submitIt)} ref={formRef}>
                        <AddCustomInstanceSidebar />
                        <div className='content'>
                            <TrainingInfo 
                                register={register}
                                errors={errors}    
                            />
                            <Customer />
                            <DateAndTime
                                register={register}
                                errors={errors}
                                control={control}
                                selectedDates={selectedDates}
                                setSelectedDates={setSelectedDates}
                                trainingDays={trainingDays}
                                setTrainingDays={setTrainingDays}
                                DateAndTimeRef={DateAndTimeRef}
                            />
                            <Pricing />
                            <Trainer/>
                            <Location />
                            <MessageForSignUp />
                            <Communication />

                            <Button
                                type='submit'
                                className='btn btn-primary'
                                disabled={isValidating}
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                </AddCustomInstanceSC>
            </CustomInstanceContext.Provider>
        );
    }

    return(
            <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddCustomInstanceSC = styled.div`
    display : flex; 
    flex-grow : 1;
    position : relative; 



    form {
        display : flex;
        flex-grow : 1;

        .content {
            display : flex;
            flex-grow : 1;
            flex-direction : column;
            background-color : #fafafa;
            overflow-y : scroll;

        }
    }

    .content-container-item {
        padding : 25px;
        margin : 25px;
        margin-bottom : 0;
        box-shadow : 0 0 10px rgba(0,0,0,0.1);
        border-radius : 5px;
        background : white;
        flex-grow:  1;
    }

`

export default AddCustomInstance