import React from 'react';
import styled from 'styled-components';
import TrainingInfo from './TrainingInfo';
import Actions from './Actions/Actions';
import RegisteredUsers from './RegisteredUsers/RegisteredUsers';
import Header from '../Header/Header';
import MailJobs from './MailJobs/MailJobs';
import SurveyAndCertificate from './SurveyAndCertificate/SurveyAndCertificate';
import JobPreviewComponent from './JobPreview/JobPreviewComponent';
import { IndividualSessionContext } from '../IndividualSession';
import NoInstanceSelected from '../../IndividualTrainingFinal/NoInstanceSelected/NoInstanceSelected';
import PreferedLanaguages from './PreferedLanguages/PreferedLanguages';


const Dashboard = ({ individualSession }) => {

    const getLanguageVotes = () => {

        if(individualSession) {

            const allPreferedLanguages = individualSession.attendees.map(attendee => attendee.preferedLanguage);
            const uniqueLanguages = [...new Set(allPreferedLanguages)];
            const uniqueLanguagesWithVotes = uniqueLanguages.map((language) => {
                return ({
                    language : language, 
                    votes : 0
                })
            }); 

            individualSession.attendees.forEach(attendee => {
                const index = uniqueLanguagesWithVotes.findIndex(lang => lang.language === attendee.preferedLanguage);
                uniqueLanguagesWithVotes[index].votes += 1;
            });

            return uniqueLanguagesWithVotes;
        }
    }

    const languageVotes = getLanguageVotes();

    const rendering = () => {

        return (
            <DashboardSC>
              
                <Header individualSession={individualSession}/>
                {!individualSession ? 
                    <NoInstanceSelected />

                    : 

                    <div style={{display : 'flex'}}>
                        <div style={{display :'flex',  minWidth : '340px', maxWidth : '340px', flexDirection : 'column', flex : 1}}>
                            <TrainingInfo individualSession={individualSession} />
                            <SurveyAndCertificate individualSession={individualSession}/>
                        </div>
                        <div style={{display :'flex',  minWidth : '340px', maxWidth : '340px', flexDirection : 'column', flex : 1}}>
                            <Actions individualSession={individualSession}/>
                            <PreferedLanaguages 
                                individualSession={individualSession}
                                languageVotes={languageVotes}    
                            />
                        </div>
                            <RegisteredUsers individualSession={individualSession} />
                    </div>
                }

            </DashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    display : flex; 
    flex-direction : column;
    flex : 1;

    .title-container {
        display : flex; 
        align-items : center;
        padding : 15px; 
        
        .title {
            font-size : 1em;
            font-weight : 500;
            color : #333;
            margin : 0;
            padding : 0;
        }

        svg {
            margin-right : 10px;
            font-size : 18px;
        }
    }





`

export default Dashboard