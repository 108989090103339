import React from 'react';
import styled from 'styled-components';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import PeopleIcon from '@mui/icons-material/People';
const PreferedLanaguages = ({ languageVotes }) => {

    const filterOutUndefined = languageVotes.filter(item => item.language);
    const orderedLanguageByVotes = filterOutUndefined.sort((a, b) => b.votes - a.votes);
/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : "20px"}}>
                <PreferedLanaguagesSC>
                    <div className='title-component'>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Prefered languages</h1>
                    </div>
                    <div className='language-votes'>
                        {orderedLanguageByVotes.filter(item => item).length === 0 && <h2>No prefered language</h2>}
                        {orderedLanguageByVotes.map((language, index) => {
                            return (
                                <div key={index} className='language-vote'>
                                    <h1 className='language'>{language.language ? language.language : 'None'}</h1>
                                    <div style={{ display : 'flex', alignItems : 'center'}}>
                                        <h1 className='votes'>{language.votes}</h1>
                                        <PeopleIcon />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </PreferedLanaguagesSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PreferedLanaguagesSC = styled.div`
    .language-votes {
        display : flex;
        flex-direction : column;
        padding : 20px;
        padding-top : 0;
    }

    .language-vote {
        display : flex;
        justify-content : space-between;
        margin-bottom : 10px;
        border : 1px solid lightgrey;
        padding : 10px;
        border-radius : 5px;


    }

    svg {
        color :  grey;
        margin-left : 5px;
        font-size : 20px;
    }
`

export default PreferedLanaguages