import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividiualTraining';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import UICard from '../../GeneralComponents/UIContainer/UICard';

const TrainingOverview = ({ individualTraining }) => {
    
    const { name, imageUrl, description, difficulty, customProperties, pricePerDay, numberOfDays } = individualTraining;

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : '20px'}}>
                <TrainingOverviewSC>
                    <div >
                        <img src={imageUrl}/>
                        <div style={{ padding : '20px',  display : 'flex', flexDirection : 'column'}}>
                            <div style={{ display : 'flex', justifyContent : 'space-between'}}>
                                <h1 style={{ fontSize : '24px'}}>{individualTraining.name}</h1>
                            </div>
                            <h3>{individualTraining.description}</h3>
                        </div>
                    </div>
                </TrainingOverviewSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingOverviewSC = styled.div`
    background-color : white;
    display : flex;
    flex-direction : column;
    padding : 12px;
    min-width : 400px;
    max-width : 400px;

    img {
        min-width : 100%;
        object-fit : cover;
        max-width : 100%;
        max-height : 200px;
    }

    .title {
        font-size : 20px;
    }

    .description {  
        font-weight : 350;
    }

    .basic-properties {
        display : flex; 
        justify-content : space-between;
        padding : 20px;
        padding-top : 10px;
    }

    .basic-property-item {
        h3 {
            font-size : 16px;
            font-weight : 400;
        }

        h4 {
            font-size : 14px;
            font-weight : 400;
        }
    }

    .boolean-properties {
        padding-left : 10px;

        .property-boolean {
            display : flex;
            align-items : center;
            margin : 5px;

            h4 {
                margin-left : 10px;
                font-size : 16px;
            }

            svg {
                color : black;
                font-size : 20px;
            }
        }

    }

    .availabilitie-properties {
        display : flex; 
        padding : 20px;
        justify-content : space-between;
    }


`

export default TrainingOverview