import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import Header from './Header';
import UserInfo from './UserInfo';
import MailsOverview from './mails/MailsOverview';
import Axios from 'axios';
import { set } from 'react-hook-form';
import { backEndAdress } from '../../../variables/back-endAdress';

const AttendeeInfo = ({ attendee, closeFunction, individualSession, individualTraining }) => {

    const [mails, setMails] = React.useState([]);
    const [loadingMails, setLoadingMails] = React.useState(true);

    React.useEffect(() => {
        const fetchMailsForAttendee = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mails/by-email/${attendee.email}/${individualSession._id}`);
                console.log('response mails :' , response.data);
                setMails(response.data);
                setLoadingMails(false);
            } catch (error) {
                console.log('Error fetching mails for attendee: ', error);
                setLoadingMails(false);
            }
        }

        fetchMailsForAttendee();
    }, [attendee]);

    
    const rendering = () => {
        return (
            <Modal>
                <WhiteContainer>
                    <Header 
                        closeFunction={closeFunction}
                    />
                    
                    <Content>
                        <UserInfo 
                            attendee={attendee}
                            individualTraining={individualTraining}
                            individualSession={individualSession}
                        />
                        
                        {loadingMails && <p style={{ padding : "20px"}}>Loading mails...</p>}
                        <MailsOverview
                            mails={mails}
                            individualSession={individualSession}
                            attendee={attendee}
                        />
                    </Content>
                </WhiteContainer>
            </Modal>
        );
    }

    return (
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const WhiteContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width : 60vw;
    min-height : 80vh;
    max-height : 80vh;
    overflow : hidden;
`;

const Content = styled.div`
    flex-grow: 1;
    display : flex; 
    overflow : hidden;
`;

export default AttendeeInfo;