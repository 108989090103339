import React from 'react';
import styled from 'styled-components';
import { AddOrEditTrainingContext } from '../AddOrEditTraining';
import CircleIcon from '@mui/icons-material/Circle';
import { Button } from '@mui/material';

const Sidebar = ({ scrollToDiv, closeWindowFunction }) => {

    const { navigationItems, activeItem, setActiveItem } = React.useContext(AddOrEditTrainingContext);

    const rendering = () => {
        return (
            <SidebarSC>
                <div style={{ display : 'flex', flexDirection : 'column'}}>
                    <h1 className='title'>Training</h1>
                    <div className='sidebar-items'>
                    {navigationItems.map((item, index) => {
                        return (
                            <div 
                                href={`#section${index+1}`}
                                key={index}
                                className={`nav-item header-item`}
                                onClick={() => scrollToDiv(item.refToUse)}   
                            >
                                <CircleIcon className='circle' />
                                <p className='navigation-title'>{item.title}</p>
                            </div>
                            )}
                        )}
                    </div>
                </div>
                <div style={{ display : 'flex', justifyContent : 'space-between', padding : '10px', flexDirection : 'column'}}>
                    <Button 
                        variant = 'contained'
                        color = 'primary'
                        onClick = {() => closeWindowFunction()}
                    >
                        Cancel
                    </Button>

                </div>
            </SidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarSC = styled.div`
    min-width: 240px;
    padding : 10px;
    box-sizing : border-box;
    margin : 10px;
    margin-left : 20px;
    background-color : white;
    border-radius : 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1); 
    justify-content : space-between;
    display : flex; 
    flex-direction : column;

    .active {
        color : black;
        font-weight : 400;
    }

    h1 {
        font-size: 1.5rem;
        padding: 25px;
        margin: 0;
        font-weight : 400;
    }

    ul {
        list-style: square;
        padding: 0;
        margin: 15px ;
    }

    .sidebar-items {
        display: flex;
        flex-direction: column;
        padding : 25px;
        padding-top : 0;
        color : grey;
        font-weight : 300;
    }

    .header-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s;
        padding-top : 10px;
        padding-bottom : 10px;
        position: relative;

        &>svg {
            color : grey;
            font-size : 0.7rem;
            padding-right : 15px;
        }

        :not(:last-child) svg::after {
            content: "";
            position: absolute;
            top: 20px; /* Same as circle's height */
            left: 50%;
            transform: translateX(-50%);
            width: 2px;
            height: calc(100% + 10px); /* Adjust for spacing between items */
            background-color: blue;
            z-index: -1;
            }
    }


`

export default Sidebar