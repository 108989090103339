import React from 'react';
import styled from 'styled-components';
import LoginInfo from './LoginInfo';

const LoginContainer = () => {

    const rendering = () => {
        return (
            <LoginContainerSC>
                <LoginInfo />

                <p className='tester'>
                        <div className='title'> 
                            <p style={{ fontFamily : '"Paytone One", serif', fontSize : '64px'}}>HOLLI</p>
                            <p style={{ fontFamily : '"Outfit", serif', fontSize : '64px', fontWeight : 'bold'}}>Training</p>
                        </div>
                        <div className='subtitle'>
                            <p>One tool to manage all your training processes</p>
                        </div>
                </p>

                <div className='login-image'>
                    <img src='/updated login image.png' alt='logo' className='holli-logo'/>
                </div>
            </LoginContainerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LoginContainerSC = styled.div`
    max-width : 70vw;
    max-height : 80vh;
    min-width : 70vw;
    min-height : 80vh;
    border-radius : 10px;
    display : flex;
    position : relative;
    background-color : white;

    @media (max-width : 1200px) {
        max-width : 90vw;
        min-width : 90vw;
    }

    .tester {
        position : absolute; 
        top : 10%;
        left : 50%;
        color : white; 
    }

    .login-image { 
        /* background: linear-gradient(to right, #35B0E5 0%, #3576BD 40%, #371D7F 100%); */
        flex : 1;
        overflow : hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;  /* Makes the image cover the container */        
        }
    }


    .title {
        line-height: 3.5em ;
    }


    .subtitle {
        margin-top : 10px;
        font-size : 1.8em;
        line-height: 0.8em;
        max-width : 30vw;
        
    }
    /* @media (max-width: 900px) {
        max-width : 100vw;
        max-height : 100vh;
        min-width : 100vw;
        min-height : 100vh;
        flex-direction : column;

        .login-image {
            flex : 1;
            max-height : 50vh;
            min-height : 50vh;   
        }
    } */


    
`

export default LoginContainer