import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import Header from './Header';
import BasicInfo from './BasicInfo';

const AddCustomTraining = ({ closeFunction }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */
    const [navigation, setNavigation] = React.useState('basicInfo');
/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {

        console.log('navigation' , navigation);

        return (
            <Modal>
                <AddCustomTrainingSC>
                    <Header closeFunction={closeFunction} />
                    <div className='content-container'>
                        <div className='navigation'>
                            <button onClick={() => setNavigation('basicInfo')}>Basic Info</button>
                            <button onClick={() => setNavigation('customTraining')}>Custom Training</button>
                        </div>
                        <div className='content'>
                            <BasicInfo visible={navigation === 'basicInfo'}/>
                        </div>

                    </div>

                    

                    <p>this is custom training</p>
                </AddCustomTrainingSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddCustomTrainingSC = styled.div`
    min-width : 80vw;
    min-height : 80vh;
    background-color : white;
    display : flex;
    flex-direction : column;
`

export default AddCustomTraining