import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; 
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button, CircularProgress } from '@mui/material';
import Modal from '../../../components/GeneralComponents/Modal';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import OutsideClickHandler from 'react-outside-click-handler';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import { IndividualSessionContext } from '../IndividualSession';
import { ChevronLeft } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Options from './IndSessionOptions';

const Header = ({ individualSession }) => {



    const { toggleSessionList } = React.useContext(IndividualSessionContext);

    const navigate = useNavigate();

    const [toggleOptions, setToggleOptions] = React.useState(false);    
    const [loading, setLoading] = React.useState(false);
    const [sessionRemoved, setSessionRemoved] = React.useState(false);




    const rendering = () => {
        return (
            <UICard styleTags={{ margin : "20px"}}>
                <HeaderSC>
                    {loading && 
                        <Modal>
                            <div className='removing-session'>
                                <p>Removing session</p>
                                <CircularProgress />
                            </div>
                        </Modal>
                    }

                    {sessionRemoved && 
                        <Modal>
                            <div className='removing-session'>
                                <p>Succesfully removed session</p>
                                <Button
                                    onClick={() => navigate('/')}
                                    variant='outlined'

                                >Close</Button>
                            </div>
                        </Modal>
                    }

                    {/* When there is no active session, prompt to select one */}
                    {!individualSession &&
                        <div 
                            onClick={() => toggleSessionList (true)} 
                            style={{ display : 'flex', alignItems : 'center', justifyContent : 'center' }}
                            className='instance-selector'    
                        >
                            <p>select session</p>
                            <ExpandMoreIcon style={{ marginLeft : '10px', marginTop : '2px' }} />
                        </div>
                    }


                    { individualSession && 
                        <div>
                            <div style={{ display : 'flex', alignItems : 'center'}}>
                                {individualSession.cancelled && <div className='cancelled status-red'>Cancelled</div>}
                                <div className='selector' style={{ display : 'flex'}} onClick={() => individualSession.trainingType !== 'custom' && toggleSessionList(true)}>
                                    <p className='instance-title'>{individualSession.trainingName}</p>
                                    {individualSession.trainingType !== 'custom' && <ArrowDropDownIcon style={{ marginLeft : 'auto', cursor : 'pointer'}}  />}
                                </div>
                            </div>
                        
                            <div className='dates'>
                                {individualSession.dates.map((date, index) => {
                                    return (
                                        <div className='date-tile'>
                                            <CalendarMonthIcon />
                                            <h2 key={index}>{moment(date.day).format('DD/MM/YYYY')}</h2>
                                        </div>
                                    )
                                })}
                            </div>
                    </div>}

                    { individualSession && <Options individualSession={individualSession}/>}
                </HeaderSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    display : flex; 
    justify-content : space-between;
    padding : 20px;
    align-items : center;

    .instance-title {
        font-size : 20px;
    }

    .dates {
        display : flex; 
        padding-top : 5px;
        font-weight : 200;

        .date-tile {
            display : flex;
            align-items : center;
            margin-right : 10px;
            font-size : 14px;

            p {
                margin-left : 5px;

            }

            &>svg {
                color : #8a8a8a;
                font-size : 14px;

            }
        }
    }

    .options {
        display : flex; 
        align-items : center;
        position : relative;



        p {
            margin-right : 10px;
            font-size : 16px;
            cursor : pointer;
            font-weight : 300;
        }
    }

    .options-dropdown {
        position : absolute;
        background-color : white;
        top : 64px;
        right : -15px;
        min-width : 250px;
        border-radius : 0px;
        border : 1px solid lightgrey;
        padding : 10px;
        border-top : 0;

        .option-item {
            display : flex;
            align-items : center;
            padding : 10px;
            cursor : pointer;
            font-size : 14px;

            &:hover {
                cursor : pointer;
                background-color : #f9f9f9;
            }
            p {
                margin-left : 10px;
            }
        }
    }
    
    .removing-session {
        background-color : white;
        display : flex; 
        align-items : center;
        justify-content : center;
        padding : 40px;
        flex-direction : column;
        min-width : 300px;

        p {
            font-size : 16px;
            margin-bottom : 20px;
        }
    }

    .date-tile {
        background-color : #fafafa;
        padding : 5px;
        border-radius : 5px;
    }

    .selector {
        &:hover {
            cursor : pointer;
            background-color : #f9f9f9;
        }
    }

    .cancelled {
        padding : 15px;
        border-radius : 5px;
        min-width : 0;
        display : flex;
        height : 10px;
        align-items : center;
        margin-right : 10px;
    }

    .instance-selector {
        &:hover {
            cursor : pointer; 
            color : #8a8a8a;
        }
    }

    .training-status {
        padding : 5px;
        font-size : 12px;
        font-weight : 300; 
        border-radius : 8px;
    }
`

export default Header