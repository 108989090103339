import React from 'react';
import styled from 'styled-components';
import Header from './Header/Header';
import Axios from 'axios' ; 
import { backEndAdress } from '../../variables/back-endAdress';
import Navigation from './Navigation';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Exercises from './Exercises/Exercises';
import SendOutSurveyModal from '../IndividualTraining/SendOutSurveyModal/SendOutSurveyModal';
import SubNavigation from '../GeneralComponents/navigation/SubNavigation';
import { navigationItems } from './NavigationItems';
import SessionList from './SessionList/SessionList';
import { IndividualTrainingContext } from '../IndividualTrainingFinal/IndividiualTraining';
import AddInstance from '../addInstance2/AddInstance';
import Options from './Header/IndSessionOptions';
import AttendeeInfo from './AttendeeInfo/AttendeeInfo';
import AreYouSure from '../GeneralComponents/AreYouSure';
import ActionInProgress from '../GeneralComponents/ActionInProgress';
import CertificatesListPopUp from './Dashboard/SurveyAndCertificate/CertificatesListPopUp';
import AddUsersManually from './AddUsersManually/AddUsersManually';

export const IndividualSessionContext = React.createContext();

const IndividualSession = ({ individualSession, individualTraining, setSessionsForTraining }) => {

    const { setIndividualSession, setReRender } = React.useContext(IndividualTrainingContext);

    const [loading, setLoading] = React.useState(false);
    const [jobPreview, setJobPreview] = React.useState(null);
    const [surveyModal, toggleSurveyModal] = React.useState(false);
    const [sessionListToggled, toggleSessionList] = React.useState(false);
    const [addInstanceToggled, setAddInstanceToggled] = React.useState(false);
    const [individualAttendee, setIndividualAttendee] = React.useState(null);
    const [action, setAction] = React.useState(null);
    const [certificatesPopUp, setCertificatesPopUp] = React.useState(false);
    const [surveyResponses, setSurveyResponses] = React.useState(null);
    const [addUsersManuallyModal, setAdddusersManuallyModal] = React.useState(false);
    
    const [deletingUser, setDeletingUser] = React.useState(false);
    const [deletingUserError, setDeletingUserError] = React.useState(null);
    const [userDeleted, setUserDeleted] = React.useState(false);

    const [state, setState] = React.useState({
        selectedForSurvey : [],
        sendSurveyLoading : false,
    });

    const instanceIdFromURL = window.location.hash.split('/')[4];

/* ==============================================
    FUNCTIONS
============================================== */
    const sendOutSurvey = () => {

        setState({...state, sendSurveyLoading : true});

        const sendOutSurveys = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/send-out-surveys`, { 
                    attendees : state.selectedForSurvey,
                    instance: individualSession,
                    training : individualTraining                      
                }); 
                setIndividualSession(response.data);
                setState({...state, selectedForSurvey: [], sendSurveyLoading: false});
            } catch ( error ) {
                console.log('errors');
                return error;
            }
        }

        sendOutSurveys();


    };

    const deleteUserFromInstance = (userId) => {

        setDeletingUser(true);

        const deleteUserFromInstanceFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/delete-user/${individualSession._id}/${userId}`);
                setIndividualSession(response.data);
                setUserDeleted(response.data);
                setAction(null);
                setIndividualAttendee(null);
            } catch (error) {
                console.error('Error deleting user from instance:', error);
                setDeletingUserError(error);
                setAction(null);
            }
        }

        deleteUserFromInstanceFunction();
    }

    React.useEffect(() => {
        if (instanceIdFromURL === 'undefined' || instanceIdFromURL === 'no-session') return 

        const getSurveyResponses = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/survey-responses/by-instance/${individualSession._id}`);
                setSurveyResponses(response.data);
            } catch (error) {
                console.log('Error in getSurveyResponses', error);
            }
        }
        getSurveyResponses();
    }, [individualSession]);

    const rendering = () => {
        return (
            !loading && 
                <IndividualSessionContext.Provider value={{
                    individualSession, 
                    setIndividualSession, 
                    individualTraining, 
                    jobPreview,
                    setJobPreview,
                    surveyModal, 
                    toggleSurveyModal,
                    sendOutSurvey,
                    toggleSessionList,
                    setAddInstanceToggled,
                    setIndividualAttendee,
                    individualAttendee,
                    action, 
                    setAction,
                    deleteUserFromInstance, 
                    setCertificatesPopUp,
                    surveyResponses,
                    setAdddusersManuallyModal
                }}>
                    <IndividualSessionSC>

                        {/* Sessionslist */}
                        <>	
                            {
                            sessionListToggled && <SessionList 
                            
                            />}
                        </>

                        {/* Add users manually (no e-mail) */}
                        <>	
                            {
                                addUsersManuallyModal && 
                                    <AddUsersManually 
                                        individualSession={individualSession}
                                    /> 
                            }
                        </>

                        {/* Suverymodal  */}
                        <>	
                            {
                                surveyModal && <SendOutSurveyModal 
                                    instance={individualSession}
                                    state={state}
                                    setState={setState}
                                /> 
                            }
                        </>

                        {/* survey responses pop up   */}
                        <>	
                            {
                                certificatesPopUp && <CertificatesListPopUp  
                                    instance={individualSession}
                                    state={state}
                                    setState={setState}
                                    closeFunction={() => setCertificatesPopUp(false)}
                                /> 
                            }
                        </>

                        {/* Add instance  */}
                        <>
                            {
                                addInstanceToggled && <AddInstance 
                                    training={individualTraining}
                                    closeFunction={() => setAddInstanceToggled(false)}
                                    setSessionsForTraining={setSessionsForTraining}
                                    setReRender={setReRender}
                                />
                            }
                        </>

                        {/* Individual attendee info  */}
                        <>
                            {
                                individualAttendee && <AttendeeInfo
                                    individualSession={individualSession}
                                    attendee={individualAttendee}
                                    closeFunction={() => setIndividualAttendee(null)}
                                    individualTraining={individualTraining}
                                />
                            }
                        </>

                        {/* Are you sure for the action? */}
                        <>
                            {action && <AreYouSure 
                                title={action.title}
                                textOption1={action.textOption1}
                                textOption2={action.textOption2}
                                function1={() => action.function1()}
                                function2={() => action.function2()}
                            />}
                        </>

                        <> {/* Action in progress modal  */}
                        {deletingUser &&
                            <ActionInProgress
                                text='Removing user from training' 
                                successText='User was succesfully removed from training' 
                                errorText='Something went wrong' 
                                completed={userDeleted}
                                error={deletingUserError}
                                successOption1={{
                                    text : 'Close',
                                    onClick : () => {
                                        setDeletingUser(false);
                                    }
                                }}
                                errorOption1={{
                                    text : 'Close',
                                    onClick : () => {
                                        setDeletingUser(false);
                                    }
                                }}
                            />
                        }
                    </>
                           
                        <Dashboard individualSession={individualSession}/>
                    </IndividualSessionSC>
            </IndividualSessionContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualSessionSC = styled.div`
    flex : 1;
    display : flex;
    flex-direction : column;
    overflow-y : scroll;
`

export default IndividualSession