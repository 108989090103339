import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { DashboardContext } from '../Dashboard';

const LatestRegistrationsHeader = () => {

    const { setLatestRegistrationsModal } = React.useContext(DashboardContext);

    const rendering = () => {
        return (
            <LatestRegistrationsHeaderSC>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setLatestRegistrationsModal(false)}
                >
                    Close
                </Button>
            </LatestRegistrationsHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LatestRegistrationsHeaderSC = styled.div`
    padding : 1rem;
    display : flex;
    justify-content : flex-end;
`

export default LatestRegistrationsHeader