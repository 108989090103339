import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import TitleComponent from './TitleComponent'; 
import { CustomInstanceContext } from './AddCustomInstance';

const TrainingInfo = ({ visible, register, errors }) => {

/* ===================================================
    IMPORTS
=================================================== */
    const { titleRef, descriptionRef, TrainingInfoRef } = React.useContext(CustomInstanceContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */
    const rendering = () => {
        return (
            <TrainingInfoSC className='content-container-item content-section' id='section1' ref={TrainingInfoRef}>
                <TitleComponent title={'Training info'}/>
                <div className='inputs'>
                    <div className='form-input'>
                        <TextField  
                            label='Title'
                            type='text' 
                            {...register('title', {
                                required : {
                                    value : true,
                                    message : 'title is requried'
                                }
                            })}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                            inputRef={titleRef}
                        />
                    </div>
                    <div className='form-input'>
                        <TextField  
                            label='Description'
                            type='text' 
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            multiline
                            rows={4}
                            inputRef={descriptionRef}
                        />
                    </div>
                </div>
            </TrainingInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingInfoSC = styled.div`
    .title-component {
        display : flex;
        align-items : center;
        margin-bottom : 20px;
    }

    .inputs {
        display : flex; 
        flex-direction : column;
    }

    .form-input {
        margin-bottom : 20px;

        .MuiFormControl-root {
            width : 100%;
        }
    }
`

export default TrainingInfo