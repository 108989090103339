import React from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress'; 
import IndividualDiscountCode from './IndividualDiscountCode/IndividualDiscountCode';
import DiscountsList from './DiscountsLists/DiscountsList';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

const DiscountCodes = () => {

    const [discounts, setDiscounts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [individualDiscountCode, setIndividualDiscountCode] = React.useState(null);

    const [reRender, setReRender] = React.useState(false);
    
    React.useEffect(() => {
        
        const getDiscounts = async () => {

            setLoading(true);

            try {
                const response = await Axios.get(`${backEndAdress}/api/discounts`);
                console.log('resp : ', response.data);
                setDiscounts(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }

        getDiscounts();

    }, [reRender]);

    const addDiscount = () => {
        setIndividualDiscountCode({
            discountCode: "",
            desciption: "",
            startDate: dayjs(),
            endDate: dayjs(),
            discountCodeStatus: "active",
            discountType: "regular",
            combiDiscountOnNthAttendee: '' ,
            combiDiscountPercentage: "",
            trainingIds: []
        });
    }

    const closeFunction = () => {
        setIndividualDiscountCode(null);
    }

    const rendering = () => {
        return (
            <DiscountCodesSC>
                {individualDiscountCode && 
                    <IndividualDiscountCode 
                        individualDiscountCode={individualDiscountCode}
                        closeFunction={closeFunction}
                        setReRender={setReRender}
                />}

                <DiscountsList
                    discounts={discounts}
                    loading={loading}
                    error={error}
                    setIndividualDiscountCode={setIndividualDiscountCode}
                />

                <Button
                    onClick={() => addDiscount()}
                    variant="contained"
                    color="primary"
                    className='add-discount-button'
                >
                    Add discount
                </Button>
            </DiscountCodesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DiscountCodesSC = styled.div`
    flex-grow : 1;
    min-height : 100%;
    display : flex;
    flex-direction : column;
    position : relative; 

    .add-discount-button {
        position : absolute;
        bottom : 20px;
        right : 20px;
    }
`

export default DiscountCodes