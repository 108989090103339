import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';

const CustomerLocation = ({ individualSession }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */
    const [infoForTrainer, setInfoForTrainer] = React.useState(false);
/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <CustomerLocationSC >

                {infoForTrainer && 
                    <Modal>
                        <div className='info-for-trainer-container'>
                            <div className='header'>
                                <h1>Information for the trainer</h1>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setInfoForTrainer(false)}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className='contact-info-divs'>
                                <div className='contact-info'>
                                    <h1>Contact name</h1>
                                    <p>{individualSession.customer.contactEmail}</p>
                                </div>
                                <div className='contact-info'>
                                    <h1>Contact phone number</h1>
                                    <p>{individualSession.customer.contactPhoneNumber}</p>
                                </div>
                            </div>
                            <div className='extra-info-for-trainer'>
                                <h1>Location instructions</h1>
                                <p>{individualSession.location.descriptionForTrainer}</p>
                            </div>
                        </div>
                    </Modal>
                }
                <img src='https://img.freepik.com/premium-vector/modern-office-building-flat-design-concept-illustration_1316704-34457.jpg' alt='avatar' />
                <div className='hover-for-info' onClick={() => setInfoForTrainer(true)}>
                    <h1 className='name'>Location</h1>
                    <h3 className='role'>{individualSession.location.adress} {individualSession.location.place} </h3>
                </div>
            </CustomerLocationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CustomerLocationSC = styled.div`
    display : flex; 

    img {
        width : 50px;
        height : 50px;
        border-radius : 50%;
        margin-right : 10px;
    }

    .info-for-trainer-container {
        background-color : white; 
        border-radius : 10px;
        width : 500px;
        height : 300px;
        display : flex;
        flex-direction : column;

        .header {
            display : flex; 
            justify-content : space-between;
            align-items : center;
            margin-bottom : 20px;
            border-bottom : 1px solid lightgrey;
            padding : 20px;

            h1 {
                font-size : 1.2em;
            }


        }
    }

    .hover-for-info {
        &:hover {
            cursor : pointer;
            background-color : #f5f5f5;
        }
    }

    .contact-info-divs {
        display : flex; 
        flex-direction : column;
        padding-left : 20px;
    }

    .contact-info {
        padding-bottom : 0;
        display : flex; 

        &>h1 {
            font-size : 1em;
            margin-right : 10px;
            font-weight : bold;
        }

        &>p {
            font-size : 1em;
        }
    }

    .extra-info-for-trainer {
        padding : 20px;

        &>h1 {
            font-size : 1.2em;
            margin-bottom : 10px;
        }
    }

`

export default CustomerLocation