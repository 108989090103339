import { Button, IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { Delete } from '@mui/icons-material';

const Header = ({ closeFunction, formRef, errors, promptDeleteDiscount }) => {

    const submitFunction = (e) => {
        console.log('open errors : ', errors)
        formRef.current.dispatchEvent(new SubmitEvent('submit', { cancelable: true, bubbles: true }));
    }

    const rendering = () => {
        return (
            <HeaderSC>
                <div className='title-component' style={{ padding : 0}}>
                    <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                    <h1 className='card-title'>Discount code</h1>
                </div>
                <div className='buttons'>

                    <Button
                        text='close'
                        variant='outlined'
                        onClick={() => submitFunction()}
                    >
                        Save
                    </Button>

                    <Button
                        onClick={closeFunction}
                        text='close'
                        variant='outlined'
                    >Close
                    </Button>
                    <IconButton>
                        <Delete
                            onClick={promptDeleteDiscount}
                        />

                    </IconButton>
                

                </div>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    padding : 15px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    border-bottom : 1px solid #e0e0e0;

    .buttons {
        display : flex;
        gap : 10px;
    }

    .title-component {
        display : flex;
        align-items : center;
        gap : 10px;
    }

`

export default Header