import React from 'react';
import styled from 'styled-components';
import SurveyItems from './SurveyItems';
import SurveyButtons from './SurveyButtons';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import PreSendMenu from './PreSendMenu';
import SurveyHeader from './SurveyHeader';
import ActionInProgress from '../../GeneralComponents/ActionInProgress';

export const SurveyContext = React.createContext(null);

const Survey = () => {

    const navigateToAcademy = () => {
        window.location.href = 'https://academy.credon.eu';
    };

    const surveyIdFromUrl = window.location.hash.split('/')[2];
    const instanceIdFromURL = window.location.hash.split('/')[3];
    const userIdFromUrl = window.location.hash.split('/')[4];

    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const emailRef = React.useRef(null);
  
    const [state, setState] = React.useState({
        survey : null,
        training : null,
        instance : null,
        preSendToggled : false,
        sendingSurveyLoading : false,
        certCreated : null
    });

    const [training, setTraining] = React.useState(null); 
    const [instance, setInstance] = React.useState(null);
    const [trainer, setTrainer] = React.useState(null);
    const [answers, setAnswers] = React.useState([]);
    const [user, setUser] = React.useState(null);
    const [certificateTemplate, setCertificateTemplate] = React.useState(null);

    const [sendingSurvey, setSendingSurvey] = React.useState(false);
    const [surveySent, setSurveySent] = React.useState(false);
    const [surveySentError, setSurveySentError] = React.useState(false);

    React.useEffect(() => {

        const getSurveyInfo = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/public/get-survey-info/${instanceIdFromURL}/${userIdFromUrl}`);
                const { instance, survey, trainer, training, user, certificateTemplate } = response.data;

                setInstance(instance);
                setTrainer(trainer);
                setUser(user);
                setCertificateTemplate(certificateTemplate);

                instance.trainingType !== 'custom' ? setTraining(training) : setTraining({name : instance.trainingName});

                setState({
                    ...state,
                    survey : survey
                });
            } catch (error) {
                console.log(error)
            }
        }

        getSurveyInfo()

    },[]);

    const submitFunction =() => {
        if(certificateTemplate) {
            setState({...state, preSendToggled : true})
        } else {
            setSendingSurvey(true);
            sendSurvey();
        }
    }

    const sendSurvey = () => {

        console.log('### sending survey');

        setState({
            ...state, 
            sendingSurveyLoading : true
        });

        const freeTextAnswers = []; 
        const freeTextItems = state.survey.surveyItems.filter(item => item.type === 'free-text');

        freeTextItems.forEach(item => {
            const responseObject = {
                question : item.question,
                possibleAnswers : item.options,
                type : item.type,
                answerIndex : document.getElementById(item.question).value
            }

            freeTextAnswers.push(responseObject);
        });

        const finalResponses = answers.concat(freeTextAnswers);

        const getInstance = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/public/${instanceIdFromURL}`);
                return response.data;
            } catch (error) {
                setSurveySentError(error);
                console.log(error)
            }
        }

        const createAnswersObject = async (answersObject) => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/survey-responses/public`, answersObject)
                return response.data;
            } catch (error) {
                console.log(error)
            }
        }

        const createCertificate = async () => {
            const certificateObject = {
                instanceId : instance._id,
                image : certificateTemplate.imageId,
                trainingName : training.name,
                certificateName : certificateTemplate.certificateName,
                certificateDate : new Date(),
                user : {
                    userEmail : user.email,
                    firstName : user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1),
                    lastName : user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)
                }
            }


            try {
                const response = await Axios.post(`${backEndAdress}/api/certificates/public/create-and-send`, certificateObject )
                console.log('### repo', response.data);
                return response.data;
            } catch (error) {
                console.log(error)
            }
        }

        const runFunction = async () => {
            const instance = await getInstance();

            const answersObject = {
                surveyId : surveyIdFromUrl,
                training,
                instance,
                trainer,
                user, //Fetched from the initial useEffect
                responses : finalResponses
            }
    
            const response = await createAnswersObject(answersObject);
            console.log('response from sent survey : ', response);

            if(certificateTemplate) {
                const certificate = await createCertificate(); 
                setState({
                    ...state, 
                    sendingSurveyLoading : false,
                    certCreated : certificate
                });
            } else {
                setSurveySent(response);
            }
        }

        runFunction();

    }

    const rendering = () => {

        if(training && instance && trainer ) {
            return ( 
                <SurveySC>
                    <SurveyContext.Provider value={{
                        state,
                        setState,
                        training,
                        instance,
                        trainer,
                        firstNameRef,
                        lastNameRef,
                        emailRef,
                        sendSurvey,
                        surveyIdFromUrl,
                        answers,
                        setAnswers,
                        user,
                        submitFunction
                    }}>
                    <>
                        {sendingSurvey && 
                            <ActionInProgress
                                text={'Sending survey'}
                                successText={'succesfully sent our survey'} 
                                errorText={'error sending survey'} 
                                completed={surveySent}
                                error={surveySentError}
                                successOption1={{
                                    text : 'ok', 
                                    onClick : () => navigateToAcademy()
                                }}
                            />
                        }

                        {state.preSendToggled && <PreSendMenu />}                               

                        <div className='survey-container'>
                            <SurveyHeader />
                            <SurveyItems />
                            <SurveyButtons />
                        </div>
                    </>
                    </SurveyContext.Provider>
                </SurveySC>
            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveySC = styled.div`
    min-height : 100vh;
    min-width : 100vw;
    background-color : white;
    background-color : #f5f5f5;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    
    .survey-options {
        display : flex;
        justify-content : space-between;
        padding : 10px;
        background-color : white;
        border-bottom : 1px solid #e0e0e0;
        padding : 15px;
        
        .other-options {
            display : flex;
            gap : 15px;
        }
        &>p {
            padding : 10px;
            cursor : pointer;
        }
    }
    .survey-container {
        display : flex;
        flex-direction : column;
        gap : 20px;
        width : 100%;
    }

`

export default Survey