import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import { CustomInstanceContext } from './AddCustomInstance';
import DropdownWithTableModal from '../../GeneralComponents/InputFields/DropDownWithTableModal';
import PersonIcon from '@mui/icons-material/Person';

const Trainer = ({ visible }) => {
  
/* ===================================================
    IMPORTS
=================================================== */
    const { trainer, trainers, setTrainer, TrainerRef } = React.useContext(CustomInstanceContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */
    const AvatarComponent = (p) => {
        const { firstName, lastName, imageUrl } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={imageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{`${firstName} ${lastName}`}</p>
            </div>
        );
    }

    const rendering = () => {
        return (
            <TrainerSC className='content-container-item content-section' id='section5' ref={TrainerRef}>
                <TitleComponent title={'Trainer'}/>
                <>
                    {trainers.length > 0 ?
                        <DropdownWithTableModal 
                            options={trainers} 
                            colDefs={[
                                { valueGetter : p => p.data, cellRenderer : AvatarComponent, headerName : 'Trainer', flex : 1 },
                                { field: "phone", headerName : 'Phone', flex  : 1 },
                            ]}
                            setValueFunction={setTrainer}
                            keyToUse={'firstName'}
                            valueFromParent={trainer}
                            icon={<PersonIcon />}
                            label='Trainer'
                        />
                        : <p className='no-values'>No trainers in software</p>
                    }
                </>
            </TrainerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainerSC = styled.div`
    margin-bottom : 20px;
`

export default Trainer