import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import MenuIcon from './MenuIcon';
import HeaderBar from './HeaderBar';
import CreateNewButton from './CreateNewButton';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius'
import { useNavigate } from 'react-router-dom';
import Profile from './Profile';
import Options from './Options';
import { useAuth0 } from "@auth0/auth0-react";

const { headerHeight } = HeigtsPaddingBorderRadius;

const Header = ({ appState, setAppState}) => {

    const { logout } = useAuth0();

    const navigate = useNavigate();

    const rendering = () => {
        return (
            <HeaderSC>
                <img src='/holli-logo.png' alt='logo' className='holli-logo' onClick={() => navigate('/')}/>
                <div className='logout-container'
                    onClick={() =>
                        logout({
                            logoutParams: { returnTo: window.location.origin },
                        })
                    }
                    
                >
                    <p>log out</p>
                </div>
                {/* <div className='end'>
                    <Options />
                    <Profile />
                </div> */}
            </HeaderSC>
            
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    min-height : 45px;
    display : flex;
    box-sizing : border-box;
    justify-content : space-between;
    align-items : center;
    background-color : white;

    .holli-logo {
        height : 25px;
        margin-left : 20px;

        &:hover {
             cursor : pointer;
        }
    }

    .logout-container {
        margin-right : 20px;

        p {
            color : ${colors.primary};
            font-size : 14px;
            font-weight : 500;

            &:hover {
                cursor : pointer;
            }
        }
    }

    .end {
        display : flex;

    }
`

export default Header