import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const AddUserForm = ({ register }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <AddUserFormSC>
                <div className='form-row' style={{display : 'flex', gap : '1rem'}}>
                    <TextField  
                        label='First name'
                        type='text' 
                        {...register('firstName')}
                        style={{minWidth : '49%'}}
                    />
                    <TextField  
                        label='Last name'
                        type='text' 
                        {...register('lastName')}
                        style={{minWidth : '49%'}}
                    />                        
                </div>
                <div className='form-row'>
                    <TextField  
                        label='Email'
                        type='text' 
                        {...register('email')}
                        style={{minWidth : '100%'}}

                    />
                </div>
                <div className='form-row'>
                    <TextField  
                        label='Company'
                        type='text' 
                        {...register('company')}
                        style={{minWidth : '100%'}}

                    />
                </div>


            </AddUserFormSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddUserFormSC = styled.div`
    display : flex; 
    flex-direction : column;
    gap : 1rem;
    margin-top : 15px;
    margin : 25px;
    box-sizing : border-box;
`

export default AddUserForm