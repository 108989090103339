import React from 'react';
import styled from 'styled-components';

const NoResponses = () => {

    const rendering = () => {
        return (
            <NoResponsesSC>
                <p>There are no responses found for the current selection</p>
            </NoResponsesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NoResponsesSC = styled.div`
    background-color : white;
    flex: 1;
    display : flex;
    min-width: 100%;
    margin-left : 25px;
    justify-content : center;
    align-items : center;
    border-radius : 5px;
`

export default NoResponses