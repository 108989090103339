import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal'
import Header from './Header'; 
import ValidOnTrainingsList from './ValidOnTrainingsList';
import DiscountInformation from './DiscountInformation';
import { set, useForm } from 'react-hook-form';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress'; 
import ActionInProgress from '../../GeneralComponents/ActionInProgress';
import AreYouSure from '../../GeneralComponents/AreYouSure';
import dayjs from 'dayjs';

const IndividualDiscountCode = ({ individualDiscountCode, closeFunction , setReRender }) => {

    const formRef = React.useRef(null);

    const [discountType, setDiscountType] = React.useState('');
    const [valueType, setValueType] = React.useState('');
    const [trainings, setTrainings] = React.useState([]);
    const [selectedTrainings, setSelectedTrainings] = React.useState([]);
    const [numberOfUsersForDiscount, setNumberOfUsersForDiscount] = React.useState(0);
    
    // Action for are you sure
    const [action, setAction] = React.useState(null);

    // Creating discount progress states
    const [creatingDiscount, setCreatingDiscount] = React.useState(false);
    const [discountCreated, setDiscountCreated] = React.useState(false);
    const [discountError, setDiscountError] = React.useState(false);

    // Deleting discount progress states
    const [deletingDiscount, setDeletingDiscount] = React.useState(false);
    const [discountDeleted, setDiscountDeleted] = React.useState(false);
    const [discountDeleteError, setDiscountDeleteError] = React.useState(false);

    const getInitiDiscountValue = () => {
        if(individualDiscountCode._id) {
            if(valueType === 'Amount') {
                return individualDiscountCode.discountType === 'regular' ? individualDiscountCode.regularDiscountAmount : individualDiscountCode.combiDiscountAmount;
            } else {
                return individualDiscountCode.discountType === 'regular' ? individualDiscountCode.singleDiscountPercentage * 100 : individualDiscountCode.combiDiscountPercentage * 100;
            }
        } else {
            return 0;
        }
    }

    const promptDeleteDiscount = () => {    
        setAction({
            title : 'Are you sure you want to delete this discount code? This action cannot be undone.',
            textOption1 : 'Yes, delete discount code',
            textOption2 : 'No, keep discount code',
            function1 : deleteDiscountCode,
            function2 : () => setAction(null)
        });
    }

    const deleteDiscountCode = () => {

        const deleteDiscount = async () => {

            setDeletingDiscount(true);

            try {
                const response = await Axios.delete(`${backEndAdress}/api/discounts/${individualDiscountCode._id}`);
                setDiscountDeleted(response.data);
                setReRender(Math.random());
            } catch (error) {
                setDiscountDeleteError(error.response.data.message);
            }
        }

        deleteDiscount();

    }

    const initDiscountValue = getInitiDiscountValue();

    const form = useForm({
        defaultValues : {
            discountCode : individualDiscountCode? individualDiscountCode.discountCode : '',
            description : individualDiscountCode? individualDiscountCode.description : '',
            discountValue : initDiscountValue,
            startDate : individualDiscountCode? dayjs(individualDiscountCode.startDate) : dayjs(),
            endDate : individualDiscountCode? dayjs(individualDiscountCode.endDate) : dayjs()
        }
    });

    const { register, handleSubmit, formState, control, setValue } = form;
    const { errors } = formState;

    React.useEffect(() => {
        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings`);
                setTrainings(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getTrainings();
    }, []);

    React.useEffect(() => {

        let valueType = ''; 

        if(individualDiscountCode.singleDiscountAmount || individualDiscountCode.combiDiscountAmount) {
            valueType  = 'Amount';
        } else {
            valueType = 'Percentage';
        }

        if(individualDiscountCode._id) {
            setDiscountType(individualDiscountCode.discountType ? individualDiscountCode.discountType : 'regular');
            setValue('discountType', individualDiscountCode.discountType ? individualDiscountCode.discountType : 'regular');
            setValueType(valueType);
            setNumberOfUsersForDiscount(individualDiscountCode.combiDiscountOnNthAttendee);
            setSelectedTrainings(individualDiscountCode.trainingIds);
        } else {
            setDiscountType('regular');
            setValue('discountType', 'regular')

            setValueType('Percentage');
            setNumberOfUsersForDiscount(2);
        }
    }, [individualDiscountCode]);


    const onSubmit = (data) => {

        setCreatingDiscount(true);

        const discount = {
            _id : individualDiscountCode._id ? individualDiscountCode._id : null,
            discountCode : data.discountCode.toUpperCase(),
            description : data.description,
            startDate : data.startDate.toISOString(),
            endDate : data.endDate.toISOString(),
            discountCodeStatus : 'active',
            discountType : data.discountType,
            trainingIds : selectedTrainings,
            combiDiscountOnNthAttendee : data.discountType === 'multi-user' ? numberOfUsersForDiscount : null
        }

        if(discountType === 'regular') {
            valueType === 'Percentage' ? discount.singleDiscountPercentage = data.discountValue / 100 : discount.singleDiscountAmount = data.discountValue;
        } else {
            valueType === 'Percentage' ? discount.combiDiscountPercentage = data.discountValue / 100 : discount.combiDiscountAmount = data.discountValue;
        }; 

        const createDiscount = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/discounts`, discount);
                setDiscountCreated(response.data);
                setReRender(Math.random());
            } catch (error) {
                console.log('errrr', error.response.data.message);
                setDiscountError(error.response.data.message);
            }
        }

        console.log('discount : ', discount);
        createDiscount();

    }    

    const errorFunction = () => {
        setCreatingDiscount(false);
        setDiscountError(false);
    }

    const rendering = () => {
        return (
            <Modal>
                <IndividualDiscountCodeSC>

                    {creatingDiscount && <ActionInProgress
                        text='Discount is being created'
                        successText={ individualDiscountCode._id ? 'Discount updated' : 'Discount created'}
                        errorText={discountError}
                        completed={discountCreated}
                        error={discountError}
                        successOption1={{text : 'Close', onClick : closeFunction}}
                        errorOption1={{text : 'Close', onClick : errorFunction}}
                    />}

                    <> {/* Deleting discount */}

                        {action && <AreYouSure 
                            title={action.title}
                            textOption1={action.textOption1}
                            textOption2={action.textOption2}
                            function1={() => action.function1()}
                            function2={() => action.function2()}
                        />}

                        {deletingDiscount && <ActionInProgress
                            text='Discount is being deleted'
                            successText='Discount deleted'
                            errorText={discountDeleteError}
                            completed={discountDeleted}
                            error={discountDeleteError}
                            successOption1={{text : 'Close', onClick : closeFunction}}
                            errorOption1={{text : 'Close', onClick : errorFunction}}
                        />}
                    </>

                    <Header 
                        closeFunction={closeFunction}
                        formRef={formRef}    
                        errors={errors}
                        promptDeleteDiscount={promptDeleteDiscount}

                    />

                    <div className='content-container'>
                        <form 
                            noValidate 
                            onSubmit={handleSubmit(onSubmit)} 
                            ref={formRef}    
                        >
                            <DiscountInformation 
                                individualDiscountCode={individualDiscountCode} 
                                register={register}
                                errors={errors}
                                control={control}
                                setDiscountType={setDiscountType}
                                discountType={discountType}
                                valueType={valueType}
                                setValueType={setValueType}
                                setValue={setValue}
                                numberOfUsersForDiscount={numberOfUsersForDiscount}
                                setNumberOfUsersForDiscount={setNumberOfUsersForDiscount}
                            />
                            <ValidOnTrainingsList 
                                individualDiscountCode={individualDiscountCode} 
                                trainings={trainings}
                                selectedTrainings={selectedTrainings}
                                setSelectedTrainings={setSelectedTrainings}
                            />
                        </form>
                    </div>
                </IndividualDiscountCodeSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualDiscountCodeSC = styled.div`
    display : flex; 
    flex-direction : column;
    min-width : 80vw;
    max-width : 80vw;
    min-height : 80vh;
    max-height : 80vh;
    overflow : hidden;
    background-color : white;
    border-radius : 10px;

    .content-container {
        overflow-y : scroll;
        flex : 1;
        display : flex; 
        flex-direction : column;
    }

    form {
        flex-grow : 1;
        display : flex;
        min-width : 100%;
        max-width : 100%;
    }
`

export default IndividualDiscountCode