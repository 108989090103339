import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';

const AddTrainingModal = ({ closeFunction, setAddTraining}) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <Modal>
                <AddTrainingModalSC>
                    <div className='top-row'>
                        <Button 
                            variant='contained' 
                            color='primary'
                            onClick={closeFunction}    
                        >
                            close
                        </Button>
                    </div>
                    <div className='option-container' onClick={() => {
                        setAddTraining(true)
                        closeFunction()
                    }}>
                        <h1>Standard</h1>
                        <p className='description'>
                            Use a template to schedule a new session
                        </p>
                        <p className='example'>
                            Example : The training 'Introduction to Excel' given at our location
                        </p>

                    </div>

                    <div className='option-container'>
                        <h1>Custom</h1>
                        <p className='description'>
                            Create a one-off training for a specific customer
                        </p>
                        <p className='example'>
                            Example : A company requests a non-standard training on Excel to go deeper into the subject for a small group
                        </p>
                    </div>
                </AddTrainingModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTrainingModalSC = styled.div`
    padding : 45px;
    background-color : white;
    border-radius : 5px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    display : flex; 
    gap : 35px;
    justify-content : center;
    
    .option-container {
        display : flex;
        flex-direction : column;
        padding : 25px;
        border : 1px solid #f0f0f0;
        border-radius : 10px;
        max-width : 200px;

        &:hover {
            cursor : pointer;
            background-color : #f0f0f0;
        }

        h1 {
            font-size : 1.5em;
            margin-bottom : 15px;
        }

        .example {
            font-size : 0.8em;
            color : #a0a0a0;
            margin-top : 15px;
        }

        img {
            width : 100%;
            aspect-ratio : 1/1;
            margin : 15px 0;            
        }
    }
`

export default AddTrainingModal