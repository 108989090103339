import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 

const Category = ({ trainingToEditLocal , setTrainingToEditLocal }) => {
    
    const [category, setCategory] = React.useState(trainingToEditLocal.customProperties.category);

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    React.useEffect(() => {
        if(trainingToEditLocal) {
            setTrainingToEditLocal({
                ...trainingToEditLocal,
                customProperties : {
                    ...trainingToEditLocal.customProperties,
                    category : category
                }
            });
        }
    }, [category]);

    const rendering = () => {
        return (
            trainingToEditLocal && 
                <CategorySC>
                    <FormControl
                        sx={{
                            width : '100%', 
                        }}
                        fullWidth>
                        <InputLabel 
                            id="demo-simple-select-label"
                            sx={{
                                backgroundColor : 'white',
                                paddingRight : '5px',

                            }}    
                        >
                            Category
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Age"
                            onChange={handleChange}
                            sx={{ fontFamily : 'inherit', fontSize : 'inherit', color : 'black'}}
                        >
                        <MenuItem 
                            value={'qlik'}
                            sx={{
                                padding : '10px'
                            }}    
                        >Qlik</MenuItem>
                        <MenuItem 
                            value={'powerbi'}
                            sx={{
                                padding : '10px'
                            }}    
                        >PowerBI</MenuItem>
                        <MenuItem 
                            value={'fabric'}
                            sx={{
                                padding : '10px'
                            }}    
                        >Fabric</MenuItem>
                        </Select>
                    </FormControl>
                </CategorySC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CategorySC = styled.div`
`

export default Category