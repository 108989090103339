import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const BasicInfo = ({ visible }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <BasicInfoSC style={{display: visible ? 'block' : 'none'}}>
                <TextField
                    label="Training Name"
                    variant="outlined"
                />
                <TextField
                    label="Training Description"
                    variant="outlined"
                />
            </BasicInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BasicInfoSC = styled.div`
    
`

export default BasicInfo