import React from 'react';
import styled from 'styled-components'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';

const DatesController = ({ control, errors, individualDiscountCode }) => {

    const rendering = () => {
        return (
            <DatesControllerSC>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='date-pickers-container'>
                        <div className='date-picker-div'>
                            <Controller
                                name="startDate"
                                control={control}
                                rules={{
                                    required: "Start date is required"
                                }}
                                render={({ field }) => {
                                    console.log('field : ', field); 
                                    console.log('dayjs', dayjs())
                                    return (
                                        <DatePicker
                                            {...field}
                                            label="Start Date"
                                            value={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            slotProps={{
                                                textField: {
                                                error: !!errors.startDate,
                                                helperText: errors.startDate?.message,
                                                },
                                            }}
                                        />
                                    )
                                } }
                            />
                        </div>
                        <div className='date-picker-div'>
                            <Controller
                                name="endDate"
                                control={control}
                                rules={{
                                    required: "End date is required"
                                }}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            {...field}
                                            label="End Date"
                                            value={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            slotProps={{
                                                textField: {
                                                error: !!errors.endDate,
                                                helperText: errors.endDate?.message,
                                                },
                                            }}
                                        />
                                    )
                                } }
                            />
                        </div>
                    </div>
                </LocalizationProvider>
            </DatesControllerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DatesControllerSC = styled.div`
    margin-bottom : 25px;

    .date-pickers-container {
        display: flex;
        justify-content: space-between;
        gap : 15px;
    }

`

export default DatesController