import React from 'react';
import styled from 'styled-components';
import { NewSessionModalContext } from './ScheduleNewSessionModal';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SchoolIcon from '@mui/icons-material/School';
import { Button } from '@mui/material';
const SelectContentType = ({ closeFunction }) => {

/* ===================================================
    IMPORTS
=================================================== */
    const { contentType, setContentType, setPopUpTrainingType } = React.useContext(NewSessionModalContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <SelectContentTypeSC>
                <div className='training-type-container' onClick={() => {
                        setContentType('standard')
                        setPopUpTrainingType(false)
                    }}>
                    <h1>From template</h1>
                    <WidgetsIcon />
                    <p className='description'>
                        A training that will be scheduled on your website and users can sign up for it. 
                    </p>
                    <p className='example'>
                        Example : The training 'Introduction to Excel'.
                    </p>
                </div>

                <div className='training-type-container' onClick={() => {
                    setContentType('custom')
                    setPopUpTrainingType(false)
                }}>
                    <h1>Custom</h1>
                    <SchoolIcon />

                    <p className='description'>
                        Training that is requested by a company and is not scheduled on your website.
                    </p>
                    <p className='example'>
                        Example : A company requests a specific on-site training on an Excel subject
                    </p>
                </div>

                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {closeFunction()}}
                >
                    Close
                </Button>
            </SelectContentTypeSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SelectContentTypeSC = styled.div`
    display : flex; 
    justify-content : center;
    gap : 20px;
    flex-grow  : 1;
    align-items : center;
    position : relative; 

    &>button {
        position : absolute;
        top : 20px;
        right : 20px;
    }

    .training-type-container {
        margin-bottom : 20px;
        max-width : 250px;
        min-height : 250px;
        border : 1px solid #f0f0f0;
        border-radius : 10px;
        padding : 25px;
        display : flex;
        flex-direction : column;

        &>svg {
            font-size : 6rem;
            margin : 20px 0;
            color : #8a8a8a;
        }

        &:hover {
            cursor : pointer;
            box-shadow : 0px 0px 10px 0px #f0f0f0;
        }

        h1 {
            font-size : 1.5rem;
        }

        .description {
            font-size : 1rem;

        }

        .example {
            font-size : 0.8rem;
            color : grey;
            font-weight : 300;
        }


    }
`

export default SelectContentType