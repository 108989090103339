import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import { CustomInstanceContext } from './AddCustomInstance';
import DropdownWithTableModal from '../../GeneralComponents/InputFields/DropDownWithTableModal';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import { TextField } from '@mui/material';

const Location = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { register, location, locations, setSelectedLocation, locationType, setLocationType, LocationRef} = React.useContext(CustomInstanceContext);

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */
    const LocationComponent = (p) => {
        const { title, thumbnailImageUrl } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={thumbnailImageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{title}</p>
            </div>
        );
    }

    const rendering = () => {
        return (
            <LocationSC className='content-container-item content-section' id='section6' ref={LocationRef}>
                <TitleComponent title={'Location'}/>
                    <div className='location-options'>
                        <FormControl
                            sx={{
                                width : '100%', 
                            }}
                            fullWidth>
                                <InputLabel 
                                    id="demo-simple-select-label"
                                    sx={{
                                        backgroundColor : 'white',
                                        paddingRight : '5px',

                                    }}    
                                >
                                    Location type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={locationType}
                                    label="Age"
                                    onChange={(e) => setLocationType(e.target.value)}
                                    sx={{ fontFamily : 'inherit', fontSize : 'inherit', color : 'black'}}
                                >
                                    <MenuItem value={'customer-location'} sx={{ padding : '10px'}}>Customer provides location</MenuItem>
                                    <MenuItem value={'our-location'} sx={{ padding : '10px'}}>We provide location</MenuItem>
                                    <MenuItem value={'online'} sx={{ padding : '10px'}}>Online training</MenuItem>
                                </Select>
                        </FormControl>
                    </div>

                    {
                        locationType === 'customer-location' &&
                            <div className='adress'>
                                <TextField  
                                    label='Adress'
                                    type='text' 
                                    fullWidth
                                    {...register('customerAdress')}
                                />  
                                <TextField  
                                    label='Description for trainer'
                                    placeholder='how to get there, and where to park'
                                    type='text' 
                                    fullWidth
                                    multiline
                                    rows={4}
                                    {...register('descriptionForTrainer')}
                                /> 
                                {/* <TextField  
                                    label='Description for attendees'
                                    placeholder='In which meeting room is the training?'
                                    type='text' 
                                    fullWidth
                                    multiline
                                    rows={4}
                                    {...register('descriptionForAttendees')}
                                />   */}
                            </div>
                    }

                    {
                        locationType === 'our-location' &&
                            <>
                            {
                                locations.length > 0 ?
                                <DropdownWithTableModal 
                                    options={locations} 
                                    colDefs={[
                                        { valueGetter : p => p.data, cellRenderer : LocationComponent, headerName : 'Location' },
                                        { field: "adress", headerName : 'Adress', flex  : 1 },
                                    ]}
                                    keyToUse={'title'}
                                    setValueFunction={setSelectedLocation}
                                    valueFromParent={location}
                                    icon={<ApartmentIcon />}
                                    label='Location'
                                />
                                : <p className='no-values'>No locations in software</p>
                            }
                            </>
                    }

                    {
                        locationType === 'online' &&
                            <>
                                <TextField  
                                    label='Link to meeting'
                                    type='text' 
                                    fullWidth
                                    {...register('meetingLink')}
                                />  
                            </>
                    }




            </LocationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LocationSC = styled.div`
    .location-options {
        display : flex;
        max-width : 50%;
        justify-content : space-between;
        gap : 20px;
        margin-bottom : 20px;
    }

    .adress {
        display : flex;
        flex-direction : column;
        gap : 20px;
        max-width : 50%;
    }
`

export default Location