import React from 'react';
import styled from 'styled-components';
import UpcomingTrainings from './UpcomingTraining/UpcomingTrainings';
import KPIRow from './KPIRow/KPIRow';
import RecentRegistrations from './RecentRegistrations/RecentRegistrations';
import TrainingCalendar from './TrainingCalendar/TrainingCalendar2';
import LatestRegistrationsModal from './LatestRegistrationsModal/LatestRegistrationsModal';
import Search from '../GeneralComponents/Search';
import UICard from '../GeneralComponents/UIContainer/UICard';
import DashboardSearch from './DashboardSearch/DashboardSearch';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import AddTrainingButtonDashboard from './AddTrainingFromDashboard/AddTrainingButtonDashboard';
import AddInstance from '../addInstance2/AddInstance';
import AddCustomTraining from '../AddCustomTraining/AddCustomTraining';
import ScheduleNewSessionModal from '../ScheduleNewSessionModal/ScheduleNewSessionModal';

export const DashboardContext = React.createContext();

const Dashboard = () => {

    const [latestRegistrationsModal, setLatestRegistrationsModal] = React.useState(false);
    const [sessions, setSessions] = React.useState([]);
    const [newSessionModal, setNewSessionModal] = React.useState(false);
    const [reRender, setRerender] = React.useState(0);

    React.useEffect(() => {
        const getSessions = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/get-all/:temp`);
                setSessions(result.data);
            } catch(err) {
                console.log(err);
            }
        }

        getSessions();
    }, []);

    const rendering = () => {
        return (
            <DashboardContext.Provider value={{
                setLatestRegistrationsModal,
                setNewSessionModal,
                setRerender,
                reRender
            }}>
                <DashboardSC>

                    <> {/* Modals */} 
                    
                        {latestRegistrationsModal && 
                            <LatestRegistrationsModal 
                            />
                        }

                        {newSessionModal &&
                            <ScheduleNewSessionModal
                                setNewSessionModal={setNewSessionModal}
                            />
                        }
                    </>

                
                    <div className='top-row' style={{display : 'flex'}}>
                        <DashboardSearch 
                            sessions={sessions}
                        />
                        <AddTrainingButtonDashboard />
                    </div>
                    <KPIRow />
                    <div style={{display : 'flex'}}>
                        <UpcomingTrainings />
                        <div style={{ flexGrow : 1, display : 'flex', flexDirection : 'column', overflow: 'hidden'}}>
                            <TrainingCalendar />
                        </div>  
                    </div>
                </DashboardSC>
            </DashboardContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    flex : 1;
    padding : 20px;
    flex-direction : column;
    display : flex; 
    

`

export default Dashboard