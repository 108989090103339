import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import AddTrainingModal from './AddTrainingModal';
import { DashboardContext } from '../Dashboard';

const AddTrainingFromDashboard = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { setNewSessionModal } = React.useContext(DashboardContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (


            <AddTrainingFromDashboardSC>
                <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setNewSessionModal(true)}>
                    Schedule session
                </Button>
            </AddTrainingFromDashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTrainingFromDashboardSC = styled.div`
    min-height : 45px;
    max-height : 45px;
    display : flex;
    box-sizing : border-box;
    align-items : center;
    flex-direction : column;

    & button {
        width : 100%;
        height : 100%;
        min-height: 45px;
        margin-left : 20px;
        margin-right : 20px;
        font-size : 13px;
    }
`

export default AddTrainingFromDashboard