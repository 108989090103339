import React from 'react';
import styled from 'styled-components';
import SidebarSurveyResponses from './SurveyResponsesSidebar/SidebarSurveyResponses';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios';
import SurveyResponseItems from './SurveyResponsesContent/SurveyResponseItems';

export const SurveyResponsesContext = React.createContext();

const SurveyResponses = () => {

    const [locations, setLocations] = React.useState([]);
    const [trainers, setTrainers] = React.useState([]);
    const [trainings, setTrainings] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [instances, setInstances] = React.useState([]);
    const [resultsArray, setResultsArray] = React.useState([]);
    const [arrayOfData, setArrayOfData] = React.useState(null);
    const [allResults, setAllResults] = React.useState([]);


    const [associatedValues, setAssociatedValues] = React.useState({
        locations : [],
        trainers : [],
        trainings : []
    });

    const [filters, setFilters] = React.useState({
        locations : [],
        trainers:  [],
        trainings : [],
        startDates : [],
    });


    // Initial load of all the possible filter values
    React.useEffect(() => {

        const locations = [];
        const trainers = [];
        const trainings = [];

        const addLocations = (instance) => {
            if(instance.locationType === 'our-location') {
                if(!locations.includes(instance.location.title)) {
                    locations.push(instance.location.title);
                }
            } else if (instance.locationType === 'customer-location') {
                if(!locations.includes('customer location')) {
                    locations.push('customer location');
                }
            } else if (instance.locationType === 'online') {
                if(!locations.includes('online')) {
                    locations.push('online');
                }
            }
        }

        const addTrainers = (instance) => {
            if(!trainers.includes(instance.trainer.firstName)) {
                trainers.push(instance.trainer.firstName);
            }
        }

        const addTrainings = (instance) => {
            if(instance.trainingType === 'public') {
                if(!trainings.includes(instance.trainingName)) {
                    trainings.push(instance.trainingName);
                }
            } else {
                if(!trainings.includes('custom training')) {
                    trainings.push('custom training');
                }
            }
        }

        const fetchInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances`);

                response.data.forEach((instance) => {
                    addLocations(instance);
                    addTrainers(instance);
                    addTrainings(instance);
                });

            } catch (error) {
                console.error(error);
            }
        }

        const runFunction = async () => {            
            await fetchInstances();

            setLocations(locations);
            setTrainers(trainers);
            setTrainings(trainings);
            setLoading(false);
        }

        runFunction();

    }, [])

    // Initial load of all survey responses for each instance
    React.useEffect(() => {

        const fetchSurveyResponses = async () => {
            try {

                // Fetching the survey responses array 
                const fetchResponse  = await Axios.get(`${backEndAdress}/api/survey-responses/by-survey/66db45314ef085d831edbe39` );
                setAllResults(fetchResponse.data);

                const tempInstances = fetchResponse.data.map((item) => item.instance);

                // First extracting info from all the instances for which we have responses 
                const locations = [];
                const trainers = [];
                const trainings = [];
        
                const addLocations = (instance) => {
                    if(!locations.includes(instance.location.title)) {
                        locations.push(instance.location.title);
                    }
                }
        
                const addTrainers = (instance) => {
                    if(!trainers.includes(instance.trainer.firstName)) {
                        trainers.push(instance.trainer.firstName);
                    }
                }
        
                const addTrainings = (instance) => {
                    if(instance.trainingType === 'public') {
                        if(!trainings.includes(instance.trainingName)) {
                            trainings.push(instance.trainingName);
                        }
                    } else {
                        if(!trainings.includes('custom training')) {
                            trainings.push('custom training');
                        }
                    }
                }

                tempInstances.forEach((instance) => {
                    addLocations(instance);
                    addTrainers(instance);
                    addTrainings(instance);
                });

                setAssociatedValues({
                    locations,
                    trainers,
                    trainings
                });

                const responses = fetchResponse.data.map((item) => item.responses);
                            
                let final = [];

                responses.map((item) => {
                    item.map((response) => {
                        final.push({
                            question : response.question,
                            type : response.type,
                            answer : response.possibleAnswers.length>0 ? response.possibleAnswers[response.answerIndex-1] : response.answerIndex,
                        });
                    })
                })

                return final;
            } catch (error) {
                console.error(error);
            }
        }

        const groupAnswers = (surveyResponses) => {

            const result = {}

            surveyResponses.map((surveyItem) => {

                    const { question , answer } = surveyItem;

                    if(!result[question]) {
                        result[question] = {};
                    }

                    if (!result[question][answer]) {
                        result[question][answer] = 0;
                    }

                    result[question][answer]++;

            })

            return result; 
        }

        const changeTheFormat = (groupedAnswers) => {
            let result = [];

            for(let key in groupedAnswers) {
                
                if (groupedAnswers.hasOwnProperty(key)) { 
                    
                    const objectTemp = {
                        question : key,
                        data : [
                            ["Answer", "number of answers"]
                        ]
                    };

                    function dictToArray(dict) {
                        let array = [];
                        for (const [key, value] of Object.entries(dict)) {
                        array.push([key, value]);
                        }
                        return array;
                    }

                    const answers = dictToArray(groupedAnswers[key]);
                    objectTemp.data = objectTemp.data.concat(answers);
                    result.push(objectTemp);

                }
            }
            return result; 
        }

        const runFunction = async () => {
            const surveyResponses = await fetchSurveyResponses();
            const groupedAnswers = groupAnswers(surveyResponses);
            const final = changeTheFormat(groupedAnswers);
            const addingTypes = final.map((item) => {
                return {
                    ...item,
                    type : surveyResponses.find((response) => response.question === item.question).type
                }
            });

            setArrayOfData(addingTypes);
        }

        runFunction();

    }, [])

    React.useEffect(() => {

        const locationFilters = filters['locations'];
        const trainerFilters = filters['trainers'];
        const trainingFilters = filters['trainings'];

        const filterResults = (results) => {
            let tempResults = results;

            if(locationFilters.length > 0) {
                console.log('location filters : ', locationFilters);
                console.log('item', results);



                tempResults = tempResults.filter((item) => locationFilters.includes(item.instance.location.title.toLowerCase()));
            }

            if(trainerFilters.length > 0) {
                tempResults = tempResults.filter((item) => trainerFilters.includes(item.instance.trainer.firstName));
            }

            if(trainingFilters.length > 0) {
                tempResults = tempResults.filter((item) => {
                    if(item.instance.trainingType === 'public') {
                        return trainingFilters.includes(item.instance.trainingName);
                    } else {
                        return trainingFilters.includes('custom training');
                    }
                });
            }

            return tempResults;
        }

        const groupAnswers = (surveyResponses) => {

            const result = {}

            surveyResponses.map((surveyItem) => {

                    const { question , answer } = surveyItem;

                    if(!result[question]) {
                        result[question] = {};
                    }

                    if (!result[question][answer]) {
                        result[question][answer] = 0;
                    }

                    result[question][answer]++;

            })

            return result; 
        }

        const changeTheFormat = (groupedAnswers) => {
            let result = [];

            for(let key in groupedAnswers) {
                
                if (groupedAnswers.hasOwnProperty(key)) { 
                    
                    const objectTemp = {
                        question : key,
                        data : [
                            ["Answer", "number of answers"]
                        ]
                    };

                    function dictToArray(dict) {
                        let array = [];
                        for (const [key, value] of Object.entries(dict)) {
                        array.push([key, value]);
                        }
                        return array;
                    }

                    const answers = dictToArray(groupedAnswers[key]);
                    objectTemp.data = objectTemp.data.concat(answers);
                    result.push(objectTemp);

                }
            }
            return result; 
        }

        const runFunction = async (locations) => {

            console.log('from the start : ', locations);

            const filteredResponses = filterResults(allResults);

            // let locationsAssociated = [];
            // let trainersAssociated = [];
            // let trainingsAssociated = [];
   
            // const addLocations = (instance, locations) => {

            //     if(locationFilters.length === 0) {
            //         if(!locationsAssociated.includes(instance.location.title.toLowerCase())) {
            //             locationsAssociated.push(instance.location.title.toLowerCase());
            //         }
            //     } else {
            //         locationsAssociated = locations;
            //     }

            // }
    
            // const addTrainers = (instance) => {
            //     if(!trainersAssociated.includes(instance.trainer.firstName)) {
            //         trainersAssociated.push(instance.trainer.firstName);
            //     }
            // }
    
            // const addTrainings = (instance) => {
            //     if(instance.trainingType === 'public') {
            //         console.log('public : ', instance)
            //         if(!trainingsAssociated.includes(instance.trainingName)) {
            //             trainingsAssociated.push(instance.trainingName);
            //         }
            //     } else {
            //         if(!trainingsAssociated.includes('custom training')) {
            //             trainingsAssociated.push('custom training');
            //         }
            //     }
            // }

            // filteredResponses.forEach((item) => {
            //     addLocations(item.instance, locations);
            //     addTrainers(item.instance);
            //     addTrainings(item.instance);
            // });


            // setAssociatedValues({
            //     locations : locationsAssociated,
            //     trainers : trainersAssociated,
            //     trainings : trainingsAssociated
            // });

            const responses = filteredResponses.map((item) => item.responses);
                            
            let temp = [];

            responses.map((item) => {
                item.map((response) => {
                    temp.push({
                        question : response.question,
                        type : response.type,
                        answer : response.possibleAnswers.length>0 ? response.possibleAnswers[response.answerIndex-1] : response.answerIndex,
                    });
                })
            })

            const surveyResponses = temp;
            const groupedAnswers = groupAnswers(surveyResponses);
            const final = changeTheFormat(groupedAnswers);
            const addingTypes = final.map((item) => {
                return {
                    ...item,
                    type : surveyResponses.find((response) => response.question === item.question).type
                }
            });

            setArrayOfData(addingTypes);
        }

        runFunction(locations);

    }, [filters])


    const rendering = () => {

        return (
            !loading && 
            <SurveyResponsesContext.Provider value={{
                locations,
                trainers,
                trainings,
                filters,
                setFilters,
                instances
            }}>
                <SurveyResponsesSC>
                    <SidebarSurveyResponses 
                        resultsArray={resultsArray}
                        associatedValues={associatedValues}
                    />
                    <SurveyResponseItems 
                        filters={filters} 
                        resultsArray={resultsArray}
                        setResultsArray={setResultsArray}    
                        arrayOfData={arrayOfData}
                        setArrayOfData={setArrayOfData}
                    />
                </SurveyResponsesSC>
            </SurveyResponsesContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyResponsesSC = styled.div`
    display : flex; 
    flex : 1;
    margin : 10px;
`

export default SurveyResponses