import React, { useEffect } from 'react';
import styled from 'styled-components';
import TrainingTiles from './TraingTiles';
import TrainingTile from './TrainingTile';
import PreOpenModal from './PreOpenModal';
import { Navigate, useNavigate } from 'react-router-dom';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios'; 
import { Button, CircularProgress } from '@mui/material';
import AddTraining from '../addTraining/AddTraining';
import TrainingsGridTitleAndSearch from './TrainingsGridTitleAndSearch';
import AddTraining2 from '../addTraining/AddTraining2';
import AddInstance from '../addInstance2/AddInstance';
import { AppContext } from '../../App';
import AddOrEditTraining from '../addOrEditTrainingComponent/AddOrEditTraining';
import  { v4 as uuid, v4 } from 'uuid';
import { defaultTouchPointsArray } from '../addTraining/defaultTouchPointsArray';
import EditTraining from '../IndividualTrainingFinal/EditMode/EditTraining';
export const TrainingsOverViewContext = React.createContext(null);

const TrainingsOverview = () => {

    const navigate = useNavigate();

    const { appState } = React.useContext(AppContext);

    const [state, setState] = React.useState({
        preOpenTraing : null,
        trainings : [],
        loading : true,
        addInstanceToggled : false,
        addTrainingToggled : false,
        trainingToEdit : null,
        numForFetching : 0,
    });

    const [trainings, setTrainings] = React.useState([]);
    const [instances, setInstances] = React.useState([]);

    React.useEffect(() => {

        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/`);
                setState({...state, loading : false});
                setTrainings(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances`);
                setInstances(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getTrainings(); 
        getInstances();

    }, [state.numForFetching]);

    const openSavedTraining = (training) => {
        setState({
            ...state, 
            preOpenTraing : training,
            addTrainingToggled : false,
            trainingToEdit : null
        });
    }

    const { preOpenTraing , loading, addInstanceToggled, addTrainingToggled } = state;

    const rendering = () => {
        if(loading) return ( 
            <div className='loading-screen'>
                <CircularProgress />
            </div>
        )

        return (
            <TrainingsOverViewContext.Provider value={{ state, setState }}>
                <TrainingsOverviewSC>
                    <>
                        {/* POP UP SCREENS */}

                        {preOpenTraing && 
                            <PreOpenModal 
                                preOpenTraing={preOpenTraing}
                                state={state}
                                setState={setState}    
                            />
                        }

                        {addInstanceToggled &&
                            <AddInstance
                                state={state}
                                setState={setState}
                                training={preOpenTraing}
                            />
                        }

                        {state.trainingToEdit &&
                        <EditTrainingSC>
                            <EditTraining 
                                state={state}
                                setState={setState}
                                trainingToEdit={state.trainingToEdit}
                                appState={appState}
                                closeWindowFunction={() => setState({...state, trainingToEdit : null})}
                                openSavedTraining={openSavedTraining}
                            />
                        </EditTrainingSC>
                        }
                    </>
                    
                <TrainingsGridTitleAndSearch />

                <div className='trainings-grid'>
                        {trainings.map((training, index) => {

                            const instancesCount = instances.filter(instance => instance.trainingId === training._id).length;                               

                            return (
                                <TrainingTile 
                                    key={index} 
                                    training={training} 
                                    state={state}
                                    setState={setState}
                                    instancesCount={instancesCount}
                                />
                            )
                        })}
                </div>
                    <Button 
                        className='button-bottom-right' 
                        variant='contained' 
                        color='primary'
                        onClick={() => navigate('add-training')}    
                    >
                        Add training
                    </Button>
                    <Button 
                        className='button-bottom-right' 
                        variant='contained' 
                        color='primary'
                        onClick={() => navigate('/add-training')}    

                    >
                        Add new training
                    </Button>
                </TrainingsOverviewSC>
            </TrainingsOverViewContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingsOverviewSC = styled.div`
    flex-grow : 1;
    position : relative;
    padding : 15px;
    box-sizing : border-box ; 
    overflow: hidden;
    overflow-y : scroll;

    .trainings-grid {
        padding : 15px;
        box-sizing : border-box;
        max-width : 100%;
        display : grid;
        grid-template-columns : repeat(5, minmax(200px, 25%));
        grid-gap : 15px;

    }

    .button-bottom-right {
        position : fixed;
        bottom : 30px;
        right : 30px;
    }
`

const EditTrainingSC = styled.div`
    flex : 1;
    overflow: hidden;
    display : flex;
`

export default TrainingsOverview