import React from 'react';
import styled from 'styled-components';
import SearchBar from './SearchBar';
import TemplatesGrid from './TemplatesGrid'; 
import Preview from '../Content/Preview';
import Search from '../../GeneralComponents/Search';
import { Button } from '@mui/material';
import DeleteTemplatePopUp from './DeleteTemplatePopUp';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios' ;

const LoadTemplateScreen = ({
    mailTemplates,
    setEmailTemplate,
    mailTemplate,
    handleBarsObject,
    state,
    setState,
    templatesNew
}) => {

    const [searchQuery, setSearchQuery] = React.useState('');
    const [deletePopUp, setDeletePopUp] = React.useState(false); 
    const [templates, setTemplates] = React.useState([]);

    const deleteTemplateFunction = () => {
        const deleteTemplate = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/mail-templates/${mailTemplate._id}`);

                let templatesTemp = response.data ; 
                const allTemplatesWithFolder = templatesTemp.filter((template) => template.folder);
                const generalTemplates = templatesTemp.filter((template) => !template.folder).map((template) => ({...template, folder : 'general'})); 
                templatesTemp = [...allTemplatesWithFolder, ...generalTemplates];      
                setTemplates(templatesTemp);
                setDeletePopUp(false);
                setEmailTemplate(null);

            } catch (error) {
                console.log(error);
            }
        }


        deleteTemplate();
    }

    const rendering = () => {
        return (
            <LoadTemplateScreenSC>
                <div className='column-one'>

                    {deletePopUp &&     
                        <DeleteTemplatePopUp 
                            setDeletePopUp={setDeletePopUp} 
                            deleteTemplateFunction={deleteTemplateFunction}
                            handleClose={() => setDeletePopUp(false)}    
                    />}

                    <h1 style={{ padding : '15px', fontSize : '18px'}}>Mail templates</h1>

                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        placeholder='Search templates'
                        style={{ margin : '15px', marginTop : '0', border : 0}}
                    />

                    <TemplatesGrid 
                        mailTemplates={mailTemplates}  
                        searchQuery={searchQuery}
                        setMailTemplateForPreview={setEmailTemplate}
                        mailTemplate={mailTemplate}
                        templates={templates}
                        setTemplates={setTemplates}
                        templatesNew={templatesNew}
                    />

                    <Button
                        variant='outlined'
                        color='primary'
                        disabled={!mailTemplate}
                        style={{ margin : '15px', marginTop : '0', flexGrow : 0, flex : 0}}
                        onClick={() => setDeletePopUp(true)}
                    >
                        Delete
                    </Button>
                </div>
                <div className='column-two'>
                    <Preview 
                        mailTemplate={mailTemplate}
                        handleBarsObject={handleBarsObject}
                        state={state}
                        setState={setState}
                    />
                </div>
            </LoadTemplateScreenSC>
        );
    }


    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
} 

const LoadTemplateScreenSC = styled.div`
    flex-grow : 1;
    display : flex;
    overflow-y : hidden;
    position : relative ; 

    .column-one {   
        display : flex;
        flex-direction : column;
        min-width : 300px;
        max-width : 300px;
        border-right : 1px solid lightgrey;
        overflow : hidden;
        padding : 15px;
    }

    .column-two {
        display : flex;
        flex-direction : column;
        flex : 1;
        overflow : hidden;
    }
`

export default LoadTemplateScreen