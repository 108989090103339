import React from 'react';
import styled from 'styled-components';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import { navigationItems } from './navigationItems';
import ActualDashboard from '../dashboard/Dashboard';
import SubNavigation from '../GeneralComponents/navigation/SubNavigation';

const Dashboard = () => {

    const rendering = () => {
        return (
            <DashboardSC>
                {/* <SubNavigation navigationItems={navigationItems}/> */}
                    <Routes>
                        <Route exact path="/" element={
                            <ActualDashboard />
                        } />
                    </Routes>
            </DashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    flex : 1;
    display : flex;
    flex-direction : column;
    overflow-y : scroll;
`

export default Dashboard