import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../.././App';

const SidebarItem = ({ item, index, selected, clickItem }) => {

    const [hoveredItem, setHoveredItem] = React.useState(null);
    const { appState } = React.useContext(AppContext);

    const hoverTimer = React.useRef(null);

    const handleMouseEnter = (item) => {
      hoverTimer.current = setTimeout(() => {
        console.log('yoo')
        setHoveredItem(item.name);
      }, 800);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimer.current);
        setHoveredItem(null);
      };

    const rendering = () => {
        return (
            <SidebarItemSC
                key={index} 
                className={`menu-item ${selected}`}
                onClick={() => clickItem(index, item)}    
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={() => handleMouseLeave()}
            >
                {item.icon}
                {appState.sideBarExpanded && <h1>{item.name}</h1>}
                {hoveredItem && !appState.sideBarExpanded && <p className='hovered-item'>{hoveredItem}</p>}
            </SidebarItemSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarItemSC = styled.div`
`

export default SidebarItem