import React from 'react';
import styled from 'styled-components';
import { TrainingCalendarContext } from './TrainingCalendar';
import TrainingCard from './TrainingCard';
import Modal from '../../GeneralComponents/Modal';
import moment from 'moment'; 

const TrainingBar = ({ instance, days, trainer }) => {
    const getColorForTraining = (instance) => {
        switch(instance.trainingName) {
            case 'Qlik sense advanced 7':
                return '#F3722C';
            case 'new training':
                return '#90BE6D';
            case 'new training 2':
                return '#EE4266';
            default:
                return 'whie';
        }
    }

    const color = getColorForTraining(instance);
    
    const [modalOpened, setModalOpened] = React.useState(false);

    const daysOfInstance = instance.dates.map((date) => date.day).map(date => moment(date).format('DD/MM/YYYY'));
    const daysOfMonth = days.map(day => moment(day).format('DD/MM/YYYY'));
    
    const matchingDays = daysOfInstance.filter(date => daysOfMonth.includes(date));
    const length = matchingDays.length;
    const startDayNumber = moment(matchingDays[0], 'DD/MM/YYYY').date();
    const endDayNumber = moment(matchingDays[matchingDays.length - 1], 'DD/MM/YYYY').date();

    const checkStartOverlap = () => {
        if(startDayNumber === 1 && daysOfInstance.indexOf(startDayNumber) !== 0) {
            return true; 
        } else {
            return false;
        }
    }

    const checkEndOverlap = () => {
        if(endDayNumber === days.length && daysOfInstance.indexOf(endDayNumber) !== daysOfInstance.length - 1) {
            return true; 
        } else {
            return false;
        }
    }

    const startOverLap = checkStartOverlap();
    const endOverLap = checkEndOverlap();

    const rendering = () => {
        return (
            <TrainingBarSC
                onClick={() => setModalOpened(true)}
                style={{ 
                    minWidth : `${length * 50}px`, 
                    left : `${(startDayNumber - 1) * 50}px`,
                    borderRadius : `${endOverLap ? '50px 0px 0px 50px' : startOverLap ? '0 50px 50px 0px' : '50px'}`,
                    borderLeft : `${endOverLap ? '1px solid lightgrey' : 'none'}`,
                }}
                >

                {modalOpened && 
                    <Modal>
                        <TrainingCard 
                            instance={instance} 
                            setModalOpened={setModalOpened}
                            trainingId={instance.trainingId}
                        />
                    </Modal>
                }
                <img 
                    src={instance.trainer.imageUrl} 
                    alt='trainer'
                    

                />
            </TrainingBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingBarSC = styled.div`
    height : 40px;
    top : 3px;
    background-color : #f0f0f0;
    position : absolute;

    &:hover {
        cursor : pointer; 
        background-color : #e0e0e0;
    }
    img {
        background-color : white;
        max-width : 38px;
        max-height : 37px;
        margin-left : 3px;
        margin-top : 2px;
        border-radius : 50%;
    }
`

export default TrainingBar