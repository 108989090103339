import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import { Button, Switch } from '@mui/material';
import Modal from '../../GeneralComponents/Modal';
import Preview from '../../emailSchedule/Content/Preview';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios'; 
import PreviewModal from './PreviewModal';
import { CustomInstanceContext } from './AddCustomInstance';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Communication = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { titleRef, descriptionRef, editor, trainer, setTouchPoints, CommunicationRef } = React.useContext(CustomInstanceContext);

/* ===================================================
    STATE
=================================================== */
    const [state, setState] = React.useState({});
    const [allMailTemplates, setAllMailTemplates] = React.useState([]); 
    const [mailTemplate, setMailTemplate] = React.useState(false);
    const [handleBarsObject, setHandleBarsObject] = React.useState({});
    const [nonInstanceHandleBars, setNonInstanceHandleBars] = React.useState({});

/* ===================================================
    EVENTS
=================================================== */  
    React.useEffect(() => {

        const getMailTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates`);
                setAllMailTemplates(response.data);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }

        const runFunction = async () => {

            const mailTemplates = await getMailTemplates();
            const touchPointsAray = mailTemplates.map(template => {
                if(template._id === '67c16d973e99c6a074460694') {
                    return {
                        touchPointId : 'temp',
                        touchPointName : template.title,
                        timingMode : 'Trigger',
                        selectedTrigger : 'sign-up',
                        numberOfDays : 0,
                        beforeOrAfter : null, 
                        time : null, 
                        subject : template.subjectLine,
                        sendAt : null, 
                        mailTemplate : template
                    }
                } else {
                    return
                }
            });

            const removeEmpties = touchPointsAray.filter(touchPoint => touchPoint !== undefined);

            setTouchPoints(removeEmpties);
        }

        runFunction(); 

    },[]);

/* ===================================================
    FUNCTIONS
=================================================== */
    const previewMailFunction = (id) => {
        const template = allMailTemplates.find(template => template._id === id);

        const trainingName = titleRef.current.value;
        const trainingDescription = descriptionRef.current.value;
        const practicalInfo = editor.getText();

        setNonInstanceHandleBars({
            training_name : titleRef.current.value,
            short_description : descriptionRef.current.value,
            practical_info : practicalInfo,
            trainer_name : `${trainer.firstName} ${trainer.lastName}`,
            trainer_image : trainer.imageId,
            trainer_description : trainer.shortDescription,
            trainer_phone : trainer.phone,
            trainer_linkedin : trainer.linkedIn,
            trainer_email : trainer.email,

        })
        setMailTemplate(template);
    }

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <CommunicationSC className='content-container-item content-section' id='section8' ref={CommunicationRef}>

                {mailTemplate && 
                    <PreviewModal
                        mailTemplate={mailTemplate}
                        handleBarsObject={handleBarsObject}
                        state={state}
                        setState={setState}
                        closeFunction={() => setMailTemplate(null)}
                        nonInstanceHandleBars={nonInstanceHandleBars}
                    />
                }

                <TitleComponent title='Communication' />
                    <div className='communication-row'>
                        <div className='communication-item-switch'>
                            <p>Confirmation on sign-up</p>
                            <Switch onChange={(e) => console.log(e)} size="small" defaultChecked={true} />
                        </div>
                        <div className='email-preview-button'>
                            <Button
                                startIcon={<MailOutlineIcon />}
                                variant='contained'
                                color='primary' 
                                type='button'
                                onClick={() => previewMailFunction('67c16d973e99c6a074460694')}
                            >Preview</Button>
                        </div>
                    </div>

                    {/* <div className='communication-row'>
                        <div className='communication-item-switch'>
                            <p>Send reminder</p>
                            <Switch onChange={(e) => console.log(e)} size="small" />
                        </div>
                        <div className='email-preview-button'>
                            <Button
                                startIcon={<MailOutlineIcon />}
                                variant='contained'
                                color='primary' 
                                type='button'
                                onClick={() => previewMailFunction('67c16d973e99c6a074460694')}
                            >Preview</Button>
                        </div>
                    </div> */}
            </CommunicationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CommunicationSC = styled.div`
    
    .communication-item-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self : flex-start; 
        border : 1px solid #d3d3d3;
        padding : 15px;
        border-radius : 5px;
    }

    .communication-row {
        display: flex;
        margin-bottom : 10px;
    }

    .email-preview-button {
        margin-left: 10px;
        box-sizing : border-box;
        max-height : 100%;
        display : flex;
        justify-content : center;

        button {
            background-color: white;
            color : grey;
            border : 1px solid #d3d3d3;
            box-shadow : 0;
            border: none;
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            min-height : 98%;
            box-sizing : border-box;
        }
    }
`   

export default Communication