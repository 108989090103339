import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import { Chip } from '@mui/material';
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomInstanceContext } from './AddCustomInstance';

const DateAndTime = ({ 
    visible, 
    control, 
    errors, 
    selectedDates, 
    setSelectedDates, 
    trainingDays, 
    setTrainingDays, 
    DateAndTimeRef
 }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */
    const [value, setValue] = React.useState(new Date());
/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */
    const handleDateChange = (newDate) => {

        const day = dayjs(newDate).format('DD/MM/YYYY'); 

        let trainingDaysTemp = [...trainingDays];

        if(trainingDays.map((td) => td.day).includes(day)) {
            console.log('includes BOI');
            trainingDaysTemp = trainingDays.filter((td) => td.day !== day);
        } else {
            console.log('not include')
            trainingDaysTemp.push({
                day : day,
                start : '09:00',
                end : '17:00'
            });
        }

        setTrainingDays(trainingDaysTemp);
    };

    const removeDate = (date) => {  
        setSelectedDates((prevDates) => prevDates.filter((d) => d !== date));
    };

    const updateStartTime = (value, date) => {
        
        const hours = value.hour();
        const minutes = value.minute();
        
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const updatedTrainingDays = trainingDays.map((td) => {
            if(td.day === date.day) {
                td.start = `${formattedHours}:${formattedMinutes}`;
            }
            return td;
        });

        console.log('updated training days : ', updatedTrainingDays);

        setTrainingDays(updatedTrainingDays);

    }
    
    const updateEndTime = (value, date) => {
        const hours = value.hour();
        const minutes = value.minute();

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const updatedTrainingDays = trainingDays.map((td) => {
            if(td.day === date.day) {
                td.end = `${formattedHours}:${formattedMinutes}`;
            }
            return td;
        });

        setTrainingDays(updatedTrainingDays);

    }
    
/* ===================================================
    RENDERING
=================================================== */
    const renderDay = (day, _selectedDates, pickersDayProps) => {
        const formattedDay = day.format("YYYY-MM-DD");
        const isSelected = selectedDates.includes(formattedDay);

        return (
            <PickersDay
                {...pickersDayProps}
                selected={isSelected} // Ensure MUI marks it as selected
                sx={{
                backgroundColor: isSelected ? "#1976d2 !important" : "transparent",
                color: isSelected ? "#fff" : "inherit",
                borderRadius: "50%",
                "&:hover": { backgroundColor: isSelected ? "#1565c0" : "#e0e0e0" },
                }}
          />
        );
    };
    const rendering = () => {

        return (
            <DateAndTimeSC className='content-container-item content-section' id='section3' ref={DateAndTimeRef}>
                <TitleComponent title={'Date and time'}/>
                <div className='content-container'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ alignSelf : 'flex-start', marginBottom : '10px' }}>
                        <DateCalendar 
                            onChange={handleDateChange} 
                            renderDay={renderDay} 
                            style={{ 
                                border : '1px solid lightgrey',
                                borderRadius : '5px',
                                marginRight : '10px',
                            }}/>
                        </div>
                        <div className='time-rows'>
                            {trainingDays.map((date) => {

                                const startHours = parseInt(date.start.substring(0, 2), 10);
                                const startMinutes = parseInt(date.start.substring(3, 5), 10);
                                const startDate = dayjs(date.day, 'DD/MM/YYYY').hour(startHours).minute(startMinutes);

                                const endHours = parseInt(date.end.substring(0, 2), 10);
                                const endMinutes = parseInt(date.end.substring(3, 5), 10);
                                const endDate = dayjs(date.day, 'DD/MM/YYYY').hour(endHours).minute(endMinutes);

                                return (
                                    <div className='time-container'>
                                        <div style={{ display : 'flex', flexDirection : 'column'}}>
                                            <p className='date-value'>{date.day}</p>
                                            <div className='time-pickers'>
                                                <div className='time-picker-item'>
                                                    <TimePicker
                                                        ampm={false}
                                                        label="Start"
                                                        value={startDate}
                                                        renderInput={(params) => <TextField {...params} />} // Required for MUI
                                                        size='small'
                                                        onChange={(value) => updateStartTime(value, date)}
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                              fontSize: '0.75rem',  // Reduce font size
                                                              height: '32px',       // Adjust height
                                                            },
                                                            '& .MuiInputBase-input': {
                                                              padding: '4px 8px',   // Reduce padding
                                                            },
                                                          }}
                                                    />
                                                </div>
                                                <div className='time-picker-item'>
                                                    <TimePicker
                                                        label="End"
                                                        value={endDate}
                                                        ampm={false}
                                                        onChange={(value) => updateEndTime(value, date)}
                                                        renderInput={(params) => <TextField {...params} size='small'/>} // Required for MUI
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                              fontSize: '0.75rem',  // Reduce font size
                                                              height: '32px',       // Adjust height
                                                            },
                                                            '& .MuiInputBase-input': {
                                                              padding: '4px 8px',   // Reduce padding
                                                            },
                                                          }}
                                                    />
                                                </div>                                            
                                            </div>
                                        </div>
                                        <DeleteIcon 
                                            variant='contained' 
                                            color='primary'
                                            onClick={() => removeDate(date)}
                                            style={{ marginLeft : '10px', marginTop : '10px' }}    
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </LocalizationProvider>

                </div>
            </DateAndTimeSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DateAndTimeSC = styled.div`
    display : flex; 
    flex-direction : column;
    flex-grow : 1;
    flex : 1;

    .content-container {
        display : flex; 
        justify-content : flex-start;
    }

    .time-rows {
        display : flex;
        flex-direction : column;
        margin-left : 10px;
        flex-grow : 1;
        flex : 1;

        .time-container {
            margin-bottom : 10px;
            padding : 15px;
            border : 1px solid lightgrey;
            border-radius : 5px;
            display : flex;
            justify-content : space-between;
            align-items : center;
            
            .time-pickers {
                display : flex;
                gap : 10px;
                margin-top : 10px;

                p {
                    font-size : 14px;
                }
            }
        }
    }

    .date-value {
        padding : 5px;      
        position : absolute; 
        top : -15px;
        background : white;
        font-size : 16px;  
    }

    .time-container {
        position : relative; 
        margin-top : 10px;
    }



`

export default DateAndTime