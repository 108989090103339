import React from 'react';
import styled from 'styled-components';
import { NewSessionModalContext } from '../../ScheduleNewSessionModal';

const TrainingTile = ({ training }) => {

/* ===================================================
    IMPORTS
=================================================== */
    const { setSelectedTraining } = React.useContext(NewSessionModalContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <TrainingTileSC onClick={() => setSelectedTraining(training)}>
                <img src={training.imageUrl} alt={training.name} />
                <h1>{training.name}</h1>
            </TrainingTileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingTileSC = styled.div`
    display : flex; 
    flex : 1;
    flex-direction : column;
    border-radius: 10px;
    border : 1px solid lightgrey;
    border-radius : 10px;
    cursor: pointer;
    overflow : hidden;

    &:hover {
        box-shadow : 0 0 10px rgba(0,0,0,0.1);
    }

    h1 {
        padding : 10px;
    }

    img {
        width : 100%;
        object-fit : cover;
    }
`

export default TrainingTile