import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField , Button, Stack, InputAdornment  } from '@mui/material';
import { Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Select from 'react-select';
import zIndex from '@mui/material/styles/zIndex';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DatesController from './DatesController';

const DiscountInformation = ({ 
    individualDiscountCode,
    register, 
    errors, 
    control, 
    handleChange, 
    startDate, 
    endDate, 
    handleStartDate,
    handleEndDate,
    discountType,
    setDiscountType,
    valueType, 
    setValueType,
    setValue,
    numberOfUsersForDiscount,
    setNumberOfUsersForDiscount,
}) => {

    const valueOptions = ['Percentage', 'Amount'];
    const discountOptions = ['regular', 'multi-user'];
      
    const nthAttendeesUpdate = (event) => {
        setNumberOfUsersForDiscount(event.target.value); // Update state on every change
        // You can also perform other actions here based on the input value
    };

    const rendering = () => {

        return (
            <DiscountInformationSC>
                    <div className='discount-code' style={{ marginBottom : '10px'}}>
                        <TextField
                            autoComplete='off'
                            required
                            id="discountCode"
                            label="Discount code"
                            variant="outlined"
                            defaultValue='test'
                            {...register('discountCode',
                                { required : 'discount code is required' }
                            )}
                            error={!!errors.discountCode}
                            helperText={errors.discountCode?.message}
                            style={{ width: '100%', marginBottom: '15px' }}
                            sx={{ 
                                fontFamily : 'Outfit', 
                                fontSize : 'inherit', 
                                color : 'black',
                                '& .MuiInputBase-root': {
                                    height: '100%', // Ensures input area fills the div,
                                    alignItems: 'flex-start',
                                    fontFamily: '"Outfit", serif',
                                    fontWeight : '350',
                                    input: {
                                        fontFamily: '"Outfit", serif',
                                    }
                                },    
                            
                            }}
                            InputLabelProps={{ shrink: true }} 
                        />
                    </div>

                    <div className='discount-description' style={{ marginBottom : '10px'}}>
                        <TextField  
                            label='Description'
                            inputProps={{ spellCheck: false }} 
                            multiline
                            type='text' 
                            {...register('description', {
                                required : {
                                    value : true,
                                    message : 'description is requried'
                                },
                                minLength : {
                                    value : 5,
                                    message : 'This field must be at least 5 characters'
                                }
                            })}
                            sx={{
                                width: '100%',
                                marginBottom: '15px',
                                height : '120px',
                                '& .MuiInputBase-root': {
                                    height: '100%', // Ensures input area fills the div,
                                    alignItems: 'flex-start',
                                    fontFamily: '"Outfit", serif',
                                    input: {
                                        fontFamily: '"Outfit", serif',
                                    }
                                },
                            }}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            InputLabelProps={{ shrink: true }} 
                        />
                    </div>

                    <DatesController
                        control={control}
                        errors={errors}
                        individualDiscountCode={individualDiscountCode}
                    />

                    <div className='discount-type' style={{ marginBottom : '10px'}}>
                        <Controller
                            name="discountType"
                            control={control}
                            render={({ onChange, value, ref }) => (
                                <FormControl
                                    style={{ width : '100%', marginBottom : '15px'}}
                                >
                                <InputLabel 
                                    id="demo-simple-select-label"
                                    style={{ backgroundColor : 'white', fontWeight : '400', paddingLeft : '5px', paddingRight : '5px'}}

                                    >Discount type</InputLabel>
                                <Select
                                    options={discountOptions}
                                    value={discountType}
                                    // value={discountOptions.find((c) => c.value === value)}
                                    onChange={(e) => {
                                        console.log('e.target.value : ', e.target.value)
                                        setDiscountType(e.target.value)
                                        setValue('discountType', e.target.value)
                                    }}
                                    sx={{
                                        fontFamily: '"Outfit", serif',
                                    }}
                                >
                                    {discountOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))    
                                    }
                                </Select>
                                </FormControl>
                            )}
                            rules={{ required: true }}
                        />
                    </div>

                    {discountType === 'multi-user' &&
                        <div className='multi-user'>
                            <Controller
                                name="nthattendee"
                                control={control}
                                defaultValue="2"
                                minimum="2"
                                rules={{ required: "Number of attendees is required" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type='number'
                                        value={numberOfUsersForDiscount}
                                        label="Sign-ups required"
                                        required
                                        variant="outlined"
                                        error={!!errors.discount}
                                        helperText={errors.discount?.message}
                                        onChange={nthAttendeesUpdate}
                                        sx={{
                                            fontFamily: '"Outfit", serif',
                                            width : '100%',
                                            marginBottom : '15px'
                                        }}
                                    />
                                )}
                            />
                        </div>    
                    }
                    {discountType === 'multi-user' &&   
                        <div className='discount-example' style={{ marginBottom : '5px', marginTop : '5px'}}>
                            {   
                                numberOfUsersForDiscount > 1 ?
                                    <p className='discount-type-information'>{`Signing up with a group gives discount. When ${numberOfUsersForDiscount} attendees sign up. Attendee number ${numberOfUsersForDiscount} recieves the following discount:`}</p>
                                    :
                                    <p className='discount-type-information' style={{ color : 'red'}}>{`A minimum of 2 sign-ups is required to create a multi-user discount`}</p>
                            }
                        </div>
                    }
                    <div className='values' style={{ display : 'flex', marginBottom : '10px'}}>
                        <div className='value-type'>
                            <Controller
                                name="value-type"
                                control={control}
                                render={({ onChange, value, ref }) => (
                                    <FormControl style={{ marginRight : '15px', minWidth : '150px'}}>
                                        <InputLabel 
                                            id="demo-simple-select-label"
                                            style={{ backgroundColor : 'white', fontWeight : '400', paddingLeft : '5px', paddingRight : '5px'}}

                                        >Value type</InputLabel>
                                        <Select
                                            options={valueOptions}
                                            value={valueType}
                                            onChange={(val) => {
                                                setValueType(val.target.value)
                                            }}
                                            sx={{
                                                fontFamily: '"Outfit", serif',

                                            }}
                                        >
                                            {valueOptions.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))    
                                            }
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className='value-amount' style={{ flexGrow : 1}}>
                            <Controller
                                name="discountValue"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Discount is required" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Discount"
                                        required
                                        variant="outlined"
                                        error={!!errors.discount}
                                        helperText={errors.discount?.message}

                                        InputProps={
                                            valueType === 'Amount' ? {
                                                startAdornment: <InputAdornment position="start" style={{ marginRight : '10px'}}>€ </InputAdornment>,
                                            } : {
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }
                                        }
                                        sx={{
                                            fontFamily: '"Outfit", serif',
                                            width : '100%',
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
            </DiscountInformationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DiscountInformationSC = styled.div`
    width : 40%;
    min-width : 400px;
    padding : 25px;
    box-sizing : border-box;
    display : flex; 
    flex-direction : column;

    .dates-picker {
        display : flex;
        margin-bottom : 15px;

        &>div {
            min-width : 50%;
            max-width : 50%;
        }
    }

    .discount-type-information {
        font-size : 12px;
        margin-top : -10px;
        margin-bottom : 15px;
        color : #666;
        font-weight : 200;
        padding-left : 5px;

    }
`


export default DiscountInformation