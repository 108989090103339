import { Button, ButtonBase } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RegisteredConfirmation = ({ state , setState }) => {

    const rendering = () => {
        return (
            <Modal>
                <RegisteredConfirmationSC>
                    <div className='info'>
                        <div style={{ display : 'flex', alignItems : 'center', border : '1px solid lightgrey', padding : '15px', borderRadius : '5px', marginBottom : '15px'}}>
                            <CheckCircleIcon style={{ fontSize : '50px', color : '#00a61c', marginRight : '10px'}}/>
                            <h1>Registration complete!</h1>
                        </div>
                    <p>Thanks for signing up, you will receive a confirmation e-mail shortly.</p>
                    <p><span style={{ color : 'red' }}>Caution!</span> if you use outlook, please check your spam folder</p>
                    </div>
                    <div className='buttons' style={{ marginTop : '15px'}}>
                        <ButtonBase onClick={() => setState({...state, registeredConfirmation : false})}>
                            <Button variant='contained'>Close</Button>
                        </ButtonBase>
                    </div> 
                </RegisteredConfirmationSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegisteredConfirmationSC = styled.div`
    background-color : white;
    padding : 85px;
    display : flex;
    flex-direction : column;
    justify-content : center;
    border-radius : 5px;

    h1 {
        font-size : 24px;
    }
`

export default RegisteredConfirmation