import React from 'react';
import styled from 'styled-components';

const Campaigns = () => {

    const rendering = () => {
        return (
            <CampaignsSC>
               <p>Campaigns</p> 
            </CampaignsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CampaignsSC = styled.div`
    
`

export default Campaigns