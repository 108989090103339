import React , { useState } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import CustomDropDown from '../../GeneralComponents/CustomDropDown';
import TextField from '@mui/material/TextField';
import { trainingProperties } from '../../../variables/trainingProperties';
import DropdownWithTableModal from '../../GeneralComponents/InputFields/DropDownWithTableModal';
import moment from 'moment';
import { AddInstanceContext } from '../AddInstance';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import TextInputField from '../../GeneralComponents/InputFields/TextInputField';
import PeopleIcon from '@mui/icons-material/People';
import ForumIcon from '@mui/icons-material/Forum';
import DropdownSelect from '../../GeneralComponents/InputFields/DropDownSelect';
import { AppContext } from '../../../App';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const AddInstanceProperties = () => {

    const { appState } = React.useContext(AppContext);
    const { 
        setTrainer, 
        setLocation, 
        trainer, 
        location, 
        price, 
        setPrice,
        capacity,
        setCapacity,
        language,
        setLanguage,
        register,
        errors,
        locationType,
        setLocationType,
        adress, 
        setAdress,
        place, 
        setPlace
    } = React.useContext(AddInstanceContext);

    const [trainers, setTrainers] = React.useState([]);
    const [locations, setLocations] = React.useState([]);

    React.useEffect(() => {
        const getTrainers = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers`);
                setTrainers(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const getLocations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/locations`);
                setLocations(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getTrainers();
        getLocations();

    }, []);

    const AvatarComponent = (p) => {
        const { firstName, lastName, imageUrl } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={imageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{`${firstName} ${lastName}`}</p>
            </div>
        );
    }

    const LocationComponent = (p) => {
        const { title, thumbnailImageUrl } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={thumbnailImageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{title}</p>
            </div>
        );
    }

    const handleChange = (event) => {
        setLocationType(event.target.value);
    };
    
    const rendering = () => { return (

            <AddInstancePropertiesSC>
                <div className='properties-grid'>
                    {/* Trainer */}
                    <>
                        {trainers.length > 0 ?
                        <DropdownWithTableModal 
                            options={trainers} 
                            colDefs={[
                                { valueGetter : p => p.data, cellRenderer : AvatarComponent, headerName : 'Trainer', flex : 1 },
                                { field: "phone", headerName : 'Phone', flex  : 1 },
                            ]}
                            setValueFunction={setTrainer}
                            keyToUse={'firstName'}
                            valueFromParent={trainer}
                            icon={<PersonIcon />}
                            label='Trainer'
                        />
                        : <p className='no-values'>No trainers in software</p>
                    }
                    </>

                    {/* location type */}
                    <FormControl>
                        <InputLabel 
                            id="demo-simple-select-label"
                            sx={{
                                backgroundColor : 'white',
                                paddingRight : '5px',
                            }}
                         >
                            Location type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={locationType}
                            defaultValue={'our-location'}
                            label="Age"
                            onChange={handleChange}
                            sx={{ fontFamily : 'inherit', fontSize : 'inherit', color : 'black'}}
                        >
                            <MenuItem
                                value={'our-location'}
                                sx={{
                                    padding : '10px'
                                }}    
                            >On location</MenuItem>
                            <MenuItem 
                                value={'online'}
                                sx={{
                                    padding : '10px'
                                }}    
                            >Online</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Price */}
                    {/* <>
                        <TextInputField
                            typedValue={price}
                            setValueFunction={setPrice}
                            label='Price'
                            suggestedValue='Enter price...'
                            icon={<PointOfSaleIcon />}
                        />
                    </> */}

                    {/* Capacity */}
                    <>
                        <TextField  
                            label='Capacity'
                            type='number' 
                            {...register('capacity', {
                                required : {
                                    value : true,
                                    message : 'capacity is requried'
                                }
                            })}
                            error={!!errors.capacity}
                            helperText={errors.capacity?.message}
                        />
                    </>

                    {/* Location */}
                    <>
                        {locationType === 'our-location' && locations.length > 0 &&
                            <DropdownWithTableModal 
                                options={locations} 
                                colDefs={[
                                    { valueGetter : p => p.data, cellRenderer : LocationComponent, headerName : 'Location' },
                                    { field: "adress", headerName : 'Adress', flex  : 1 },
                                ]}
                                keyToUse={'title'}
                                setValueFunction={setLocation}
                                valueFromParent={location}
                                icon={<ApartmentIcon />}
                                label='Location'
                            />
                        }
                    </>

                    <>
                        {locationType === 'online' && 
                        <>
                            <TextField  
                                label='Link to meeting'
                                type='text' 
                                fullWidth
                                {...register('meetingLink')}
                            />  
                        </>


                        }
                    </>


                </div>
            </AddInstancePropertiesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddInstancePropertiesSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;

    .properties-grid {
        flex-grow : 1;
        display : grid; 
        grid-template-columns : repeat(3, 1fr);
        grid-auto-rows : min-content;
        padding : 15px;
        margin-top : 15px;
        grid-gap : 15px;
    }

.material-input {
    margin: 14px;
}

.input-item-box {
    display : flex;
    justify-content : center;
    align-items : center;

    .icon-placeholder {
        display : flex;
        justify-content : center;
        align-items : center;
        width : 50px;
        border : 1px solid #ccc;
        aspect-ratio : 1;
        height : 50px;
        justify-content : center;
        align-items : center;

        &>svg {
            color : grey;
        }

    }
}

.properties-grid .no-values {
    display : flex;
    padding : 10px;
    border-radius : 5px;
    text-align : center;
    justify-content : center;
    align-items : center;    
    border : 1px solid lightgrey;
}

`

export default AddInstanceProperties


