import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { colors } from '../../variables/Colors';
import AddTrainingSidebar from './AddTrainingSidebar';
import { Button } from '@mui/material';
import { TrainingsOverViewContext } from '../TrainingsOverview/TrainingsOverview';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import TrainingProperties from './TrainingProperties.js';
import TrainingMailSchedule from './TrainingMailSchedule';
import TrainingFeedBackForm from './TrainingFeedbackForm';
import TrainingCertificates from './TrainingCertificates';
import TrainingInfo from './TrainingInfo.js';
import ReviewTraining from './ReviewTraining.js';
import { defaultMailSchedule as defaultTouchPointsArray} from './defaultTouchPointsArray';
import OnCompleteAddTrainingMenu from './OnCompleteAddingTrainingMenu.js';
import { AppContext } from '../../App.js';

export const AddTrainingContext = React.createContext(null);

const AddTraining2 = () => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const trainingNameRef = React.useRef();
    const trainingDescriptionRef = React.useRef();

    const { state : overviewState, setState : setOverviewState } = React.useContext(TrainingsOverViewContext);
    const [imageURL, setImageURL] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [surveys, setSurveys] = React.useState([]);
    const [emailTemplates, setEmailTemplates] = React.useState([]);

    const [state, setState] = React.useState({
        currentComponentName : 'General info',
        selectedCertificateTemplate : null,
        certificateTemplates : [],
        selectedSurvey : null,
        touchPoints : [],
        createTrainingLoading : false,
        completeTrainingMenu : false,
        savedTrainingResponse : null,
    });

    const addTraining = () => {
        console.log('training : ', state);
        console.log('trainingNameRef : ', trainingNameRef.current.value);

        setState({...state, createTrainingLoading : true});
        
        const training = {
            name : trainingNameRef.current.value,
            description : trainingDescriptionRef.current.value,
            certificateTemplateId : state.selectedCertificateTemplate._id,
            surveyId : state.selectedSurvey._id,
            defaultTouchPointsArray : state.touchPointsFromChild,
            tenant
        };

        console.log('training : ', training);
        
        const formData = new FormData();
        formData.append('s3Image', file);
        formData.append('training', JSON.stringify(training));

        const saveTraining = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/trainings`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setState({
                    ...state, 
                    addTrainingToggled : false, 
                    createTrainingLoading : false,
                    completeTrainingMenu : true,
                    savedTrainingResponse : response.data                    
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTraining();

    }

    React.useEffect(() => {
        const getCertificateTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificate-template/get-all/${tenant}`);
                setState({...state, certificateTemplates : response.data});
            } catch (error) {
                console.log(error);
            }
        }

        getCertificateTemplates();

    },[]);

    React.useEffect(() => {
        const getSurveys = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys`);
                setSurveys(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getSurveys();

    },[]);

    React.useEffect(() => {
        const getEmailTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates/find-all/${tenant}`);
                setEmailTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getEmailTemplates();

    },[]);

    const rendering = () => {
        return (
            <AddTrainingContext.Provider value={{ 
                state, 
                setState,
                trainingNameRef
            }}>
                <Modal>
                    <AddTraining2SC>
                        { state.completeTrainingMenu && <OnCompleteAddTrainingMenu /> }
                        <div className='top-bar'>
                            <h1>Add training</h1>
                            <Button
                                variant='contained'
                                color='primary'
                                style={{margin : '25px'}}
                                onClick={() => setOverviewState({...overviewState, addTrainingToggled : false})}
                            >Close
                            </Button>
                        </div>
                        <div className='content'>
                            <AddTrainingSidebar />

                            <>
                                <TrainingInfo 
                                    state={state} 
                                    setState={setState}
                                    imageURL={imageURL}
                                    setImageURL={setImageURL}
                                    file={file}
                                    setFile={setFile}
                                    trainingNameRef={trainingNameRef}
                                    trainingDescriptionRef={trainingDescriptionRef}
                                    visible = {state.currentComponentName === 'General info' ? 'visible' : 'hidden'}
                                />
                            </>
                            <> 
                                <TrainingMailSchedule 
                                    visible = {state.currentComponentName === 'Mail schedule' ? 'visible' : 'hidden'}
                                    state={state}
                                    setState={setState}
                                    emailTemplates={emailTemplates}
                                    defaultTouchPoints={defaultTouchPointsArray}
                                    directUpdate={true}
                                />
                            </>
                            <>
                                <TrainingFeedBackForm 
                                    visible = {state.currentComponentName === 'Feedback form' ? 'visible' : 'hidden'}
                                    state={state}
                                    setState={setState}
                                    surveys={surveys}
                                    setSurveys={setSurveys}
                                />
                            </>
                            <>
                                <TrainingCertificates 
                                    state={state} 
                                    setState={setState}
                                    visible = {state.currentComponentName === 'Certificates' ? 'visible' : 'hidden'}
                                />
                            </>
                                <ReviewTraining 
                                    addTraining={addTraining}
                                    visible = {state.currentComponentName === 'Review' ? 'visible' : 'hidden'}
                                />

                        </div>

                    </AddTraining2SC>
                </Modal>
            </AddTrainingContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTraining2SC = styled.div`
    min-width : 500px;
    width : 90vw;
    min-height : 500px;
    height : 90vh;
    background-color : white;
    border-radius : 5px;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    position : relative; 
    overflow : hidden;
    box-shadow : 0px 0px 5px 0px ${colors.lightGrey};

    .top-bar {
        display : flex;
        align-items : center;
        justify-content : space-between;
        border-bottom : 1px solid ${colors.lightGrey};
        padding-left : 25px;

        h1 {
            box-sizing : border-box;
            margin : 0;
            font-size : 1rem;
            color : ${colors.darkGrey};
            font-weight : 400;
        }

    }

    .content {
        display : flex;
        flex-grow : 1;
    }

`

export default AddTraining2