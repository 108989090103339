import React from 'react';
import styled from 'styled-components';
import Modal from '../../../GeneralComponents/Modal';
import { Button, CircularProgress } from '@mui/material';
import Axios from 'axios'; 
import { backEndAdress } from '../../../../variables/back-endAdress';

const MailViewer = ({ mail, closeFunction, individualSession, attendee }) => {

    const [loadingHTML, setLoadingHTML] = React.useState(true);
    const [mailHTML, setMailHTML] = React.useState('');

    React.useEffect(() => {
        setLoadingHTML(true);

        const fetchMailHTML = async () => {
            try {
                const html = await Axios.post(`${backEndAdress}/api/mail-templates/get-html-file/${mail.mailTemplateId}` , { 
                    instance : individualSession , 
                    attendee : attendee,
                    nonInstanceHandleBars : mail.nonInstanceHandleBars ? mail.nonInstanceHandleBars : {}
                });
                setMailHTML(html.data);
                setLoadingHTML(false);
            } catch (err) {
                console.log('Error fetching mail HTML: ', err);
                setLoadingHTML(false);
            }
        }

        fetchMailHTML();


    }, [mail]);

    const rendering = () => {
        
        return (
            <Modal>


                <MailViewerSC>
                    <Header>
                        <Button variant='outlined' onClick={closeFunction}>Close</Button>
                    </Header>
                    {loadingHTML ? 
                        <div className='loading-container'>
                            <p>Loading mail...</p>
                            <CircularProgress />
                        </div>  
                        :

                        <>
                            <div className='spacer'></div>
                            <div 
                                className='test' 
                                dangerouslySetInnerHTML={{__html : mailHTML}}>
                            </div>
                            <div className='spacer'></div>
                        </>
                    }
                </MailViewerSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailViewerSC = styled.div`
    max-height: 90vh;
    min-height: 90vh;
    max-width: 90vw;
    min-width: 90vw;
    background-color: white;
    display: flex;
    flex-direction: column;

    .loading-container {
        flex : 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 15px;
        }
    }

    .spacer {
        min-height: 45px;
        background-color: #F7F8F9;
    }

    .test {
        flex-grow : 1;
        overflow-y: scroll;

    }
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    background-color: white;
`;

const CloseButton = styled(Button)`
    margin-left: auto;
`;

export default MailViewer;