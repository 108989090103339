import React from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import moment from 'moment'; 
import { useNavigate } from 'react-router-dom';

const CourseList = ({ sessions, query }) => {

    const [filteredSessions, setFilteredSessions] = React.useState([]);
    const navigate = useNavigate();

    const highlightMatch = (text, query) => {
        if (!query) return text;
        const regex = new RegExp(`(${query})`, "gi");
        return text.replace(regex, `<span style="background-color: yellow;">$1</span>`);
};    

    const createCellRenderer = (field) => (params) => {
        const value = params.value;
        if (!value) return "";
        return <span dangerouslySetInnerHTML={{ __html: highlightMatch(value, query) }} />;
    };

    const navigateToSession = (data) => {
        const { _id, trainingId } = data;
        navigate(`/trainings/${trainingId}/sessions/${_id}`);
    }

    const TrainingTypeComponent = (p) => {
        const { trainingType, trainingName } = p.data;

        return (
            <div
                style={{
                    display: 'inline-block', // Prevents full width stretch
                    backgroundColor: '#f0f0f0',
                }}
            >
                <p className='test'>{trainingType === 'custom' ? 'Custom' : 'Public'}</p>
          </div>
        )

        // if(trainingType === 'custom') {
        //     return (
        //         <p className='type-container'>Custom</p>
        //     );
        // } else if(trainingType === 'public') {
        //     return (
        //         <p className='type-container'>Public</p>
        //     );
        // }

    }

    const LocationComponent = (p) => {
        const { locationType, location} = p.data;

        if(locationType === 'our-location') {
            return (
                <p>{location.title}</p>
            );
        } else if(locationType === 'client-location') {
            return (
                <p>Customer</p>
            );
        } else if(locationType === 'online') {
            return (
                <p>Online</p>
            );
        }
    }

    React.useEffect(() => {
        if(query === '') {
            const sortedByDate = sessions.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            setFilteredSessions(sortedByDate);
        } else {
            const filtered = sessions.filter((session) => {
                return session.trainingName.toLowerCase().includes(query.toLowerCase()) || 
                session.trainer.firstName.toLowerCase().includes(query.toLowerCase()) ||
                session.locationType === 'our-location' && session.location.title.toLowerCase().includes(query.toLowerCase())

            })
            const sortedByDate = filtered.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            setFilteredSessions(sortedByDate);
        }
    }, [query, sessions]);

    const rendering = () => {
        return (
            <CourseListSC>
                <div className='ag-theme-material' style={{height : "800px", width : "100%", flex : 1, borderRadius: 0}} >
                    <AgGridReact
                        rowData={filteredSessions}
                        columnDefs={[
                            { headerName : "startDAte : ", field : "startDate", cellRenderer : (params) => moment(params.value).format('DD/MM/YYYY')},
                            { headerName: "Training Name", field: "trainingName", cellRenderer: createCellRenderer("trainingName"),  flex : 1 },
                            { headerName: "Trainer", field: "trainer.firstName", cellRenderer: createCellRenderer("trainer.firstName") },
                            { valueGetter : p => p.data, cellRenderer : LocationComponent, headerName : 'Location', flex : 1 },

                          ]}
                        headerHeight={0}
                        onRowClicked={(e) => navigateToSession(e.data)}
                    />
                </div>
            </CourseListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CourseListSC = styled.div`
    background-color : red;
    position : absolute;
    min-height : 75vh;
    max-height : 75vh;
    overflow : hidden;
    left : 0;
    right : 0;
    border : 1px solid #e0e0e0;
    z-index : 100;

    .ag-theme-material {
        --ag-border-radius : 10px;
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
        --ag-border-radius : 0;
        flex : 1;
    }

    .ag-cell-focus {
        outline: none; /* Remove the default outline */
    }

    .test {
        display : inline-block;
        background-color : red;
        padding : 5px;
        max-height : 35px;
        margin-bottom : -10px;
    }
`

export default CourseList