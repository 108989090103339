import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

root.render(
  <React.StrictMode>
    <Auth0Provider
        domain="dev-31ndxs2v.us.auth0.com"
        clientId={clientId}
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience : "https://holli-training.com/",
            scope: 'read:trainings'
        }}
    >
      <App />
    </Auth0Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
