import React from 'react';
import styled from 'styled-components';
import CertificateItem from './CertificateItem';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios'; 
import CertificatesHeaderMenu from './CertificatesHeaderMenu';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import moment from 'moment';

const CertificatesList = () => {

    const [state, setState] = React.useState({
        certificates : []
    });

    React.useEffect(() => { 
        const getCertificates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificates`)
                console.log('repooo', response.data)
                setState({
                    ...state,
                    certificates : response.data
                })
            } catch (error) {
                console.log(error)
            } 
        }

        getCertificates()
    },[]);

    const openNewTab = (certId) => {
        window.open(`https://holli-certificate-hoster.onrender.com/certificate/${certId}`, '_blank');
    };

    const rendering = () => {

        return (
            <CertificatesListSC>
                <CertificatesHeaderMenu />

                <div className='ag-theme-material' style={{height : "100%", width : "100%", marginLeft : '15px'}} >
                    <AgGridReact
                        rowData={state.certificates} 
                        rowHeight={50}
                        columnDefs={[
                            { field: "certificateDate", headerName : 'Awarded date', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY'), flex : 3},
                            { field: "certificateName", headerName: "Name", flex : 5 },
                            { field: "user.userEmail", headerName: "Email", flex : 5 },
                        ]}
                        onRowClicked={(row) => openNewTab(row.data._id)}
                    />
                </div>


                    {/* <div className='page-with-licenses'>
                        {state.certificates.length === 0 ?
                            <h1>No certificates found</h1>
                                :
                                <>
                                    <h1>Awarded certificates</h1>
                                    {state.certificates.map((certificate) => {
                                        return (
                                            <CertificateItem key={certificate._id} certificate={certificate} />
                                        );
                                    })}
                                </>
                        }
                    </div> */}
            </CertificatesListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificatesListSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    
    .none-found {
        padding : 15px;
        font-size : 0.8rem;
        font-weight : 400;
    }

    .page-with-licenses {
        display : flex;
        flex-direction : column;
        background-color : white;
        margin : 10px;
        border-radius : 5px;
        flex-grow : 1;
    }

    h1 {
        padding : 15px;
        margin : 0;
        font-size : 1.2rem;
        font-weight : 400;
    }


    .ag-theme-material {
        --ag-border-radius : 10px;
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
    }

    .ag-cell-focus {
        outline: none; /* Remove the default outline */
    }
`

export default CertificatesList