import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Certificate from './Certificate/Certificate';
import Survey from './Survey/Survey';
import MailSchedule from './MailSchedule/MailSchedule';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import TrainingOverview from './TrainingOverview/TrainingOverview';
import EditTraining from './EditMode/EditTraining';
import Actions from './Actions/Actions';
import SendOutSurveyModal from '../IndividualTraining/SendOutSurveyModal/SendOutSurveyModal';
import SubNavigation from '../GeneralComponents/navigation/SubNavigation';
import { navigationItems } from './navigationItems';
import Overview from './Overview/Overview';
import { Route, HashRouter as Router, Routes, useLocation } from 'react-router-dom';
import IndividualSession from '../IndividualSession/IndividualSession';

export const IndividualTrainingContext = React.createContext();

const IndividualTraining = () => {

    const location = useLocation();

    const trainingId = window.location.hash.split('/')[2];
    const instanceId = window.location.hash.split('/')[4];

    const [individualTraining, setIndividualTraining] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const [addInstanceToggled, setAddInstanceToggled] = React.useState(false);
    const [sessionsForTraining, setSessionsForTraining] = React.useState([]);
    const [individualSession, setIndividualSession] = React.useState(null);
    const [reRender, setReRender] = React.useState(0);
    const [lastOpenedSessionId, setLastOpenedSessionId] = React.useState(null);

    const [state, setState] = React.useState({
        trainingToEdit : null,
    });


    const [loadingTraining, setLoadingTraining] = React.useState(true);

    // Change the individual session when the url changes
    React.useEffect(() => {

        if(instanceId === 'undefined' || instanceId === 'no-session' || !instanceId) {
            setIndividualSession(null);
        }
        else {
            const getSession = async () => {
                try {
                    const response = await Axios.get(`${backEndAdress}/api/instances/${instanceId}`);
                    console.log('repsonse : ', response.data);
                    setIndividualSession(response.data);
                    setLastOpenedSessionId(response.data._id);
                }
                catch (error) {
                    console.log(error);
                }
            }

            getSession();
        }

    }, [location, sessionsForTraining]);

    // Get the sessions and the training ID 
    React.useEffect(() => {

        const getSessions = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/training/${trainingId}`);
                setSessionsForTraining(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }

        const getTraining = async () => {
            setLoadingTraining(true);
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/${trainingId}`);
                setLoadingTraining(false);
                setIndividualTraining(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }

        const runFunction = async () => {
            const training = trainingId !== 'custom' && await getTraining(); 
            const sessions = trainingId !== 'custom' && await getSessions();

            if(trainingId === 'custom') {
                setLoadingTraining(false);
            }
        }

        console.log('ok');


        runFunction(); 

        
    }, [reRender]);

    const rendering = () => {
        if(loadingTraining) {
            return <div>loading...</div>
            } else {
                return (
                    <IndividualTrainingContext.Provider 
                        value={{
                            setIndividualTraining,
                            setEditMode,
                            state,
                            setState,
                            addInstanceToggled,
                            setAddInstanceToggled, 
                            sessionsForTraining,
                            setIndividualSession,
                            individualSession,
                            setReRender
                        }}>
        
                        {!state.trainingToEdit ? 
                            <IndividualTrainingSC>
                                <SubNavigation
                                    navigationItems={navigationItems}
                                    individualSession={individualSession}
                                    lastOpenedSessionId={lastOpenedSessionId}
        
                                />
                                <Routes>
                                    <Route path='/sessions/:sessionId' element={
                                        <IndividualSession 
                                            individualSession={individualSession} 
                                            individualTraining={individualTraining}
                                            setSessionsForTraining={setSessionsForTraining}
                                        />} 
                                    />
                                    <Route path='/overview' element={
                                        <Overview 
                                            individualTraining={individualTraining}
                                            setReRender={setReRender}
                                        />} 
                                    />
                                </Routes>
                            </IndividualTrainingSC>
                        : 
                            <EditTrainingSC>
                                <EditTraining 
                                    state={state} 
                                    setState={setState}
                                    editExistingTraining={true}
                                    setReRender={setReRender}
                                />
                            </EditTrainingSC>
                        }
                    </IndividualTrainingContext.Provider>
                );
            }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainingSC = styled.div`
    display : flex; 
    flex-direction : column;
    flex : 1;
    overflow : hidden;
    position : relative; 


`

const EditTrainingSC = styled.div`
    overflow-y:  hidden;
    flex : 1;
    display : flex;

`

export default IndividualTraining