import React from 'react';
import styled from 'styled-components';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { IndividualTrainingContext } from '../IndividiualTraining';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const UpcomingTrainings = ({ individualTraining }) => {

    const navigate = useNavigate();

    const [upcomingTrainings, setUpcomingTrainings] = React.useState([]);
    const [loading, setLoading] = React.useState(true); 

    React.useEffect(() => {
        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/`)
                const filteredInstances = response.data.filter(instance => new Date(instance.startDate) > new Date() && instance.trainingId === individualTraining._id);
                setUpcomingTrainings(filteredInstances);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        }

        getInstances();

    }, []);


    const getStatusClass = (capacity) => {
        if (capacity < 0.5) {
            return 'status-red';
        } else if (capacity >= 0.5 && capacity < 0.8) {
            return 'status-amber';
        } else {
            return 'status-green';
        }
    }


    const rendering = () => {
        return (
            <UICard styleTags={{ margin : '20px', marginLeft : 0, flex : 1 }}>
                <UpcomingTrainingsSC>
                    <div className='title-component'>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Upcoming trainings</h1>
                    </div>
                    <div style={{ flex : 1, alignItems : 'center'}}>
                        {loading && <CircularProgress />}
                    </div>

                    {upcomingTrainings.map(instance => {

                        const capacity = instance.attendees.length / instance.capacity;
                        const statusClass = getStatusClass(capacity);

                        return (
                            <div
                                className='trainer-row'
                                onClick={() => navigate(`/trainings/${instance.trainingId}/sessions/${instance._id}`)}
                            >
                                <div className='info'>
                                    <h1>{instance.locationType === 'our-location' ? instance.location.title : 'Customer location'}</h1>
                                    <h2>{moment(instance.startDate).format('ddd DD MM YYYY')}</h2>
                                </div>
                                <div className='images' style={{ display : 'flex', alignItems : 'center'}}>
                                    <div className={`attendees ${statusClass}`}>
                                        {`${instance.attendees.length}/${instance.capacity}`}
                                    </div>

                                    <img src={instance.trainer.imageUrl}/>
                                </div>
                            </div>
                        );
                    })}
                    
                </UpcomingTrainingsSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UpcomingTrainingsSC = styled.div`
    display : flex;
    flex-direction : column;

    .trainer-row {
        display : flex; 
        align-items : center;
        border-bottom : 1px solid #e0e0e0;
        padding : 10px;
        justify-content : space-between;
        padding-left : 30px;

        &:hover {
            background-color : #f0f0f0;
            cursor: pointer;    
        }

        img {
            width : 30px;
            height : 30px;
            border-radius : 50%;
            margin-right : 10px;
        }

    }

    .attendees {
        padding : 6px;
        font-size : 0.8em;
        margin-right : 10px;
        width : 25px;
        border-radius : 5px;
        text-align : center;
    }
`

export default UpcomingTrainings