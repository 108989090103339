import React from 'react';
import Header from './header/Header';
import MainContent from './mainContent/MainContent';
// import Footer from './footer/Footer';
import Modal from '../GeneralComponents/Modal';
import styled from 'styled-components';
import WebIcon from '@mui/icons-material/Web';
import { set, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import TextFormInput from '../GeneralComponents/formInputs/TextFormInput';
import { AppContext } from '../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress'; 
import { defaultMailSchedule } from '../addTraining/defaultTouchPointsArray';
import OnCompleteAddOrEditTraining from './OnCompleteAddOrEditTraining'
import Footer from '../addOrEditTrainingComponent/footer/Footer';
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Sidebar from './sidebar/Sidebar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';
import { IndividualTrainingContext } from '../IndividualTraining/IndividualTraining';
import { useNavigate } from 'react-router-dom';
import ActionInProgress from '../GeneralComponents/ActionInProgress';

export const AddOrEditTrainingContext = React.createContext(null);

const AddOrEditTraining = ({ 
    trainingToEdit, 
    closeWindowFunction, 
    openSavedTraining, 
    setTraining, 
    setMailJobs,
    state: editTrainingState,
    setState : setEditTrainingState,
    editExistingTraining,
    setReRender
}) => {

    const { user } = useAuth0();

    const form = useForm();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, trigger, control, formState, setValue  } = form;
    const { errors, isValidating } = formState;
    const formValues = watch(); 

    const [activeItem, setActiveItem] = React.useState(0);
    const [surveys, setSurveys] = React.useState([]);
    const [certificateTemplates, setCertificateTemplates] = React.useState([]);
    const [mailTemplates, setMailTemplates] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [imageURL, setImageURL] = React.useState(null);
    const [file, setFile] = React.useState(null);

    const [savingTraining, setSavingTraining] = React.useState(false);
    const [trainingSaved, setTrainingSaved] = React.useState(false);
    const [savingError, setSavingError] = React.useState(null);

    // const [touchPointsForTraining, setTouchPointsForTraining] = React.useState([defaultMailSchedule.defaultTouchPointsArray]);
    // const [touchPointsForTrainingCustomerLocation, setTouchPointsForTrainingCustomerLocation] = React.useState([defaultMailSchedule.customerLocationTouchPointsArray]);


    const formRef = React.useRef(null);

    const [state, setState] = React.useState({
        selectedCertificateTemplate : null,
        certificateTemplates : [],
        selectedSurvey : null,
        touchPoints : [],
        completeTrainingMenu : false,
        savedTrainingResponse : null,
        touchPointsFromChild: [],
        trainingCreated : '',
        availableLocations : [],
    }); 

    const content = trainingToEdit ? trainingToEdit.htmlForWebsite : '<p>Hello! This is a basic editor with Tiptap.</p>';

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle
        ],
        content: content,
    })

    const [trainingToEditLocal, setTrainingToEditLocal] = React.useState(null);
    const [activeDiv, setActiveDiv] = React.useState(null);

    React.useEffect(() => {
        setTrainingToEditLocal(trainingToEdit);
        setImageURL(trainingToEdit?.imageUrl);

    },[trainingToEdit]);

    const generalInfoRef = React.useRef(null);
    const propertiesRef = React.useRef(null);
    const websiteRef = React.useRef(null);
    const mailScheduleRef = React.useRef(null);
    const feedbackRef = React.useRef(null);
    const certificateRef = React.useRef(null);
    const reviewRef = React.useRef(null);

    const scrollToDiv = (refToUse) => {
        console.log('scrolling to : ', refToUse);
        if (refToUse.current) {
            refToUse.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const navigationItems = [{
        title: 'General info',
        icon : <WebIcon />,
        refToUse : generalInfoRef
    },{
        title: 'Properties',
        icon : <WebIcon />,
        refToUse : propertiesRef
    },{
        title: 'Website',
        icon : <WebIcon />,
        refToUse : websiteRef
    },{
        title : 'Mailschedule',
        icon : <WebIcon />,
        refToUse : mailScheduleRef
    },{
        title : 'Feedback',
        icon : <WebIcon />,
        refToUse : feedbackRef
    },{
        title : 'Certificate',
        icon : <WebIcon />,
        refToUse : certificateRef
    },{
        title : 'Review',
        icon : <WebIcon />, 
        refToUse : reviewRef
    }];

    // Fetching data on component render 
    React.useLayoutEffect(() => {

        const getEmailTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates`);
                setMailTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        
        const getCertificateTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificate-template/`);
                setCertificateTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getSurveys = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys`);
                setSurveys(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getLocations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/locations`);
                setLocations(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getCertificateTemplates();
        getEmailTemplates();
        getSurveys();
        getLocations();

    },[]);

    React.useLayoutEffect(() => {
        if(trainingToEdit) {

            const { surveyId, certificateTemplateId } = trainingToEdit;
            const selectedSurvey = surveys.find(survey => survey._id === surveyId);
            const selectedCertificateTemplate = certificateTemplates.find(certificate => certificate._id === certificateTemplateId);

            setState({
                ...state,
                touchPointsFromChild : trainingToEdit.defaultTouchPointsArray,
                selectedSurvey,
                selectedCertificateTemplate
            });
        }
    },[trainingToEdit, surveys]);

    // Save or edit training
    const onSubmit = (data) => {

        setSavingTraining(true);
        setState({...state, trainingCreated : data.title});
        
        const html = editor.getHTML()

        const training = {
            ...trainingToEditLocal,
            organizationId : user.org_id,
            name : data.title,
            description : data.description,
            certificateTemplateId : state.selectedCertificateTemplate?._id,
            surveyId : state.selectedSurvey?._id,
            defaultTouchPointsArray : trainingToEditLocal.defaultTouchPointsArray,
            hasThumbnailUrl : !!imageURL,
            htmlForWebsite : html,
            pricePerDay : data.price,
            numberOfDays : data.numberOfDays,
            publishedOnWebsite : true,
            requirements : data.requirements,
        };

        console.log('training', training);
   
        const formData = new FormData();
        formData.append('s3Image', file);
        formData.append('training', JSON.stringify(training));

        const saveTraining = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/trainings`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                if(editExistingTraining) { 
                    setEditTrainingState({
                        ...editTrainingState, 
                        preOpenTraing : response.data,
                        trainingToEdit : null,
                        numForFetching : Math.random()
                    });

                    setTrainingSaved(response.data._id);
                    setReRender(Math.random());


                } else {
                    setTrainingSaved(response.data._id); 
                    navigate(`/trainings/${response.data._id}/overview`);

                }
            } catch (error) {
                console.log('error', error);
            }
        }

        console.log('training', training);  

        saveTraining();
        

    }

    const sections = document.querySelectorAll('.content-section');
    const navItems = document.querySelectorAll('.nav-item');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {

          // Check if section is intersecting (in view)
          if (entry.isIntersecting) {
            
            // Remove active class from all links
            navItems.forEach(item => item.classList.remove('active'));
      
            // Get corresponding link and add active class
            const activeLink = document.querySelector(`.nav-item[href="#${entry.target.id}"]`);
            activeLink.classList.add('active');
          }
        });

    }, { threshold: 0.6 }); // Adjust threshold as needed
      
    // Observe each section
    sections.forEach(section => observer.observe(section));


    return ( 
        

        trainingToEditLocal &&
        <AddOrEditTrainingSC>
            <AddOrEditTrainingContext.Provider value={{ 
                navigationItems,
                activeItem,
                setActiveItem
            }}>
               
                {/* { state.completeTrainingMenu && 
                    <OnCompleteAddOrEditTraining 
                        trainingCreated={state.trainingCreated}
                        savedTrainingResponse={state.savedTrainingResponse}    
                        state={state}
                        setState={setState}
                        openSavedTraining={openSavedTraining}
                    /> 
                } */}
                <>

                    { savingTraining && 
                        <ActionInProgress 
                            text='Saving training'
                            successText='Training created successfully'
                            errorText='Error creating training'
                            completed={trainingSaved}
                            error={savingError}
                            successOption1={{
                                text : 'Open training',
                                onClick : () => {
                                    console.log('hey');
                                }
                            }}
                            successOption2={{
                                text : 'Create another',
                                onClick : () => {
                                    console.log('hey');
                                }
                            }}
                            errorOption1={{
                                text : 'Close', 
                                onClick : () => {
                                    setSavingError(false);
                                    setSavingTraining(false);
                                    setTrainingSaved(false);
                                }
                            }}
                        />
                    }


                    <Sidebar 
                        scrollToDiv={scrollToDiv}
                        closeWindowFunction={closeWindowFunction}
                    />

                    <div className='display-column-container'>
                        <form 
                            onSubmit={handleSubmit(onSubmit)} 
                            noValidate
                            ref={formRef} 
                        >
                            <MainContent 
                                register={register}
                                setValue={setValue}
                                errors={errors}   
                                surveys={surveys}
                                mailTemplates={mailTemplates}
                                state={state}
                                setState={setState} 
                                defaultTouchPoints={defaultMailSchedule.defaultTouchPointsArray}
                                formValues={formValues}
                                trigger={trigger}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                file={file}
                                setFile={setFile}
                                activeItem={activeItem}
                                trainingToEdit={trainingToEdit}
                                trainingToEditLocal={trainingToEditLocal}
                                setTrainingToEditLocal={setTrainingToEditLocal}
                                certificateTemplates={certificateTemplates}
                                locations={locations}
                                editor={editor}
                                generalInfoRef={generalInfoRef}
                                propertiesRef={propertiesRef}
                                websiteRef={websiteRef}
                                mailScheduleRef={mailScheduleRef}
                                feedbackRef={feedbackRef}
                                certificateRef={certificateRef}
                                reviewRef={reviewRef}
                            />
                        </form>
                        <Footer 
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                navigationItems={navigationItems}
                                trigger={trigger}
                                formRef={formRef}
                            />
                    </div>
                </>
            </AddOrEditTrainingContext.Provider>
        </AddOrEditTrainingSC>
    );
};

const AddOrEditTrainingSC = styled.div`
    flex : 1;
    display : flex;
    position : relative;
    margin-top : 10px;
    overflow: hidden;
    
    .close-button {
        position : absolute;
        top : 2px;
        right : 2px;
    }

    .display-column-container {
        display : flex;
        flex-grow : 1;
        flex-direction : column;
        overflow : scroll;
    }

    &>form {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
    }
`

export default AddOrEditTraining;