import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button, ButtonBase } from '@mui/material';
import { IndividualSessionContext } from '../IndividualSession';
import AddUserForm from './AddUserForm';
import { useForm } from 'react-hook-form';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios'; 
import ActionInProgress from '../../GeneralComponents/ActionInProgress';

const AddUsersManually = ({ individualSession }) => {

/* ===================================================
    IMPORTS
=================================================== */
    const { setAdddusersManuallyModal } = React.useContext(IndividualSessionContext);

    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors, isValidating } = formState;

    const formRef = React.useRef(null);

/* ===================================================
    STATE
=================================================== */
    const [addAttendeeLoading, setAddAttendeeLoading] = React.useState(false);
    const [addAttendeeError, setAddAttendeeError] = React.useState(null);
    const [addAttendeeSuccess, setAddAttendeeSuccess] = React.useState(null);
/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */
    const submitIt = (data) => {

        setAddAttendeeLoading(true);

        const attendee = {
            firstName : data.firstName,
            lastName : data.lastName,
            email : data.email,
            company : data.company,
        }
       

        const addAttendee = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/add-user-no-email/${individualSession._id}`, { attendee })
                setAddAttendeeSuccess(response.data);
            } catch (error) {
                console.log('erra : ', error);
                setAddAttendeeError(error);
            }
        } 

        addAttendee();

    }
/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <Modal>
                {addAttendeeLoading && <ActionInProgress
                    text='Adding user..'
                    successText='User added'
                    errorText='Error adding user'
                    completed={addAttendeeSuccess}
                    error={addAttendeeError}
                    successOption1={{
                        text : 'Close',
                        onClick : () => setAdddusersManuallyModal(false)
                    }}
                    successOption2={{
                        text : 'Add another user',
                        onClick : () => {
                            setAddAttendeeLoading(false);
                            formRef.current.reset();
                            setAddAttendeeSuccess(null);
                        }
                    }}
                    errorOption1={{
                        text : 'Close',
                        onClick : () => setAdddusersManuallyModal(false)
                    }}
                />}

                <AddUsersManuallySC>
                    <div className='title'>
                        <div style={{ display : 'flex', flexDirection : 'column'}}>
                            <h1>Add users</h1>
                            <h2>They will be added to the training but receive no e-mail communication</h2>
                        </div>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => setAdddusersManuallyModal(false)}
                        >
                            Close
                        </Button>
                    </div>
                    <form noValidate onSubmit={handleSubmit(submitIt)} ref={formRef}>                        
                        <AddUserForm 
                            register={register}
                            handleSubmit={handleSubmit}
                            watch={watch}
                            trigger={trigger}
                            control={control}
                            errors={errors}
                            isValidating={isValidating}
                        />
                        <div className='submit-row'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                Add user
                            </Button>
                        </div>
                    </form>
                </AddUsersManuallySC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddUsersManuallySC = styled.div`
    min-width : 750px;
    min-height : 200px;
    display : flex; 
    background-color : white;
    border-radius : 10px;
    flex-direction : column;

    .title {
        display : flex; 
        justify-content : space-between;
        border-bottom : 1px solid lightgrey;
        padding : 25px;
    }

    .submit-row {
        display : flex; 
        justify-content : flex-end;
        padding : 20px;
    }

`

export default AddUsersManually