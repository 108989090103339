import React from 'react';
import styled from 'styled-components';
import { SurveyResponsesContext } from '../../SurveyResponses';
import Search from '../../../GeneralComponents/Search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const FilterItem2 = ({ title , filter, associativeValues }) => {


    const [query, setQuery] = React.useState('');
    const { filters , setFilters } = React.useContext(SurveyResponsesContext);


    const sortedFilter = filter.sort((a, b) => {
        const aAssociated = associativeValues ? associativeValues.map(i => i.toLowerCase()).includes(a.toLowerCase()) : false;
        const bAssociated = associativeValues ? associativeValues.map(i => i.toLowerCase()).includes(b.toLowerCase()) : false;
        return bAssociated - aAssociated;
    });

    const matchedWithQuery = sortedFilter.filter((item) => item.toLowerCase().includes(query.toLowerCase()));

    const handleFilter = (option) => {  
        const filter = filters[title.toLowerCase()] ? filters[title.toLowerCase()] : [];
        const newFilter = filter.includes(option) ? filter.filter((item) => item !== option) : [...filter, option];
        console.log(newFilter);
        setFilters({
            ...filters,
            [title.toLowerCase()] : newFilter
        });
    }

    const rendering = () => {
        return (
            <FilterItem2SC>
                <div className='top-bar'>
                    <div className='filter-title'>
                        <p className='filter-item-title'>{title} {filters[title.toLowerCase()].length > 0 && `(${filters[title.toLowerCase()].length})` }</p>
                        {filters[title.toLowerCase()].length > 0 && <button onClick={() => setFilters({...filters, [title.toLowerCase()] : []})}>Clear</button>}
                    </div>
                    <Search 
                        placeholder='Search'
                        query={query}
                        setQuery={setQuery}
                        style={{ maxHeight : '20px', marginBottom : '10px', backgroundColor : 'white'}}
                    />
                </div>
                <div className='filter-values'>
                    {matchedWithQuery.map((option) => {

                        const isAssociated = true;
                        // const isAssociated = associativeValues ? associativeValues.map(i => i.toLowerCase()).includes(option.toLowerCase()) : false;
                        const isChecked = filters[title.toLowerCase()] ? filters[title.toLowerCase()].includes(option) : false; 

                        return (
                            <div 
                                className={`filter-value ${isAssociated ? 'associated' : ''}`}
                                style={{ 
                                    backgroundColor : !isAssociated ? 'white' : 'inherit',
                                    color : !isAssociated ? 'grey' : 'inherit'
                                }}
                                onClick={() => isAssociated && handleFilter(option)}
                            >
                                {!isChecked ? 
                                    <CheckBoxOutlineBlankIcon
                                        key={option}
                                        value={option}
                                        style={{
                                            color : isAssociated ? '#009DFF' : 'grey'
                                        }}
                                    />
                                    :
                                    <CheckBoxIcon
                                        key={option}
                                        value={option}
                                    />
                                }
                                <p className='value-name'>{option}</p>
                            </div>
                        )
                    })}
                </div>
            </FilterItem2SC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FilterItem2SC = styled.div`
    display : flex; 
    flex-direction : column;
    border : 1px solid gainsboro;
    border-radius : 5px;
    margin-bottom : 10px;
    padding : 10px;

    .top-bar {
        display : flex;
        flex-direction : column;
    }

    .filter-item-title {
        font-size : 16px;
        padding : 10px;
    }


    .filter-title {
        display : flex;
        justify-content : space-between;
        align-items : center;
        margin-bottom : 5px;
    }   

    .filter-values {
        margin-bottom : 25px;
    }

    .associated {
        &:hover {
            cursor: pointer;
        }
    }

    .filter-value {
        display : flex; 
        align-items : center;
        padding : 10px;
        border-radius : 5px;




        
        p {
            font-weight : 300;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            max-width : 200px;
        }
    
        svg {
            max-width : 20px;
            max-height : 20px;
            color : #009DFF;
            margin-right : 10px;
        }
    }


    
`

export default FilterItem2