import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

const Requirement = ({ trainingToEditLocal, register, errors }) => {

    const rendering = () => {
        return (
            <RequirementsSC>
                <TextField  
                    defaultValue={trainingToEditLocal ? trainingToEditLocal.requirements : 'Bring your own laptop'}
                    label='Requirements'
                    multiline
                    spellCheck='false'
                    placeholder='Bring your own laptop with software x installed'

                    {...register('requirements', {
                        minLength : {
                            value : 5,
                            message : 'This field must be at least 5 characters'
                        }
                    })}
                    sx={{
                        width: '100%',
                        height: '100%', // Ensure the text field takes the full height of the parent div
                        '& .MuiInputBase-root': {
                        height: '100%', // Ensures input area fills the div,
                        alignItems: 'flex-start',
                        },
                    }}
                    error={!!errors.requirements}
                    helperText={errors.requirements?.message}
                />
            </RequirementsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RequirementsSC = styled.div`
    margin-top : 20px;
    flex-grow : 1;
`

export default Requirement