import React from 'react';
import styled from 'styled-components';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

const TitleComponent = ({ title }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <TitleComponentSC>
                <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                <h1 className='card-title'>{title}</h1>
            </TitleComponentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TitleComponentSC = styled.div`
    display : flex; 
    &>svg {
        margin-right : 5px;
    }
    padding-bottom : 20px;
`

export default TitleComponent