import React from 'react';
import styled from 'styled-components';
import Difficulty from './Difficulty';
import AvailableLanguages from './AvailableLanguages';
import AvailableLocations from './AvailableLocations';
import OnDemand from './OnDemand'; 
import Webinar from './Webinar';
import FreeTraining from './FreeTraining';
import PricePerDay from './PricePerDay';
import TextBlock from './TextBlock';
import { v4 as uuidv4 } from 'uuid';
import Category from './Category';
import Requirements from './Requirements';

const TrainingProperties = ({ 
    visible, 
    locations, 
    state, 
    setState, 
    register, 
    errors, 
    trainingToEditLocal, 
    setTrainingToEditLocal, 
    editor, 
    propertiesRef,
    setValue
}) => {

    const rendering = () => {
        return (
            <TrainingPropertiesSC ref={propertiesRef} className='content-section' id='section2'>
                <h1 className='content-section-title'>Properties</h1>
                <div className='content-training-properties'>                
                    <div className='form-group'>
                        <div className='form-group-row-item'>
                            <div className='left-panel input-dropdown-container'>
                                <Difficulty
                                    setTrainingToEditLocal={setTrainingToEditLocal}
                                    trainingToEditLocal={trainingToEditLocal}
                                />
                                <AvailableLanguages 
                                    setTrainingToEditLocal={setTrainingToEditLocal}
                                    trainingToEditLocal={trainingToEditLocal}
                                />
                                <AvailableLocations 
                                    locations={locations} 
                                    state={state} 
                                    setState={setState}
                                    setTrainingToEditLocal={setTrainingToEditLocal}
                                    trainingToEditLocal={trainingToEditLocal}
                                />
                                <Category 
                                    setTrainingToEditLocal={setTrainingToEditLocal}
                                    trainingToEditLocal={trainingToEditLocal}
                                />
                            </div>
                            <div className='right-panel'>
                                <div className='selectable-properties'>
                                    <OnDemand 
                                        setTrainingToEditLocal={setTrainingToEditLocal}
                                        trainingToEditLocal={trainingToEditLocal}
                                    />
                                    <Webinar 
                                        setTrainingToEditLocal={setTrainingToEditLocal}
                                        trainingToEditLocal={trainingToEditLocal}
                                    />
                                    <FreeTraining 
                                        setTrainingToEditLocal={setTrainingToEditLocal}
                                        trainingToEditLocal={trainingToEditLocal}
                                    />
                                </div>
                                <PricePerDay 
                                    register={register} 
                                    errors={errors} 
                                    setTrainingToEditLocal={setTrainingToEditLocal}
                                    trainingToEditLocal={trainingToEditLocal}
                                    setValue={setValue}
                                />
                            </div>
                        </div>
                        <div className='form-group-row-item'>
                            <Requirements
                                register={register}
                                errors={errors}
                                trainingToEditLocal={trainingToEditLocal}
                                setTrainingToEditLocal={setTrainingToEditLocal}
                            />
                        </div>
                    </div>


                </div>

            </TrainingPropertiesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingPropertiesSC = styled.div`
    display : flex;
    flex-direction : column;
    padding : 25px;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    margin : 20px;
    margin-bottom : 0;
    margin-left : 0;

    .content-training-properties {
        display : flex;
        gap : 20px;
        max-width : 80%;

        .form-group {
            display : flex;
            flex : 1;
            flex-direction : column;

            .form-group-row-item {
                display : flex;
                flex : 1;
                gap : 20px;
            }

            .left-panel {
                min-width : 50%;
                display : flex;
                flex-direction : column;
                gap : 20px;
            }

            .right-panel {
                display : flex;
                flex-direction : column;
                flex : 1;

                .selectable-properties {
                    flex : 1;
                    display : grid;
                    grid-template-columns : repeat(2, 1fr);
                    grid-gap : 20px;
                    grid-auto-rows : min-content;

                    &>div {
                        flex : 1;
                    }
                }
            }

        .selectable-properties {


            &>div {
                display : flex; 
                border : 1px solid lightgrey;
                padding : 10px;
                padding-left : 15px;
                box-sizing : border-box;
                border-radius : 5px;
                min-height : 56px;
                max-height : 56px;
                align-items : center;
                justify-content : space-between;
            }
        }
    }
    }
    




`

export default TrainingProperties