import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import Attendee from './Attendee';
import TopBar from './TopBar';
import NoAttendees from './NoAttendees';

const SendOutSurveyModal = ({ instance, state, setState }) => {

    React.useEffect(() => {
        const idsOfAllAttendees = instance.attendees.filter(attendee => !attendee.receivedSurvey);
        setState({...state, selectedForSurvey : idsOfAllAttendees});
    },[]);

    const rendering = () => {
        return (
            <Modal>
                <SendOutSurveyModalSC>
                    <TopBar state={state} setState={setState}/>
                    <div style={{ overflowY : 'scroll', display : 'flex', flexGrow : 1, flexDirection : 'column'}}>
                    {
                        instance.attendees.length === 0 ? 
                            <NoAttendees />
                        : 
                        instance.attendees.map((attendee) =>  <Attendee attendee={attendee} state={state} setState={setState} />)
                    }
                    </div>
                </SendOutSurveyModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SendOutSurveyModalSC = styled.div`
    min-height : 80vh;
    max-height : 80vh;
    min-width : 80vw;
    background-color : white;
    border-radius : 5px;
    display : flex; 
    flex-direction : column;
    box-sizing : border-box;
    position : relative;
    overflow : hidden;

    .title {
        font-size : 1.5rem;
        margin-top : 0;
        margin-bottom : 25px;
        padding : 25px;
    }
`

export default SendOutSurveyModal