import React from 'react';
import styled from 'styled-components';
import TitleComponent from './TitleComponent';
import { TextField } from '@mui/material';
import {  EditorContent } from '@tiptap/react'
import TitleIcon from '@mui/icons-material/Title';
import AbcIcon from '@mui/icons-material/Abc';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { CustomInstanceContext } from './AddCustomInstance';

const MessageForSignUp = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { editor, MessageForEmailRef } = React.useContext(CustomInstanceContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <MessageForSignUpSC className='content-container-item content-section' id='section7' ref={MessageForEmailRef}>
                <TitleComponent title={'Pracitcal info'}/>
                <p className='subtitle'>
                    This message will be shown to the user when they sign up for the session. Add pracitcal information about what they need to bring / have installed. Inform them about lunch
                </p>
                <div className="editor-toolbar">
                </div>
                <EditorContent 
                    editor={editor} 
                    className='editor-content'
                    spellCheck={false}    
                />
            </MessageForSignUpSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MessageForSignUpSC = styled.div`

    .subtitle {
        color : #2b2b2b;
        font-weight : 300;
        margin-top : -10px;
        margin-bottom : 20px;
        padding : 7px;
    }

    .editor-toolbar {
        display : flex;
        
        button {
            background-color : #fafafa;
            border : 1px solid #ddd;
            padding : 8px;
            cursor : pointer;
            display : flex;
            justify-content : center;
            align-items : center;
            color : grey;

            &.is-active {
                background-color : #f0f0f0;
            }
        }
    }

    .editor-content {
        border: 1px solid #ddd;
        padding: 25px;
        border-radius: 8px;
        min-height: 200px;
        flex-grow : 1;
        overflow-y : scroll;

        .tiptap {
            outline : none;
            flex-grow : 1;

            h1 {
                color : #D20A4B;
                margin : 0;
                font-family : 'Outfit', sans-serif;
                font-weight : 400;
                font-size : 1.2rem;
            }

            p {
                margin : 0;
                color : #1C4050;
            }
        }

        &>h1 {
            color : red;
        }

    }
`

export default MessageForSignUp