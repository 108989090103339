import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SubNavigation = ({ navigationItems, individualSession, lastOpenedSessionId }) => {

    const [currentItem, setCurrentItem] = React.useState(navigationItems[0]);
    const location = useLocation();

    React.useEffect(() => {
        const lastWord = window.location.hash.split('/').pop();
        lastWord === 'overview' ? setCurrentItem(navigationItems[1]) : setCurrentItem(navigationItems[0]);
    }, [location]);

    const navigate = useNavigate();

    const clickItem = (item, path) => {
        navigate(path)
        setCurrentItem(item);
    }

    const getPath = (item) => {
        if(item.name === 'Scheduled trainings') {

            if(individualSession) {
                return `sessions/${individualSession._id}`;
            } else {
                return `sessions/${lastOpenedSessionId ? lastOpenedSessionId : 'no-session'}`;
            }

        }

        else {
            return item.path;
        }

    }

    const rendering = () => {
        return (
            <SubNavigationSC>
                {navigationItems.map((item, index) => {

                    const path = getPath(item); 

                    return (
                        <div 
                            className='navigation-item' 
                            key={item.name}
                            onClick={() => clickItem(item, path)}    
                        >
                            <h1 className={`${currentItem.name === item.name ? 'color-main-blue' : 'color-black-medium'}`}>{item.name}</h1>
                        </div>
                    );
                })}

                </SubNavigationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubNavigationSC = styled.div`
    display : flex;
    margin-left : 20px;
    padding-top : 20px;

    .navigation-item {

        padding : 10px;
        padding-top : 0;
        padding-bottom : 0;
        
        &:hover {
            cursor : pointer;
        }

        &:first-child {
            border-right : 1px solid grey;
            padding-right : 10px;
        }   
    }
`

export default SubNavigation