import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios'; 
import { SurveyResponsesContext } from '../SurveyResponses';
import FilterItem from './FilterItems/FilterItem';
import FilterItem2 from './FilterItems/FilterItem2';

const SurveyResponsesSidebar = ({ associatedValues }) => {
  
    const { locations, trainers, trainings, instances  } = React.useContext(SurveyResponsesContext);
    const rendering = () => {

        return (
            <SurveyResponsesSidebarSC>

                <FilterItem2
                    title='Locations'
                    filter={locations}
                    associativeValues={associatedValues['locations']}
                />

                <FilterItem2
                    title='trainings'
                    filter={trainings}
                    associativeValues={associatedValues['trainings']}
                />

                <FilterItem2
                    title='trainers'
                    filter={trainers}
                    associativeValues={associatedValues['trainers']}
                />

                {/* <FilterItem
                    title='Locations'
                    filterOn='title'
                    items={locations}
                    resultsArray={resultsArray}
                />

                <FilterItem
                    title='Trainers'
                    filterOn='firstName'
                    items={trainers}
                />

                <FilterItem
                    title='Trainings'
                    items={trainings}
                    filterOn='name'
                /> */}


            </SurveyResponsesSidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyResponsesSidebarSC = styled.div`
    padding : 15px;
    box-sizing : border-box;
    overflow-y : auto;
    max-width : 300px;
    
    h1 {
        font-size : 16px;
        margin-bottom : 10px;
    }

    min-width : 260px;
    background-color : white;
`

export default SurveyResponsesSidebar