import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { NewSessionModalContext } from './ScheduleNewSessionModal';

const Header = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { setNewSessionModal } = React.useContext(NewSessionModalContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <HeaderSC>
                <p>header</p>
                <Button variant="contained" color="primary" onClick={() => setNewSessionModal(false)}>
                    Close
                </Button>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    display : flex; 
    justify-content : space-between;
    padding : 20px;
`

export default Header