import React from 'react';
import styled from 'styled-components';
import moment from 'moment'; 
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MailPreview from '../../../mails/mailPreview/MailPreview';
import Modal from '../../../GeneralComponents/Modal';
import MailViewer from './MailViewer';

const MailsOverview = ({ mails, individualSession, attendee}) => {

    const [mailPreview, setMailPreview] = React.useState(null);

    const mailsGeneralDate = mails.map(mail => {
        return ({
            ...mail, 
            date : mail.isScheduled ? mail.jobProperties.sendAt : mail.createdAt
        })
    }); 
    const sortedMails = mailsGeneralDate.sort((a, b) => new Date(a.date) - new Date(b.date));

    const rendering = () => {
        return (
            <MailsOverviewSC>
                <h1 className='title'> Mail traffic </h1>
                <div className='mail-traffic-container'>
                    {sortedMails.map(mail => {

                        const timing = mail.isScheduled ? mail.jobProperties.sendAt : mail.createdAt;
                        const status = ['cancelled' , 'opened'].includes(mail.mailerSendMailStatus) ? mail.mailerSendMailStatus : mail.isScheduled ? 'scheduled' : 'sent'; 

                        return (
                            <>
                                {/* Individual mail */}
                                <>	
                                    {
                                        mailPreview && <MailViewer
                                            mail={mailPreview}
                                            closeFunction={() => setMailPreview(null)}
                                            individualSession={individualSession}
                                            attendee={attendee}
                                        />                                       
                                    }
                                </>

                                <div className='mail-container' key={mail._id} onClick={() => setMailPreview(mail)}>
                                    <div className='icon'>
                                        <MailOutlineIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                                    </div>
                                    <div className='title-and-date'>
                                        <h1>{mail.subject}</h1>
                                        <h3>{moment(mail.date).format('DD/MM/YYYY HH:mm')}</h3>
                                    </div>
                                    <div className='status'>
                                        <p className={`${
                                            status === 'scheduled' ? 'status-grey' : 
                                            status === 'cancelled' ? 'status-red' : 
                                            status === 'opened' ? 'status-blue' : 
                                            'status-green'}`}>{status}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })
                    }
                </div>

            </MailsOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailsOverviewSC = styled.div`
    padding : 20px;
    display : flex; 
    flex-direction : column;
    flex : 1;
    
    .title {
        margin : 0;
        font-size : 18px;
        margin-bottom : 20px;
    }

    .mail-traffic-container {
        background-color : #F6F8FA;
        padding-bottom : 20px;
        overflow : hidden;
        padding : 20px;
        flex : 1;
        border-radius : 10px;
        overflow-y : scroll
    }

    .mail-container {
        display : flex;
        align-items : center;
        border : 1px solid #e0e0e0;
        border-radius : 10px;
        margin-bottom : 10px;
        padding : 15px;
        background-color : white;
        position : relative;
        height : 60px;

        &:hover {
            box-shadow : 0px 0px 10px 0px #e0e0e0;
            cursor: pointer;
        }
    
        .icon {
            margin-right : 20px;
            &>svg {
                font-size : 30px;
            }
        }

        .title-and-date {
            flex : 1;
            display : flex;
            flex-direction : column;

            h4 {
                margin : 0;
            }
            p {
                margin : 0;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -10px; /* Adjust the gap */
            left: 50%;
            width: 1px;
            height: 10px; /* Length of the line */
            background-color: grey;
            transform: translateX(-50%);
        }

        &:last-child {
            &::after {
                display : none;
            }
        }
    
    }

    .status {

        p {
            font-size : 12px;
            border-radius : 5px;
            padding : 7px;
        }

    }


`

export default MailsOverview