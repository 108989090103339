import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import TitleIcon from '@mui/icons-material/Title';
import AbcIcon from '@mui/icons-material/Abc';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const CancelMailModal = ({ closeFunction, cancelInstanceAndSendMail, editor, EditorContent}) => {

    const rendering = () => {
        return (
            <Modal>
                <CancelMailModalSC>
                    <div className='top-bar'>
                        <h1>Cancel session</h1>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => closeFunction()}
                        >Close</Button>
                    </div>

                    <div className='tip-tap-container'>
                        <div className="editor-toolbar">
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    editor.chain().focus().toggleHeading({ level: 1 }).run()}
                                }
                                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                            >
                                <TitleIcon />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    editor.chain().focus().setParagraph().run()}
                                }
                                className={editor.isActive('paragraph') ? 'is-active' : ''}
                            >
                                <AbcIcon />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    editor.chain().focus().toggleBulletList().run()}
                                }
                                className={editor.isActive('bulletList') ? 'is-active' : ''}
                            >
                                <FormatListBulletedIcon />
                            </button>
                        </div>
                        <EditorContent 
                            editor={editor} 
                            className='editor-content'
                            spellCheck={false}    
                        />
                    </div>


                    <div className='buttons-for-cancelling'>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => cancelInstanceAndSendMail(false)} // False means no message
                        >Cancel without contacting attendees</Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => cancelInstanceAndSendMail(true)} // True means add message
                        >Cancel session and mail attendees</Button>

                    </div>
                </CancelMailModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CancelMailModalSC = styled.div`
    min-width : 80vw;
    min-height : 80vh;
    background-color : white;

    .top-bar {
        display : flex; 
        justify-content : space-between;
        align-items : center;
        border-bottom : 1px solid #ccc;
        padding : 20px;

    }

    .tip-tap-container {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
        margin : 15px;
    }

    .editor-toolbar {
        display : flex;
        
        button {
            background-color : #fafafa;
            border : 1px solid #ddd;
            padding : 8px;
            cursor : pointer;
            display : flex;
            justify-content : center;
            align-items : center;
            color : grey;

            &.is-active {
                background-color : #f0f0f0;
            }
        }
    }

    .editor-content {
        border: 1px solid #ddd;
        padding: 25px;
        border-radius: 8px;
        flex-grow : 1;
        max-height : 350px;
        overflow-y : auto;
        min-height : 350px;
        
        .tiptap {
            outline : none;
            flex-grow : 1;

            h1 {
                color : #D20A4B;
                margin : 0;
                font-family : 'Outfit', sans-serif;
                font-weight : 400;
                font-size : 1.2rem;
            }

            p {
                margin : 0;
                color : #1C4050;
            }
        }

        &>h1 {
            color : red;
        }

    }

    .buttons-for-cancelling {
        display : flex;
        padding : 20px;
        padding-top : 0;
        padding-bottom : 20px;
        justify-content : flex-end;

        &>button {
            margin-left : 10px;
        }
    }

`

export default CancelMailModal