import React from 'react';
import styled from 'styled-components';
import Search from '../../GeneralComponents/Search'; 
import UICard from '../../GeneralComponents/UIContainer/UICard';
import CourseList from './CourseList';

const DashboardSearch = ({ sessions }) => {
    console.log('sessions', sessions);

    const [query , setQuery] = React.useState('');
    const [listVisible, setListVisible] = React.useState(false);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.dashboard-search-card') === null) {
                setListVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        const handleEscapePress = (event) => {
            if (event.key === 'Escape') {
            setListVisible(false);
            }
        };

        document.addEventListener('keydown', handleEscapePress);
        document.addEventListener('keyup', handleEscapePress);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, []);


    const rendering = () => {
        return (
            <DashboardSearchSC className='dashboard-search-card'>
                <UICard style={{ margin : 0, padding : 0}} >
                    <Search 
                        style={{ 
                            backgroundColor : 'white', 
                            border : 0, 
                            fontFamily : 'inherit', 
                            fontSize : '1.6em', 
                            padding : '10px 15px', 
                            fontWeight : 'inherit'
                        }}
                        placeholder="Search trainings and sessions...."
                        query={query}
                        setQuery={setQuery}
                        onFocus={() => setListVisible(true)}
                    />
                </UICard>

                {listVisible &&
                    <CourseList
                        sessions={sessions}
                        query={query}
                    />
                }
            </DashboardSearchSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSearchSC = styled.div`
    margin-bottom : 15px;
    position : relative;
    flex : 1;
    margin-right : 15px;

`

export default DashboardSearch