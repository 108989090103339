import React from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';

const IndividualTrainerInfo = ({ form, register, errors, trainer }) => { 

    const rendering = () => {
        return (
            <IndividualTrainerInfoSC>
                <div className='form-row name-and-last-name'>
                    <TextField  
                        label='First name'
                        type='text' 
                        defaultValue={trainer.firstName}
                        {...register('firstName', {
                            required : {
                                value : true,
                                message : 'First name is requried'
                            },
                            minLength : {
                                value : 3,
                                message : 'This field must be at least 3 characters'
                            }
                        })}
                        sx={{
                            width: '50%',

                        }}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                    />
                    <TextField  
                        label='Last name'
                        type='lastName' 
                        defaultValue={trainer.lastName}
                        {...register('lastName', {
                            required : {
                                value : true,
                                message : 'Last name is requried'
                            },
                            minLength : {
                                value : 3,
                                message : 'This field must be at least 3 characters'
                            }
                        })}
                        sx={{
                            width: '50%',
                        }}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                    />
                </div>
                <div className='form-row email'>
                    <TextField  
                        id="email"
                        defaultValue={trainer.email}
                        label="Email"
                        variant="outlined"
                        {...register('email', 
                            { 
                                required : 'Email is required', 
                                pattern : {
                                    value : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message : 'Not a valid email'
                                }
                            }
                        )}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        sx={{
                            width: '100%',
                        }}
                    />
                </div>
                <div className='form-row phone-and-linkedin'>
                    <TextField  
                        defaultValue={trainer.phone}
                        label='Phone number'
                        type='text' 
                        {...register('phone', {
                            required : {
                                value : true,
                                message : 'Phone is requried'
                            }
                        })}
                        sx={{
                            width: '50%',
                        }}
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                    />
                    <TextField  
                        label='LinkedIn'
                        type='linkedIn' 
                        defaultValue={trainer.linkedIn}
                        {...register('linkedIn', {
                            required : {
                                value : true,
                                message : 'Last name is requried'
                            },
                            minLength : {
                                value : 3,
                                message : 'This field must be at least 3 characters'
                            }
                        })}
                        sx={{
                            width: '50%',
                        }}
                        error={!!errors.linkedIn}
                        helperText={errors.linkedIn?.message}
                    />
                </div>
                <div className='form-row short-description'>
                <TextField  
                    defaultValue={trainer.shortDescription}
                    label='Short description for e-mail'
                    multiline
                    type='text' 
                    {...register('description', {
                        required : {
                            value : true,
                            message : 'description is requried'
                        },
                        minLength : {
                            value : 5,
                            message : 'This field must be at least 5 characters'
                        }
                    })}
                    sx={{
                        width: '100%',
                        height: '250px', // Ensure the text field takes the full height of the parent div
                        '& .MuiInputBase-root': {
                            height: '100%', // Ensures input area fills the div,
                            alignItems: 'flex-start',
                        },
                    }}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                />
                </div>
            </IndividualTrainerInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainerInfoSC = styled.div`
    flex : 1;
    display : flex; 
    flex-direction : column;
    padding : 25px;

    .form-row {
        display : flex; 
        gap : 15px;
        margin-bottom : 15px;
    }

    .short-description {
        flex-grow : 1;

    }
`

export default IndividualTrainerInfo