import React from 'react';
import styled from 'styled-components';
import TrainingOverview from '../TrainingOverview/TrainingOverview';
import { IndividualTrainingContext } from '../IndividiualTraining';
import Actions from '../Actions/Actions';
import UpcomingTrainings from '../UpcomingTrainings/UpcomingTrainings';
import CustomProperties from '../../IndividualSession/Dashboard/CustomProperties/CustomProperties';
import MailSchedule from '../MailSchedule/MailSchedule';
import AddInstance from '../../addInstance2/AddInstance';
import Options from './IndTrainingOptions';
import WebsiteStatus from '../WebsiteStatus/WebsiteStatus';

const Overview = ({ state, setState, setReRender, individualTraining }) => {

    const { addInstanceToggled, setAddInstanceToggled } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            individualTraining && 
            <OverviewSC>

                <Options 
                    individualTraining={individualTraining}
                />

                {addInstanceToggled && <AddInstance 
                    closeFunction={() => setAddInstanceToggled(false)}
                    training={individualTraining}
                    setReRender={setReRender}
                    
                />}


                <div style={{ display : 'flex'}}>
                    <TrainingOverview individualTraining={individualTraining}/>
                    <div sytle={{ display : 'flex', flexDirection : 'column'}}>
                        <WebsiteStatus individualTraining={individualTraining}/>
                        <Actions individualTraining={individualTraining}/>
                    </div>
                    <UpcomingTrainings individualTraining={individualTraining}/>
                </div>
                <div style={{ display : 'flex'}}>
                    <CustomProperties individualTraining={individualTraining}/>
                    {/* <MailSchedule individualTraining={individualTraining}/> */}
                </div>
            </OverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OverviewSC = styled.div`
    display : flex;
    flex-direction : column;
    overflow-y : scroll;

`

export default Overview