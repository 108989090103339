import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import IndividualSession from '../IndividualSession';

const UserAvatar = ({ attendee, individualTraining, setCombiDiscountModal, individualSession }) => {

    const { firstName, lastName, email, company, role, createdAt } = attendee;

    const rendering = () => {
        return (
            <UserAvatarSC>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom : '10px' }}>
                    <div className='avatar'>
                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar" />
                    </div>
                    <div className='user-info'>
                        <h1>{`${firstName} ${lastName}`}</h1>
                        <h2>{role}</h2>
                        <h2>{company}</h2>
                    </div>
                </div>
                <div className='email-and-registration'>
                <div className='info-field'>
                        <p className='input'>
                            Email
                        </p>
                        <h1 className='value'>
                            {email}
                        </h1>
                    </div>

                    <div className='info-field'>
                        <h1 className='input'>
                            Registered at
                        </h1>
                        <h1 className='value'>
                            {moment(createdAt).format('DD/MM/YYYY HH:mm')}
                        </h1>
                    </div>

                    <div className='info-field'>
                        <p className='input'>
                            Discount code used
                        </p>
                        <h1 className='value'>
                            {attendee.discountCode ? attendee.discountCode : 'none'}
                        </h1>
                    </div>

                    <div className='info-field'>
                        <h1 className='input'>
                            Received discount
                        </h1>
                        <h1 
                            onClick={() => attendee.discountCode && setCombiDiscountModal(true)}
                            className={`value ${attendee.discountCode ? 'value discount-clickable' : 'value'}`}    
                        >
                            €{attendee.discountValue || 0}
                        </h1>
                    </div>

                    <div className='info-field'>
                        
                        <h1 className='input'>
                            {individualSession.priceMode === 'fixed' ? 'Fixed price for training' : 'Price paid'}
                        </h1>
                        <h1 className='value'>
                            {individualSession.priceMode === 'fixed' ? 
                                `€${individualSession.fixedPrice}` :
                                `€${(individualTraining.pricePerDay * individualTraining.numberOfDays) - ( attendee.discountValue || 0)}`
                            }
                        </h1>
                    </div>
                </div>


            </UserAvatarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserAvatarSC = styled.div`
    display : flex; 
    flex-direction : column;

    .avatar {
        img {
            width: 75px;
            height: 75px;
            border-radius: 50%;
        }
    }

    .user-info {
        margin-left : 15px;

        h1 {
            font-size: 1.5em;
            margin: 0;
        }
    }

    .info-field {
        margin-top : 15px;
        display : flex;
        flex-direction : column;
        
        .value {
            margin: 0;
            color : #4f4f4f;
        }
    }

    .discount-clickable {
        &:hover {
            cursor : pointer;
            color : #007bff;
        }
    }
    
`

export default UserAvatar