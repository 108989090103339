import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Header = ({ closeFunction }) => {

/* ===================================================
    IMPORTS
=================================================== */

/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <HeaderSC>
                <p>Header</p>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={closeFunction}
                >Close</Button>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    
`

export default Header