import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const InstanceInfo = ({ instance, training }) => {
    const rendering = () => {
        return (
            <InstanceInfoSC>
                <div className='image-containrer'>
                    <img src={training.imageUrl} alt={training.name} />
                </div>
                <div className='info-container'>
                    <h1>{training.name}</h1>
                    <h3>{training.description}</h3>
                </div>
                <div className='properties'>
                    <div className='properties-item'>
                        <CalendarMonthIcon />
                        <div style={{ display : 'flex', flexDirection : 'column', marginLeft : '10px'}}>
                            <h3>Start date</h3>
                            <p>{moment(instance.startDate).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                    {instance.trainingType !== 'custom' && 
                        <div className='properties-item'>
                            <LocationOnIcon />
                            <div style={{ display : 'flex', flexDirection : 'column', marginLeft : '10px'}}>
                                <h3>Location</h3>
                                <p>{instance.location.place}</p>
                            </div>
                        </div>
                    }
                </div>

            </InstanceInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceInfoSC = styled.div`
    background-color : white;
    min-height : 100%;
    max-width : 420px;
    min-width: 420px;
    border-right : 1px solid lightgrey;
    display : flex; 
    flex-direction : column;
    justify-content : flex-start;
    max-height : 100%;
    overflow-y : scroll;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: lightgrey;
        border-radius: 10px;
    }

    .image-containrer {
        max-width : 100%;
        min-width : 100%;
        box-sizing : border-box;
        img {
            width : 100%;
            height : auto;
            max-height : 220px;
            object-fit : cover;
        }
    }

    .info-container {
        padding-top : 0;
        padding : 35px;
        box-sizing : border-box;
        h1 {
            font-size : 1.5em;
            margin : 0;
        }

        h3 {
            font-size : 1em;
            margin : 0;
        }
    }

    .properties {
        display : flex; 
        flex-direction : column;
        padding : 35px;
        padding-top : 0;
        
    }

    .properties-item {
        display : flex; 
        flex-direction : row;
        margin-bottom : 30px;
        align-items : center;
    }

    @media (max-width: 600px) {
        min-height : 0;
        padding-bottom : 0;
        margin-bottom : 0;

        .properties {
            padding-bottom : 0;
        }
    }

`

export default InstanceInfo