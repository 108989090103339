import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from "@auth0/auth0-react";
import LoginContainer from './LoginContainer';

const LoginScreen = () => {

    const rendering = () => {
        return (
            <LoginScreenSC>
                <LoginContainer />
            </LoginScreenSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LoginScreenSC = styled.div`
    height: 100vh;
    display: flex;
    width : 100vw;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
`

export default LoginScreen