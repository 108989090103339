import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress  } from '../../../../variables/back-endAdress';
import Search from '../../../GeneralComponents/Search';
import { Button } from '@mui/material';
import { NewSessionModalContext } from '../../ScheduleNewSessionModal';
import TrainingTile from './TrainingTile';

const AddPublicTraining = () => {

/* ===================================================
    IMPORTS
=================================================== */
    const { closeFunction } = React.useContext(NewSessionModalContext);
/* ===================================================
    STATE
=================================================== */
    const [trainings, setTrainings] = React.useState([]);
    const [query, setQuery] = React.useState('');
    const [state, setState] = React.useState({
        loading : true
    });
/* ===================================================
    EVENTS
=================================================== */
    React.useEffect(() => {
    const getTrainings = async () => {

        try {
            const response = await Axios.get(`${backEndAdress}/api/trainings/`);
            setState({...state, loading : false});
            setTrainings(response.data);
        } catch (error) {
            console.error(error);
        }
    }


    getTrainings(); 
    }, [])
/* ===================================================
    FUNCTIONS
=================================================== */

/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {

        const filteredTrainings = trainings.filter(training => {
            return training.name.toLowerCase().includes(query.toLowerCase());
        })

        return (
            <AddPublicTrainingSC>
                {state.loading && <p>Loading...</p>}
                <div className='header'>
                    <h1>Add a public training</h1>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            closeFunction();
                        }}
                    >
                        Close
                    </Button>
                </div>

                <div className='content-container'>
                    <div className='search-container'>
                        <Search
                            query={query}
                            setQuery={setQuery}
                            placeholder={'Search for a training...'}
                        />
                    </div>
                    <div className='training-list'>
                        {filteredTrainings.map((training, index) => <TrainingTile key={index} training={training}/>)}
                    </div>
                </div>

            </AddPublicTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddPublicTrainingSC = styled.div`
    flex : 1;
    .header {
        display : flex;
        justify-content : space-between;
        align-items : center;
        padding : 15px;
    }

    .content-container {
        padding : 15px;
        padding-top : 0;
    }

    .training-list {
        display : grid; 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(250px, 1fr));
        gap : 15px;
        margin-top : 15px;
        
    }
`

export default AddPublicTraining