import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import ReactForm from './ReactForm';
import RegisteredConfirmation from './RegisteredConfirmation';
import ActionInProgress from '../GeneralComponents/ActionInProgress';
import InstanceInfo from './InstanceInfo';

export const SignUpFormContext = React.createContext();

const SignUpForm = () => {

    const trainingId = window.location.hash.split('/')[2];
    const instanceId = window.location.hash.split('/')[3];

    const [state, setState] = React.useState({
        training : null,
        instance : null,
        registeredConfirmation : false
    });

    const [training, setTraining] = React.useState(null);
    const [instance, setInstance] = React.useState(null);

    const [signUpInProgress, setSignUpInProgress] = React.useState(false);
    const [signUpError, setSignUpError] = React.useState(false);
    const [signUpComplete, setSignUpComplete] = React.useState(false);

    React.useEffect(() => {


        const fetchInstance = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/public/${instanceId}`);
                setInstance(response.data);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }

        const fetchTraining = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/public/${trainingId}`);
                setTraining(response.data[0]);
            } catch (error) {
                console.log(error);
            }
        }

        const runFunction = async () => {
            const instance = await fetchInstance();
            if(instance.trainingType !== 'custom') {
                const training = await fetchTraining();
            } else {
                setTraining({
                    name : instance.trainingName, 
                    imageUrl : 'https://previews.123rf.com/images/sodis/sodis1804/sodis180400047/100084305-business-training-flat-design-vector-illustration.jpg', 
                    description : instance.nonInstanceHandleBars.short_description
                })
            }
        }

        runFunction();


    }, []);


    const rendering = () => {

        return (
            <SignUpFormContext.Provider value={{ 
                state, 
                setState,
                training,
                instance,
                setSignUpInProgress,
                setSignUpError
            }}>
                <SignUpFormSC>

                    <>
                        {state.registeredConfirmation && 
                            <RegisteredConfirmation 
                                state={state}
                                setState={setState}
                            />
                        }
                    </>

                    <> {/* Signing up in progress modal */}
                        {signUpInProgress && 
                            <ActionInProgress
                                text='Signing up...' 
                                successText='Succesfully signed up' 
                                errorText={signUpError} 
                                completed={signUpComplete}
                                error={signUpError}
                                successOption1={{
                                    text : 'Close',
                                    onClick : () => {
                                        setSignUpInProgress(false);
                                    }
                                }}
                                errorOption1={{
                                    text : 'Close',
                                    onClick : () => {
                                        setSignUpInProgress(false);
                                    }
                                }}
                            />
                        }
                    </>

                    <div className='singup-container'>
                        {(training && instance) &&
                            <InstanceInfo
                                instance={instance}
                                training={training}
                            />
                        }

                        <ReactForm 
                            instance={instance}
                            state={state}
                            setState={setState}    
                        />
                    </div>
                </SignUpFormSC>
            </SignUpFormContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SignUpFormSC = styled.div`
    position : fixed;
    min-height : 100vh;
    min-width : 100vw;
    top : 0;
    left : 0;
    bottom : 0;
    background-color : #fafafa;
    box-sizing : border-box;
    display : flex;
    align-items : center;
    padding-left: 80px;

    .singup-container { 
        display : flex;
        max-height : 600px;
        overflow : hidden;
        min-height : 600px;
        background-color : white;
        box-shadow : 0 0 10px rgba(0,0,0,0.1);

    }

    @media (max-width: 600px) {
        display : flex;
        flex-direction : column;
        align-items : center;
        justify-content : center;
        padding-left : 0;
        min-height : 0px;
        position : relative;

        .singup-container {
            max-width : 100%;
            min-width : 100%;
            max-height : 10000px;
            min-height : 0px;
            display : flex; 
            flex-direction : column;
            overflow-y : scroll;
        }
        
    }
`

export default SignUpForm