import React from 'react';
import styled from 'styled-components';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import GradingIcon from '@mui/icons-material/Grading';
import LinkIcon from '@mui/icons-material/Link';
import { IndividualTrainingContext } from '../IndividiualTraining';

const Actions = ({ individualTraining }) => {

    const { state, setState, setAddInstanceToggled} = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : '20px', marginLeft : 0 }}>
            <ActionsSC>
                <div className='title-component' style={{ padding : '20px', paddingRight : 0}}>
                    <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                    <h1 className='card-title'>Training calendar</h1>
                </div>                
                <div className='action-item' onClick={() => setState({...state, trainingToEdit : individualTraining})}>
                    <GradingIcon />
                    <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                        <h1 className='action-title'>Edit training</h1>
                        <h3>Edit the training</h3>
                    </div>
                </div>
                <div className='action-item' onClick={() => setAddInstanceToggled(true)}>
                    <LinkIcon />
                    <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                        <h1 className='action-title'>Schedule session</h1>
                        <h3>Schedule a session for this training</h3>
                    </div>
                </div>
            </ActionsSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ActionsSC = styled.div`
    min-width: 300px;
    display : flex; 
    flex-direction : column;
    max-width : 300px;

    .action-item {
        display : flex; 
        align-items : center;
        border-bottom : 1px solid lightgrey;
        padding : 15px;
        min-height : 50px;

        &:hover {
            background-color : #F5F5F5;
            cursor : pointer;
        }

        svg {
            color : #737373;
            margin-right : 25px;
            margin-left : 10px
        }
    }
`

export default Actions