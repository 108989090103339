import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import UserActions from './UserActions';
import CombiDiscountModal from './CombiDiscountModal';
import UserAvatar from './UserAvatar';

const UserInfo = ({ attendee, individualTraining, individualSession }) => {

    // console.log('individual training', individualTraining);
    // console.log('total price : ', individualTraining.pricePerDay * individualTraining.numberOfDays);

    const [combiDiscountModal, setCombiDiscountModal] = React.useState(false);

    const rendering = () => {
        return (
            <UserInfoSC>
                {
                    combiDiscountModal && <CombiDiscountModal 
                        individualTraining={individualTraining}
                        individualSession={individualSession}
                        closeFunction={() => setCombiDiscountModal(false)}
                        attendee={attendee}
                    />
                }

                <UserAvatar
                    attendee={attendee}
                    individualTraining={individualTraining}
                    setCombiDiscountModal={setCombiDiscountModal}
                    individualSession={individualSession}
                />

                {/* <div className='personal-info'>
                    <p>{attendee.firstName} {attendee.lastName}</p>
                    <p>{attendee.email}</p>
                    <br/>
                    <p>{attendee.company}</p>
                    <p>{attendee.role}</p>

                </div> */}


                <UserActions
                    attendee={attendee}
                />

            </UserInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserInfoSC = styled.div`
    padding : 40px;
    border-right : 1px solid #e0e0e0;
    min-width : 300px;

    .discount-code {
        &:hover {
            cursor : pointer;
            text-decoration : underline
        }
    }
`

export default UserInfo