import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React from 'react';
import styled from 'styled-components';
import { SurveyResponsesContext } from '../../SurveyResponses';
import Search from '../../../GeneralComponents/Search';

const FilterItem = ({ title, items, filterOn, resultsArray }) => {

    const { filters, setFilters } = React.useContext(SurveyResponsesContext);
    const [query, setQuery] = React.useState('');

    const handleFilter = (option) => {  
        const filter = filters[title.toLowerCase()] ? filters[title.toLowerCase()] : [];
        const newFilter = filter.includes(option._id) ? filter.filter((item) => item !== option._id) : [...filter, option._id];
        setFilters({
            ...filters,
            [title.toLowerCase()] : newFilter
        });
    }

    // const filteredItems = items.filter((item) => item[filterOn].toLowerCase().includes(query.toLowerCase()));

    const rendering = () => {
        return (
            <FilterItemSC>
                <div className='top-bar'>
                    <p className='filter-item-title'>{title} {filters[title.toLowerCase()].length > 0 && `(${filters[title.toLowerCase()].length})` }</p>
                    {filters[title.toLowerCase()].length > 0 && <button onClick={() => setFilters({...filters, [title.toLowerCase()] : []})}>Clear</button>}
                </div>
                
                <Search 
                    placeholder='Search'
                    query={query}
                    setQuery={setQuery}
                    style={{ maxHeight : '20px', marginBottom : '10px', backgroundColor : 'white'}}
                />
                {/* {filteredItems.map((option) => {
                    
                    const isChecked = filters[title.toLowerCase()] ? filters[title.toLowerCase()].includes(option._id) : false; 
                    
                    return (
                        <div 
                            className='filter-option'
                            onClick={() => handleFilter(option)}    
                        >
                            {!isChecked ? 
                                <CheckBoxOutlineBlankIcon
                                    key={option}
                                    value={option}
                                />
                                :
                                <CheckBoxIcon
                                    key={option}
                                    value={option}
                                />
                            }
                            <p>{option[filterOn]}  </p>
                        </div>                        
                    )
                })} */}
            </FilterItemSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FilterItemSC = styled.div`

    .filter-item-title {
        font-size : 14px;
        padding : 10px;
        font-weight : 580;
        padding-left : 0;
    }


    .filter-option {
        display : flex;
        align-items : center;
        padding : 5px;


        &:hover {
            background-color : #f8f8f8;
            cursor: pointer;
        }
        
        p {
            font-weight : 300;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            max-width : 200px;
        }
    
        svg {
            max-width : 20px;
            max-height : 20px;
            color : #009DFF;
            margin-right : 10px;
        }
    
    }

    .top-bar {
        display : flex;
        justify-content : space-between;
        align-items : center;

        button {
            background-color : lightgrey;
            color : white;
            border : none;
            padding : 5px 10px;
            border-radius : 15px;
            cursor : pointer;
            font-size : 10px;
        }
    }

`

export default FilterItem