import React from 'react';
import styled from 'styled-components';
import { AddInstanceContext } from '../AddInstance';
import moment from 'moment';
import DateComponentTest from './DateComponentTest'; 
import DateAndTime from '../../ScheduleNewSessionModal/AddCustomInstance/DateAndTime';

const AddInstanceDates = () => {

    const { dates, setDates, trainingDays, setTrainingDays, selectedDates, setSelectedDates } = React.useContext(AddInstanceContext);

    const rendering = () => {
        
        const datesSorted = dates ? dates.sort((a, b) => {
            return new Date(a) - new Date(b);
        }) : null;
        
        return (
            <AddInstanceDatesSC>
                <DateAndTime
                    trainingDays={trainingDays}
                    setTrainingDays={setTrainingDays}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                />

                {/* <div className='calendar-container'>
                    <DateComponentTest />
                </div>
                <div className='listed-dates'>
                    {datesSorted && datesSorted.length > 0 ?

                        datesSorted.map(date => {
                            return (
                                <div key={date} className='date-field'>
                                    <p>{moment(date).format('DD/MM/YYYY')}</p>
                                </div>
                            )
                        }) :
                        <p className='no-dates-selected'>no dates selected...</p>
                    }
            </div> */}
            </AddInstanceDatesSC> 
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddInstanceDatesSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    padding : 20px;

    h1 {
    }

    .calendar-container {
        display : flex;
        width : 320px;
        background-color : white;
        margin-top : 15px;
    }

    .MuiDateCalendar-root {

    }
        
    .next-button {
        position : absolute;
        bottom : 20px;
        right : 20px;
    }

    .listed-dates {
        display : flex;
        flex-direction : column;
        margin-top : 15px;
        margin-left : 15px;
        background-color : white;
        border-radius : 5px;
        box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
        width : 320px;

        &>.date-field {
            margin : 0;
            padding : 10px;
            border-bottom : 1px solid rgba(0,0,0,0.1);
            padding : 15px;
        }

        .no-dates-selected {
            margin : 0;
            padding : 10px;
            padding : 15px;
            color : grey;
        }
    }
`

export default AddInstanceDates