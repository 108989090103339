import React from 'react';
import styled from 'styled-components';
import Search from '../../GeneralComponents/Search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const ValidOnTrainingsList = ({ trainings, selectedTrainings, setSelectedTrainings }) => {

    const [query, setQuery] = React.useState('');
    const fiteredTrainings = trainings.filter((training) => training.name.toLowerCase().includes(query.toLowerCase()));
    const clearSelections = () => {
        setSelectedTrainings([]);
    }
    const rendering = () => {
        return (
            <ValidOnTrainingsListSC>
                <div className='header-trainings-list'>
                    <Search
                        placeholder='Search for a training'
                        query={query}
                        setQuery={setQuery}
                        style={{
                            maxWidth: '100%',
                            marginBottom: '15px',
                            flexGrow: 1,
                            marginRight : '15px'   
                        }}
                    />
                    { selectedTrainings.length > 0 && <button type='button' onClick={() => clearSelections()}>CLEAR</button> }
                </div>
                <div className='select-all-option'>
                    <div 
                        className='training-value'  
                        onClick={() => {
                            if(selectedTrainings.length === fiteredTrainings.length){
                                setSelectedTrainings([])
                            } else {
                                setSelectedTrainings(fiteredTrainings.map((training) => training._id))
                            }
                        }}
                        style={{ border : 0}}
                    >
                        <label>Select all</label>
                    </div>
                </div>
                <div className='trainigs-list'>
                    { fiteredTrainings.map((training, index) => {
                        return (
                            <div 
                                key={index} 
                                className='training-value'  
                                onClick={() => {
                                    if(selectedTrainings.includes(training._id)){
                                        setSelectedTrainings(selectedTrainings.filter((_id) => _id !== training._id))
                                    } else {
                                        setSelectedTrainings([...selectedTrainings, training._id])
                                    }
                                }}
                            >
                                {selectedTrainings.includes(training._id) ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>}
                                <label>{training.name}</label>
                            </div>
                        );
                    }
                    )}
                </div>

            </ValidOnTrainingsListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ValidOnTrainingsListSC = styled.div`
    flex-grow : 1;
    padding : 20px;
    box-sizing : border-box;
    display : flex; 
    flex-direction : column;
    overflow-y : auto;

    .training-value {
        display : flex;
        align-items : center;
        font-weight : 350;
        border-bottom : 1px solid #e3e3e3;
        padding : 10px;

        &>svg {
            margin-right : 10px;
            color : #009DFF;
        }

        &:hover {
            background-color : #f5f5f5;
            cursor : pointer;
        }


    }

    .header-trainings-list {
        display : flex; 
        justify-content : space-between;
    }

    button {
        padding : 5px 10px;
        background-color : #009DFF;
        color : white;
        border : none;
        border-radius : 5px;
        cursor : pointer;
        max-height : 40px;

        &:hover {
            background-color : #007bb3;
        }
    }

    .select-all-option {
        display : flex; 
        border-bottom : 0;
        font-weight : 'bold';

        &:hover {
            background-color : white;
            cursor : pointer;
        }
    }

    .trainigs-list {
        flex-grow : 1;
    }

`

export default ValidOnTrainingsList