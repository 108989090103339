import React from 'react';
import styled from 'styled-components';
import Rating from '@mui/material/Rating';

const RatingComponent = ({ item, answers, setAnswers }) => {

    const ratingRef = React.useRef();



    const onChangeFunction = (index) => {
        const responses = answers; 

        const responseObject = {
            question : item.question,
            possibleAnswers : [1,2,3,4,5],
            type : 'rating',
            answerIndex : index
        }

        // check if the question is already answered
        const questionIndex = responses.findIndex(response => response.question === item.question);
        if(questionIndex === -1) {
            responses.push(responseObject);
        } else {
            responses[questionIndex] = responseObject;
        };

        setAnswers(responses);
    }

    React.useEffect(() => {
        onChangeFunction(1);
    },[])


    const rendering = () => {
        return (
            <RatingSC>
                <p className='question'>{item.question}</p>
                <div className='options'>
                    <Rating 
                        name="size-large" 
                        defaultValue={1} 
                        size="large" 
                        onChange={(event, newValue) => onChangeFunction(newValue)}
                    />

                    {/* {[1,2,3,4,5,7,8,9,10].map((number, index) => {   
                        return (
                            <div key={index} className='option'>
                                <input type="radio" id={number} name={item.question} value={number} onChange={() => onChangeFunction(index)}></input>
                                <label htmlFor={number}>{number}</label>
                            </div>
                        )})
                    } */}
                </div>
            </RatingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RatingSC = styled.div`
    .question {
        font-size : 1.2rem;
        padding-bottom : 10px;
    }

    .options {
        display : flex;
        flex-wrap : wrap;
    }

    .option {
        display : flex;
        align-items : center;

        & input {
            margin-right : 10px;
        }
    }
`

export default RatingComponent