import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import LatestRegistrationsHeader from './LatestRegistrationsHeader';
import RegistrationsTable from './RegistrationsTable';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios';
import { CircularProgress } from '@mui/material';

const LatestRegistrationsModal = () => {

    const [attendees, setAttendees] = React.useState([]);

    const [loadAttendees, setLoadAttendees] = React.useState(true);
    const [loadAttendeesError, setLoadAttendeesError] = React.useState(false);
    
    React.useEffect(() => {

        const loadAttendees = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/get-all-attendees-list`);
                setAttendees(response.data);
                setLoadAttendees(false);
            } catch (error) {
                setLoadAttendeesError(true);
                setLoadAttendees(false);
            }
        }

        loadAttendees();

    }, [])

    const rendering = () => {
        return (
            <Modal>
                <LatestRegistrationsModalSC>
                    <LatestRegistrationsHeader />
                    
                    {loadAttendees && 
                        <div className='loading-container'>
                            <CircularProgress />
                        </div>
                    }


                    {loadAttendeesError &&
                        <div className='error-container'>
                            <p>There was an error loading the attendees</p>
                        </div>
                    }

                    <RegistrationsTable 
                        attendees={attendees}
                    />
                </LatestRegistrationsModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LatestRegistrationsModalSC = styled.div`
    min-width : 80vw;
    min-height : 80vh;
    max-height : 80vh;
    max-width : 80vw;
    overflow : hidden;
    background-color : white;
    display : flex; 
    flex-direction : column;
    border-radius : 10px;
    .loading-container {
        display : flex;
        justify-content : center;
        align-items : center;
        flex: 1;        
    }   
`

export default LatestRegistrationsModal