import React from 'react';
import styled from 'styled-components';
import { NewSessionModalContext } from '../ScheduleNewSessionModal';
import { CustomInstanceContext } from './AddCustomInstance';
import { Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const AddCustomInstanceSidebar = () => {

/* ===================================================
    IMPORTS
=================================================== */

const { closeFunction } = React.useContext(NewSessionModalContext);
const { activeNavItem, setActiveNavItem, navigationItems } = React.useContext(CustomInstanceContext);
/* ===================================================
    STATE
=================================================== */

/* ===================================================
    EVENTS
=================================================== */

/* ===================================================
    FUNCTIONS 
=================================================== */
    const scrollToDiv = (refToUse) => {
        if (refToUse.current) {
            refToUse.current.scrollIntoView({ behavior: 'smooth' });
        }
}
/* ===================================================
    RENDERING
=================================================== */

    const rendering = () => {
        return (
            <AddCustomInstanceSidebarSC>
                <div className='sidebar-items'>
                    <h1 className='title'>Training</h1>

                    {navigationItems.map((item, index) => {
                        return (
                            <div 
                                href={`#section${index+1}`}
                                key={index}
                                className={`nav-item header-item`}
                                onClick={() => scrollToDiv(item.refToUse)}   
                            >
                                <CircleIcon className='circle' />
                                <p className='navigation-title'>{item.title}</p>
                            </div>
                            )}
                    )}
                </div>

                    {/* {navigationItems.map((item, index) => {
                        return (
                            <div 
                                key={index} 
                                className={`navigation-item ${item.id === activeNavItem ? 'active' : ''}`}
                                onClick={() => setActiveNavItem(item.id)}    
                            >
                                <div className='icon'>
                                    <i className={`fas fa-${item.icon}`}></i>
                                </div>
                                <div className='title'>
                                    {item.title}
                                </div>
                            </div>
                        );
                    })} */}
                {/* </div> */}
                <Button 
                    variant='outlined'
                    color='primary'
                    onClick={() => closeFunction()}
                >
                    Cancel
                </Button>
            </AddCustomInstanceSidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddCustomInstanceSidebarSC = styled.div`
    padding : 20px;
    min-width : 200px;
    display : flex; 
    flex-direction : column;
    justify-content : space-between;

    .active {
        color : black;
        font-weight : 400;
    }

    h1 {
        font-size: 1.5rem;
        margin: 0;
        padding : 25px;
        padding-left : 0px;
        font-weight : 400;
        color : black;
    }

    ul {
        list-style: square;
        padding: 0;
        margin: 15px ;
    }

    .sidebar-items {
        display: flex;
        flex-direction: column;
        padding : 25px;
        padding-left : 10px;
        padding-top : 0;
        color : grey;
        font-weight : 300;
    }

    .header-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s;
        padding-top : 10px;
        padding-bottom : 10px;
        position: relative;

        &>svg {
            color : grey;
            font-size : 0.7rem;
            padding-right : 15px;
        }

        :not(:last-child) svg::after {
            content: "";
            position: absolute;
            top: 20px; /* Same as circle's height */
            left: 50%;
            transform: translateX(-50%);
            width: 2px;
            height: calc(100% + 10px); /* Adjust for spacing between items */
            background-color: blue;
            z-index: -1;
            }
    }
`

export default AddCustomInstanceSidebar