import React from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import moment from 'moment'; 
import { useNavigate } from 'react-router-dom';

const RegistrationsTable = ({ attendees }) => {

    const navigate = useNavigate();

    const navigateToSession = (data) => {
        const { instanceId, trainingId } = data;
        navigate(`/trainings/${trainingId}/sessions/${instanceId}`);
    }

    const rendering = () => {
        return (
            <RegistrationsTableSC>
                <div className='ag-theme-material' style={{ flexGrow: 1, height : "100px"}}>
                    <AgGridReact
                        rowData={attendees} 
                        columnDefs={[                       
                            { field: "createdAt", headerName : 'Signed up', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY HH:mm'), flex : 3},
                            { headerName : "Name", valueGetter : p => `${p.data.firstName} ${p.data.lastName}`, flex : 3},
                            { field : "trainingName", headerName : 'Training', flex : 3},
                            { field : "company", headerName : 'Company', flex : 3},
                        ]}
                        onRowClicked={(row) => navigateToSession(row.data)}
                    />
                </div>

            </RegistrationsTableSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegistrationsTableSC = styled.div`
    background-color : white;
    flex-grow : 1;
    display : flex;
    flex : 1;
    flex-direction : column;
    overflow : hidden;


    .ag-theme-material {
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
        --ag-material-accent-color : #1976d2;
        --ag-selected-row-background-color : rgba(42,115,207,0.1);
        .ag-cell-focus {
            outline: none; /* Remove the default outline */
        }
    }




`

export default RegistrationsTable