import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import AreYouSure from '../../GeneralComponents/AreYouSure';
import ActionInProgress from '../../GeneralComponents/ActionInProgress';
import { useNavigate } from 'react-router-dom';

const Options = ({ individualTraining }) => {

/* =====================================================================================
    General imports
===================================================================================== */    
    const optionsRef = useRef(null);
    const navigate = useNavigate();

/* =====================================================================================
    State
===================================================================================== */  
    const [optionsToggled, setOptionsToggled] = useState(false);
    const [action, setAction] = useState(null);
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(true);
    const [error, setError] = useState(null);

/* =====================================================================================
    Use effects
===================================================================================== */  
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

/* =====================================================================================
    Functions
===================================================================================== */  
    const handleClickOutside = (event) => {
        if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setOptionsToggled(false);
        }
    };

    const removeTraining = () => {
        setAction({
            title: 'Are you sure you want to delete this training and all scheduled sessions associated with it?',
            textOption1: 'Yes',
            textOption2: 'No',
            function1: () => deleteTraining(),
            function2: () => setAction(null)
        })
    }

    const deleteTraining = async () => {
        setRemoving(true);
        try {
            const response = await Axios.delete(`${backEndAdress}/api/trainings/${individualTraining._id}`);
            setRemoved(response.data);  
        }
        catch (error) {
            setError(error);
        }
    }
    

/* =====================================================================================
    Rendering
===================================================================================== */  
    const rendering = () => {
        return (
            <>
                {/* Are you sure for the action? */}
                <>
                    {action && <AreYouSure 
                        title={action.title}
                        textOption1={action.textOption1}
                        textOption2={action.textOption2}
                        function1={() => action.function1()}
                        function2={() => action.function2()}
                    />}
                </>

                {/* Action in progress */}
                <>
                    {removing && <ActionInProgress 
                        text='Deleting training'
                        successText='Training deleted'
                        errorText='Error deleting training'
                        completed={removed}
                        error={error}
                        successOption1={{
                            text : 'Close',
                            onClick : () => {
                                navigate(`/trainings/`);
                                setRemoving(false);
                                setAction(null);
                            }
                        }}
                        errorOption1={{
                            text : 'Close',
                            onClick : () => setAction(null)
                        }}
                    />}
                </>


            <OptionsSC ref={optionsRef}>
                <p onClick={() => setOptionsToggled(!optionsToggled)}>Options</p>
                {optionsToggled && (
                    <OptionsMenu>
                        <OptionItem onClick={() => removeTraining()}>Delete training</OptionItem>

                    </OptionsMenu>
                )}
            </OptionsSC>
            </>
        );
    };

    

    return (
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
};

const OptionsSC = styled.div`
    position: absolute; 
    right: 40px; 
    top: 20px; 
    cursor: pointer;
`;

const OptionsMenu = styled.div`
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

const OptionItem = styled.div`
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export default Options;