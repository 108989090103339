import React from 'react';
import { Router } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { AppContext } from './App';
import LoginScreen from './components/loginScreen/LoginScreen';

const WithSidebarAndHeader = ({ children, authenticated }) => {
    const { appState, setAppState } = React.useContext(AppContext);
    const { tenant } = appState;
    
    const rendering = () => {

        if(!authenticated) return (
            <LoginScreen 
                appState={appState}
                setAppState={setAppState}
            />
        )
        if(!tenant) {
            return (
                <>
                    <p>Couldn't create a connection to the server</p>
                    <p>contact the admin </p>
                </>
            )
        }
        return (
            <TestAppSC>
                <Header
                    appState={appState}
                    setAppState={setAppState}
                />
                <div className='content'>
                    <Sidebar 
                        appState={appState}
                        setAppState={setAppState}
                    />
                    {children}
                </div>
            </TestAppSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TestAppSC = styled.div`
    display : flex; 
    flex-direction : column;
    min-height : 100vh; 
    max-height : 100vh;
    min-width : 100vw; 
    background-color : #fafafa;
    overflow: hidden;

    .content {
        display : flex;
        flex-grow : 1;
        flex-direction : row;
        overflow : hidden;
        
    } 
`

export default WithSidebarAndHeader